import React from 'react';
import PropTypes from 'prop-types';

import Button from 'react-uwp/Button';
import Loader from 'react-loader-spinner';

import OutlinedButton from 'components/form/OutlinedButton/OutlinedButton';

import './Container.style.scss';

import NexAtlasLogo from 'assets/logos/nexatlas_horizontal.png';
import NexAtlasLogo2x from 'assets/logos/nexatlas_horizontal@2x.png';

import classnames from 'classnames';

const AuthContainer = props => {
  const {
    children,
    logo,
    title,
    nextButton,
    nextLabel,
    nextDisabled,
    previousButton,
    previousLabel,
    previousDisabled,
    loading,
    width,
    height,
    onNext,
    onPrevious,
  } = props;

  const customStyle = {};
  if (width) customStyle.maxWidth = width;
  if (height) customStyle.height = height;

  return (
    <div className="auth__container" style={customStyle}>
      {loading && (
        <div className="auth__container__loading">
          <div className="auth__container__loading__spinner">
            <Loader type="TailSpin" color="#00B96D" height="100" width="100" />
          </div>
        </div>
      )}
      <div className="auth__container__logo-container">
        {logo && <img src={NexAtlasLogo} srcSet={`${NexAtlasLogo} 1x,${NexAtlasLogo2x} 2x`} alt="NexAtlas" aria-hidden />}
      </div>
      {title && <h2 className="auth__container__title">{title}</h2>}
      <div className="auth__container__content">{children}</div>
      {(previousButton || nextButton) && (
        <div className="auth__container__actions">
          {previousButton && (
            <Button
              onClick={() => onPrevious()}
              disabled={previousDisabled}
              className={classnames('auth__container__actions__previous', { 'auth__container__actions__previous--only': !nextButton })}
            >
              {previousLabel}
            </Button>
          )}
          {nextButton && (
            <OutlinedButton label={nextLabel} onClick={() => onNext()} disabled={nextDisabled} className="auth__container__actions__next" />
          )}
        </div>
      )}
    </div>
  );
};

AuthContainer.defaultProps = {
  logo: false,
  title: null,
  nextButton: false,
  nextLabel: 'Próximo',
  nextDisabled: false,
  previousButton: false,
  previousLabel: 'Voltar',
  previousDisabled: false,
  loading: false,
  width: null,
  height: null,
  onNext: () => {},
  onPrevious: () => {},
};

AuthContainer.propTypes = {
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]).isRequired,
  logo: PropTypes.bool,
  title: PropTypes.string,
  nextButton: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  nextLabel: PropTypes.string,
  nextDisabled: PropTypes.bool,
  previousButton: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  previousLabel: PropTypes.string,
  previousDisabled: PropTypes.bool,
  loading: PropTypes.bool,
  width: PropTypes.number,
  height: PropTypes.number,
  onNext: PropTypes.func,
  onPrevious: PropTypes.func,
};

export default AuthContainer;
