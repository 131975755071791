import { PLAN } from './types';

export function loadPlans() {
  return {
    type: PLAN.LOAD_COLLECTION,
    payload: {
      client: 'api',
      request: {
        url: '/plan/getAll',
        method: 'get',
      },
    },
  };
}
