const AUTH_PREFIX = '/auth';
export const AUTH = {
  MAIN: `${AUTH_PREFIX}`,
  CONFIRM_EMAIL_SEND: `${AUTH_PREFIX}/confirmEmail`,
};

const DASHBOARD_PREFIX = '/dashboard';
export const DASHBOARD = {
  MAIN: `${DASHBOARD_PREFIX}`,
};
