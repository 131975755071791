import { Model, attr } from 'redux-orm';

import { NEWS } from '../actions/types.js';

export default class News extends Model {
  static reducer(action, News, session) {
    const { payload, type } = action;

    switch (type) {
      case `${NEWS.LOAD_COLLECTION}_SUCCESS`:
        if (payload.data && payload.data.news) {
          payload.data.news.forEach(news => {
            News.upsert(news);
          });
        }
        break;

      default:
        break;
    }
  }
}

News.modelName = 'News';

News.fields = {
  id: attr(),
  creation_date: attr(),
  updated_on: attr(),
  title: attr(),
  text: attr(),
  image: attr(),
};
