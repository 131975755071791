import React from 'react';
import { useDispatch } from 'react-redux';

import Tour from 'reactour';
import { useTourContext } from 'context/TourContext/TourContext';
import ConfirmModal from '../../../../components/ConfirmModal/ConfirmModal';

import { setUserFlag } from '../../../../redux/actions/authActions';

const DashboardTour = () => {
  const tour = useTourContext();
  const dispatch = useDispatch();

  const handleCloseTour = () => {
    tour.closeTour();
    tour.openExitDialog();
  };

  const handleCloseTourDialog = confirm => {
    tour.closeExitDialog();
    if (confirm) {
      dispatch(setUserFlag('TOUR_DONE', 'true'));
      return tour.closeTour();
    }
    return tour.openTour();
  };

  return (
    <div>
      <ConfirmModal
        message={'Você tem certeza que deseja sair do tour? Você poderá acessá-lo novamente no menu de configurações, clicando em "Refazer tour"'}
        width={480}
        open={tour.exitDialog}
        onYes={() => handleCloseTourDialog(true)}
        onCancel={() => handleCloseTourDialog(false)}
        onFinish={tour.closeExitDialog}
      />
      <Tour
        steps={tour.steps}
        goToStep={tour.currentStep}
        isOpen={tour.open}
        rounded={2}
        onRequestClose={handleCloseTour}
        closeWithMask={false}
        disableDotsNavigation
        disableKeyboardNavigation
        showNavigation={false}
        maskSpace={2}
        showButtons={false}
        showNumber={false}
        className="tour-helper"
      />
    </div>
  );
};

export default DashboardTour;
