import { decimalToDMS } from 'utils/map';

export const resolveItemFirstInformation = (type_id, name, code, meta) => {
  switch (type_id) {
    case 1:
    case 5:
      return code;
    case 2:
      return name;
    case 3:
      if (name?.match?.(/^[0-9]{6}[NS]\s[0-9]{7}[EW]$/g)) {
        return 'Coordenadas';
      }
      return name;

    case 4:
      return code;
    case 6:
      return `${code} NDB ${meta && meta.frequency && meta.frequency.toFixed(1)}`;
    case 7:
      return `${code} VOR ${meta && meta.frequency && meta.frequency.toFixed(1)}`;
    case 8:
      return `${code} VOR/DME ${meta && meta.frequency && meta.frequency.toFixed(1)}`;

    default:
      return code;
  }
};

export const resolveItemSecondInformation = value => {
  return `${decimalToDMS(value.latitude, false)} ${decimalToDMS(value.longitude, true)}`;
};
