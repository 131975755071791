import React from 'react';
import Button from 'react-uwp/Button';

import nl2br from 'react-nl2br';

import './ConfirmModal.style.scss';

const ConfirmModal = props => {
  const width = props.width || '280px';

  const yesLabel = props.yesLabel || (props.disableCancel ? 'Ok' : 'Sim');
  const cancelLabel = props.cancelLabel || 'Cancelar';
  const disableCancel = props.disableCancel || false;

  if (props.open) {
    return (
      <div className="confirm-modal__overlay" onClick={() => !disableCancel && props.onFinish()}>
        <div className="confirm-modal__window" style={{ width: '90%', maxWidth: width }} onClick={e => e.stopPropagation()}>
          <div className="confirm-modal__text">{nl2br(props.message)}</div>
          <div className="confirm-modal__actions">
            <Button
              className="confirm-modal__actions__button"
              onClick={e => {
                props.onYes();
                props.onFinish && props.onFinish();
                e.stopPropagation();
              }}
            >
              {yesLabel}
            </Button>
            {!disableCancel && (
              <Button
                className="confirm-modal__actions__button"
                onClick={e => {
                  props.onCancel && props.onCancel();
                  props.onFinish && props.onFinish();
                  e.stopPropagation();
                }}
              >
                {cancelLabel}
              </Button>
            )}
          </div>
        </div>
      </div>
    );
  }
  return null;
};

export default ConfirmModal;
