import React, { Component } from 'react';

import TextBox from 'react-uwp/TextBox';
import Icon from 'react-uwp/Icon';
import close from 'assets/cancel.svg';

import withTourContext from 'context/TourContext/withTourContext';
import metrics from 'utils/metrics';

import WaypointInputSearchBox from './WaypointInputSearchBox';
import './WaypointInputSearch.style.scss';

class WaypointInputSearch extends Component {
  state = {
    open: false,
  };

  shouldComponentUpdate(nextProps, nextState) {
    const { text } = this.props;
    const { open } = this.state;

    return nextProps.text !== text || nextState.open !== open;
  }

  handleAdd = item => {
    const { onAdd, onChange } = this.props;

    onChange('');
    onAdd(item);
  };

  handleSearchClear = () => {
    const { onChange } = this.props;
    onChange('');
  };

  handleBlur = () => {
    const { tourContext } = this.props;

    if (tourContext.condStep) {
      return;
    }
    window.setTimeout(() => this.setState({ open: false }), 300);
  };

  handleEnterDown = e => {
    const { tourContext } = this.props;

    if (!tourContext.condStep) {
      return;
    }

    if (e.key !== 'Enter') {
      return;
    }

    e.stopPropagation();
    e.preventDefault();

    tourContext.dispatchTourClick(metrics.events.MENU_PESQUISA.THIS);
  };

  render() {
    const { open } = this.state;
    const { text, onLocate, onFocus, onChange } = this.props;

    return (
      <div className="map__input-search" id="wpt_input" style={{ boxShadow: 'inset 0px 0px 0 2px #bfbfbf !important' }}>
        <div className="map__input-search__input">
          <TextBox
            className="map__input-search__input__placeholder"
            background="#FEFEFE; box-shadow: inset 0px 0px 0 2px #bfbfbf !important; font-size: 13px;"
            placeholder="AD, fixo, auxílio, município ou coordenadas"
            rightNode={
              text ? (
                <img
                  className="map__input-search__close"
                  src={close}
                  width={20}
                  height={25}
                  style={{ margin: '0 8px' }}
                  alt="close"
                  onClick={this.handleSearchClear}
                />
              ) : (
                <Icon style={{ margin: '0 8px' }}>Search</Icon>
              )
            }
            value={text}
            onChangeValue={value => {
              onChange(value);
            }}
            onFocus={focus => {
              this.setState({ open: true });
              onFocus();
            }}
            onBlur={this.handleBlur}
            onKeyDown={this.handleEnterDown}
          />
          <WaypointInputSearchBox search={text} show={open} onLocate={onLocate} onAdd={this.handleAdd} />
        </div>
      </div>
    );
  }
}

export default withTourContext(WaypointInputSearch);
