import { Model, attr, fk } from 'redux-orm';

import { WAYPOINT } from '../actions/types.js';

export default class Waypoint extends Model {
  static reducer(action, Waypoint, session) {
    const { payload, type } = action;

    switch (type) {
      case `${WAYPOINT.LOAD_COLLECTION}_SUCCESS`:
        payload.data.waypoints.forEach(waypoint => {
          Waypoint.upsert({
            id: waypoint[0],
            code: waypoint[1],
            name: waypoint[2],
            latitude: waypoint[3],
            longitude: waypoint[4],
            waypoint_type_id: waypoint[5],
            meta: waypoint[6],
          });
        });
        break;

      case `${WAYPOINT.LOAD_USER_COLLECTION}_SUCCESS`:
        Waypoint.all()
          .filter(waypoint => waypoint.waypoint_type_id === 3)
          .delete();

        payload.data.waypoints.forEach(waypoint => {
          Waypoint.upsert({
            id: waypoint.id,
            code: null,
            name: String(waypoint.name),
            latitude: waypoint.latitude,
            longitude: waypoint.longitude,
            waypoint_type_id: 3,
            meta: {},
          });
        });
        break;

      default:
        break;
    }
  }
}

Waypoint.modelName = 'Waypoint';

Waypoint.fields = {
  id: attr(),
  code: attr(),
  name: attr(),
  latitude: attr(),
  longitude: attr(),
  waypoint_type_id: fk('WaypointType', 'waypoints'),
  last_update_id: attr(),
  meta: attr(),
};
