import { combineReducers, createStore, applyMiddleware } from 'redux';
import { persistStore, persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';

import { composeWithDevTools } from 'redux-devtools-extension/developmentOnly';
import thunk from 'redux-thunk';
import { multiClientMiddleware } from 'redux-axios-middleware';

import appStateReducer from './reducers/appStateReducer';
import authReducer from './reducers/authReducer';
import feedbackReducer from './reducers/feedbackReducer';
import newsReducer from './reducers/newsReducer';
import ormSchema from './ormSchema';

import { createReducer as createORMReducer } from 'redux-orm';
import { loginWebClient, apiWebClient, aerodataWebClient } from '../services/webclient.js';

const persistAuthConfig = {
  key: 'auth$v1.1',
  storage,
  version: 1,
  blacklist: ['error_code', 'password_recovery_sent', 'loading'],
};

const persistNewsConfig = {
  key: 'news$v1.0',
  storage,
  version: 1,
  blacklist: [],
};

const rootReducer = combineReducers({
  appState: appStateReducer,
  auth: persistReducer(persistAuthConfig, authReducer),
  feedback: feedbackReducer,
  news: persistReducer(persistNewsConfig, newsReducer),
  orm: createORMReducer(ormSchema),
});

const initialState = {};

const middleware = [
  thunk,
  multiClientMiddleware({
    login: {
      client: loginWebClient,
    },
    api: {
      client: apiWebClient,
    },
    aerodata: {
      client: aerodataWebClient,
    },
  }),
];

export const store = createStore(rootReducer, initialState, composeWithDevTools(applyMiddleware(...middleware)));
export const persistor = persistStore(store);
