import React, { useCallback, useMemo } from 'react';
import { connect } from 'react-redux';
import L from 'leaflet';

import ReactGA from 'react-ga';

import { Marker, Tooltip } from 'react-leaflet';
import { GeodesicLine } from 'react-leaflet-geodesic';
import Icon from 'react-uwp/Icon';

import { getActiveRoute } from 'redux/selectors/route';

import metrics from '../../../../utils/metrics';

import classnames from 'classnames';

import './MapRoute.style.scss';

const defaultIcon = L.icon({
  iconUrl: require('../../assets/map-marker.png'),
  iconSize: [12, 12],
  iconAnchor: [6, 6],
  popupAnchor: [-1, -8],
});

const upIcon = L.icon({
  iconUrl: require('../../assets/map-marker.png'),
  iconSize: [12, 12],
  iconAnchor: [6, 6],
  popupAnchor: [-1, -8],
});

const downIcon = L.icon({
  iconUrl: require('../../assets/map-marker.png'),
  iconSize: [12, 12],
  iconAnchor: [6, 6],
  popupAnchor: [-1, -8],
});

const loadingIcon = L.icon({
  iconUrl: require('../../assets/map-marker-loading.png'),
  iconSize: [12, 12],
  iconAnchor: [6, 6],
  popupAnchor: [-1, -8],
});

const highlightedIcon = L.icon({
  iconUrl: require('../../assets/map-marker-highlighted.png'),
  iconSize: [42, 42],
  iconAnchor: [21, 21],
  popupAnchor: [-1, -8],
});

const getLabel = waypoint => {
  switch (waypoint.waypoint_type_id) {
    case 1:
    case 5:
      return waypoint.code;
    case 2:
      return waypoint.name;
    case 3:
      return waypoint.name;
    case 4:
    case 9:
      return waypoint.code;
    case 6:
      return `${waypoint.code} NDB`;
    case 7:
      return `${waypoint.code} VOR`;
    case 8:
      return `${waypoint.code} VOR/DME`;

    default:
      return waypoint.code;
  }
};

const MapRoute = props => {
  let waypoints = [];
  if (props.activeRoute && props.activeRoute.waypoints) {
    waypoints = props.activeRoute.waypoints;
  }

  const getIcon = useCallback((waypoint, i) => {
    if (waypoint.loading) return loadingIcon;
    if (waypoint.isHighlighted) return highlightedIcon;
    if (i === 0) return upIcon;
    if (i === waypoint.length - 1) return downIcon;
    return defaultIcon;
  }, []);

  const isSomeWaypointHighlighted = useMemo(() => waypoints.some(waypoint => waypoint.isHighlighted), [waypoints]);

  return (
    <div>
      {waypoints.map((waypoint, i) => {
        const isDragging = props.mapSearchWaypointActive && props.mapSearchWaypointActive.i === i;

        return (
          <Marker
            position={
              isDragging ? [props.mapSearchWaypointActive.pos.lat, props.mapSearchWaypointActive.pos.lng] : [waypoint.latitude, waypoint.longitude]
            }
            draggable={!waypoint.loading}
            onDrag={event => {
              props.onWaypointDrag(event, i);
            }}
            onDragstart={event => props.onWaypointDragStart()}
            onDragend={event => {
              const containerPoint = event.target._map.latLngToContainerPoint(event.target._latlng);
              props.onWaypointDragEnd(waypoint, event.target, {
                x: containerPoint.x + 50,
                y: containerPoint.y,
              });
              metrics.collect(metrics.events.MAPA.WPT.ARRASTAR);
            }}
            key={waypoint.id || waypoint.name}
            icon={getIcon(waypoint, i)}
          >
            {!isDragging && (!isSomeWaypointHighlighted || waypoint.isHighlighted) && (
              <Tooltip
                permanent
                direction="top"
                opacity={1}
                className={classnames('map__route__marker__tooltip', waypoint.isHighlighted && 'map__route__marker__tooltip--highlighted', {
                  info: waypoint.waypoint_type_id === 1 || waypoint.waypoint_type_id === 5,
                })}
                offset={[-1, -8]}
              >
                {getLabel(waypoint)}
                {(waypoint.waypoint_type_id === 1 || waypoint.waypoint_type_id === 5) && (
                  <ReactGA.OutboundLink
                    to={`https://aisweb.decea.gov.br/?i=aerodromos&codigo=${waypoint.code}`}
                    eventLabel="aisweb-waypoint-map"
                    alt="I"
                    target="_blank"
                    rel="noopener noreferrer"
                    className="map__route__marker__tooltip__action"
                    onClick={() => metrics.collect(metrics.events.MAPA.WPT.AISWEB)}
                  >
                    <Icon>Info</Icon>
                  </ReactGA.OutboundLink>
                )}
              </Tooltip>
            )}
          </Marker>
        );
      })}

      <GeodesicLine
        positions={waypoints.map((waypoint, i) => {
          if (props.mapSearchWaypointActive && props.mapSearchWaypointActive.i === i) {
            return [props.mapSearchWaypointActive.pos.lat, props.mapSearchWaypointActive.pos.lng];
          }
          return [waypoint.latitude, waypoint.longitude];
        })}
        options={{
          color: '#007D4A',
          steps: 8,
          weight: 6,
        }}
      />
    </div>
  );
};

const mapStateToProps = (state, props) => ({
  activeRoute: getActiveRoute(state, props),
});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(MapRoute);
