/* global gtag */

import { Component } from 'react';
import { connect } from 'react-redux';
import moment from 'moment';

class ChatBox extends Component {
  loaded = false;

  userIdentified = false;

  scriptElem = null;

  componentDidMount() {
    this.addChatBox();
  }

  componentDidUpdate() {
    const { auth } = this.props;

    if (auth.user && auth.user_profile && !this.userIdentified) {
      console.log('[ChatBox] Identifying user');
      this.pushIdentify();
      this.checkEvents();
      this.userIdentified = true;
    }

    if ((!auth.user || !auth.user_profile) && this.userIdentified) {
      console.log('[ChatBox] Unidentifying user');
      this.pushUnidentify();
      this.userIdentified = false;
    }
  }

  componentWillUnmount() {
    if (window.$crisp) {
      window.$crisp.push(['do', 'chat:hide']);
    }

    if (this.scriptElem) {
      this.scriptElem.parentNode.removeChild(this.scriptElem);
    }
  }

  addChatBox = async () => {
    const { auth } = this.props;

    window.$crisp = [];
    window.$crisp.push(['do', 'chat:show']);
    window.$crisp.push(['safe', true]);
    window.$crisp.push([
      'on',
      'message:sent',
      () => {
        gtag('event', 'conversion', { send_to: 'AW-529361823/k6JCCJWDyvsBEJ_XtfwB' });
      },
    ]);

    window.CRISP_RUNTIME_CONFIG = {
      session_merge : true
    };
    window.CRISP_WEBSITE_ID = '0ba39795-a324-4407-ba15-745f0952b82a';

    const d = document;
    const s = d.createElement('script');
    s.src = 'https://client.crisp.chat/l.js';
    s.async = 1;
    d.getElementsByTagName('head')[0].appendChild(s);

    this.scriptElem = s;
  };

  pushIdentify = () => {
    const { auth } = this.props;

    window.$crisp.push(['set', 'user:nickname', [`${auth.user_profile.name} ${auth.user_profile.surname}`]]);
    window.$crisp.push(['set', 'user:email', [auth.user.email]]);
    window.$crisp.push([
      'set',
      'session:data',
      [
        [
          ['pilot', auth.user_profile.pilot ? 'Piloto' : 'Não piloto'],
          ['aviation_segment', auth.user_profile.aviation_segment],
          ['aircraft_type', auth.user_profile.aircraft_type],
          ['self_classification', auth.user_profile.self_classification],
          ['creation_date', auth.user.creation_date],
          ['email_confirmation_url', `https://app.nexatlas.com/auth/confirmEmail/${auth.user.email_confirmation_token}`],
          ['confirmed_email', auth.user.confirmed_email],
          ['trial_to', auth.user.trial_to],
          ['plan_id', auth.user.plan_id],
          ['plan_name', auth.user.plan && auth.user.plan.text],
          ['subscription_status', auth.user.subscription_status],
          ['subscription_method', auth.user.subscription_method],
          ['subscription_created', auth.user.subscription_created],
          ['subscription_updated', auth.user.subscription_updated],
          ['subscription_boleto_url', auth.user.subscription_boleto_url],
        ],
      ],
    ]);

    const userSession = auth.user.salt;
    if (userSession) {
      window.CRISP_TOKEN_ID = userSession;
      window.$crisp.push(['do', 'session:reset']);
    }
  };

  pushUnidentify = () => {
    if (window.CRISP_TOKEN_ID) {
      window.CRISP_TOKEN_ID = null;
    }

    window.$crisp.push(['do', 'session:reset']);
  };

  checkEvents = () => {
    const { auth } = this.props;

    if (auth.user.subscription_status === 'paid') {
      window.$crisp.push(['set', 'session:event', [[['enter-paid']]]]);
    }

    const trialTo = moment(auth.user.trial_to);
    const today = moment().startOf('day');
    const daysFromTrial = Math.round(moment.duration(today - trialTo).asDays());
    if (auth.user_profile.aviation_segment === 'Piloto aluno' && !auth.user.subscription_status && daysFromTrial >= -5) {
      window.$crisp.push(['set', 'session:event', [[['14-dia-trial-piloto-aluno']]]]);
    }

    if (auth.user.subscription_method === 'boleto') {
      window.$crisp.push(['set', 'session:event', [[['enter-boleto']]]]);
    }

    if (
      auth.user.subscription_method === 'credit_card' &&
      (auth.user.subscription_status === 'pending_payment' || auth.user.subscription_status === 'unpaid')
    ) {
      window.$crisp.push(['set', 'session:event', [[['enter-credit-card-unpaid']]]]);
    }
  };

  render() {
    return null;
  }
}

const mapDispatch = {};

const mapStateToProps = state => ({ auth: state.auth });

export default connect(mapStateToProps, mapDispatch)(ChatBox);
