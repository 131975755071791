import { Model, attr } from 'redux-orm';
import * as Sentry from '@sentry/browser';

import uuidv1 from 'uuid/v1';
import { ROUTE } from '../actions/types.js';

export default class Route extends Model {
  static reducer(action, Route, session) {
    const { payload, type } = action;

    let generatedWaypointID;

    switch (type) {
      case `${ROUTE.LOAD_COLLECTION}_SUCCESS`:
        payload.data.routes.forEach(route => {
          Route.upsert({
            id: route.id,
            name: route.name,
            total_distance: route.total_distance,
            ground_speed: route.ground_speed,
            is_simple: route.is_simple,
            favourite: route.favourite,
            deleted: route.deleted,
            deleted_on: route.deleted_on,
            creation_date: route.creation_date,
            updated_on: route.updated_on,
          });

          if (route.allwaypoints) {
            route.allwaypoints.forEach(routeWaypoint => {
              session.RouteWaypoint.upsert({
                id: routeWaypoint.id,
                route_id: routeWaypoint.route_id,
                sequence: routeWaypoint.sequence,
                waypoint_id: routeWaypoint.waypoint_id,
                waypoint_type_id: routeWaypoint.waypoint_type_id,
                code: routeWaypoint.code,
                name: routeWaypoint.name,
                latitude: routeWaypoint.latitude,
                longitude: routeWaypoint.longitude,
                is_origin: routeWaypoint.is_origin,
                is_destination: routeWaypoint.is_destination,
                meta: routeWaypoint.meta ? JSON.parse(routeWaypoint.meta) : {},
                creation_date: routeWaypoint.creation_date,
                isHighlighted: false,
              });
            });
          }
        });
        break;

      case `${ROUTE.UPDATE_ALL}_SUCCESS`:
        const updateRoute = payload.response.data;

        if (updateRoute.route) {
          if (updateRoute.waypoints) {
            updateRoute.waypoints.forEach(routeWaypoint => {
              session.RouteWaypoint.upsert({ ...routeWaypoint, meta: JSON.parse(routeWaypoint.meta) });
            });
          }

          Route.upsert({ ...updateRoute.route, waypoints: undefined });
        }
        break;

      case ROUTE.CREATE:
        generatedWaypointID = uuidv1();

        Route.upsert({
          id: payload.route_id,
          name: payload.name,
          ground_speed: null,
          deleted: false,
          deleted_on: null,
        });

        session.RouteWaypoint.upsert({
          id: generatedWaypointID,
          route_id: payload.route_id,
          waypoint_id: payload.waypoint_id,
          sequence: 1,
          waypoint_type_id: payload.waypoint_type_id,
          code: payload.code,
          name: payload.name,
          latitude: payload.latitude,
          longitude: payload.longitude,
          meta: payload.meta,
          isHighlighted: false,
        });
        break;

      case ROUTE.INVERT:
        Route.upsert({
          id: payload.route_id,
          name: payload.route_name,
          ground_speed: payload.ground_speed,
          deleted: false,
          deleted_on: null,
        });

        payload.waypoints.forEach(waypoint => {
          session.RouteWaypoint.upsert(waypoint);
        });

        break;

      case ROUTE.SPLIT:
        Route.upsert({
          deleted: false,
          deleted_on: null,
        });

        payload.waypoints.forEach(waypoint => {
          session.RouteWaypoint.upsert(waypoint);
        });

        break;

      case ROUTE.UPDATE:
        Route.withId(payload.id).update(payload);
        break;

      case ROUTE.ADD_WAYPOINT:
        generatedWaypointID = uuidv1();

        // Correcting other waypoints sequence
        session.RouteWaypoint.all()
          .filter(rw => rw.route_id === payload.route_id && rw.sequence >= payload.sequence)
          .toRefArray()
          .forEach(rw => {
            session.RouteWaypoint.upsert({
              ...rw,
              sequence: rw.sequence + 1,
            });
          });

        // Adding new waypoint
        session.RouteWaypoint.upsert({
          id: generatedWaypointID,
          route_id: payload.route_id,
          waypoint_id: payload.waypoint_id,
          sequence: payload.sequence,
          waypoint_type_id: payload.waypoint_type_id,
          code: payload.code,
          name: payload.name,
          latitude: payload.latitude,
          longitude: payload.longitude,
          meta: payload.meta,
          isHighlighted: false,
        });

        // If a new route name is generated, change it
        if (payload.route_name) {
          const activeRoute = Route.withId(payload.route_id);
          Route.upsert({
            ...activeRoute.ref,
            name: payload.route_name,
          });
        }
        break;

      case ROUTE.SWITCH_WAYPOINT:
        generatedWaypointID = uuidv1();

        // Removing old waypoint
        const oldWaypoint = session.RouteWaypoint.withId(payload.old_waypoint.id);
        if (oldWaypoint) {
          oldWaypoint.delete();
        }

        // Adding new waypoint
        session.RouteWaypoint.upsert({
          id: generatedWaypointID,
          route_id: payload.route_id,
          waypoint_id: payload.new_waypoint.id,
          sequence: payload.old_waypoint.sequence,
          waypoint_type_id: payload.new_waypoint.waypoint_type_id,
          code: payload.new_waypoint.code,
          name: payload.new_waypoint.name,
          latitude: payload.new_waypoint.latitude,
          longitude: payload.new_waypoint.longitude,
          meta: payload.new_waypoint.meta,
          isHighlighted: false,
        });

        // If a new route name is generated, change it
        if (payload.route_name) {
          const activeRoute = Route.withId(payload.route_id);
          Route.upsert({
            ...activeRoute.ref,
            name: payload.route_name,
          });
        }
        break;

      case ROUTE.UPDATE_WAYPOINT:
        session.RouteWaypoint.upsert({ ...payload });
        break;

      case ROUTE.SORT_WAYPOINT:
        // Correcting other waypoints sequence
        if (payload.oldIndex > payload.newIndex) {
          session.RouteWaypoint.all()
            .filter(rw => rw.route_id === payload.route_id && rw.sequence > payload.newIndex && rw.sequence <= payload.oldIndex + 1)
            .toRefArray()
            .forEach(rw => {
              session.RouteWaypoint.upsert({
                ...rw,
                sequence: rw.sequence + 1,
              });
            });
        } else {
          session.RouteWaypoint.all()
            .filter(rw => rw.route_id === payload.route_id && rw.sequence > payload.oldIndex && rw.sequence <= payload.newIndex + 1)
            .toRefArray()
            .forEach(rw => {
              session.RouteWaypoint.upsert({
                ...rw,
                sequence: rw.sequence - 1,
              });
            });
        }

        // Changing sequence of the waypoint
        const sortingWaypointModel = session.RouteWaypoint.withId(payload.sortingWaypoint.id);
        sortingWaypointModel.update({
          ...sortingWaypointModel.ref,
          sequence: payload.newIndex + 1,
        });

        // If a new route name is generated, change it
        if (payload.route_name) {
          const activeRoute = Route.withId(payload.route_id);
          Route.upsert({
            ...activeRoute.ref,
            name: payload.route_name,
          });
        }
        break;

      case ROUTE.REMOVE_WAYPOINT: {
        const deletingWaypoint = session.RouteWaypoint.withId(payload.waypoint_id);

        if (!deletingWaypoint) {
          Sentry.captureMessage(`Cannot found deleting waypoint. waypoint_id=${payload.waypoint_id}`);
          return;
        }

        const { sequence } = deletingWaypoint;
        deletingWaypoint.delete();

        session.RouteWaypoint.all()
          .filter(rw => rw.route_id === payload.route_id && rw.sequence > sequence)
          .toRefArray()
          .forEach(rw => {
            session.RouteWaypoint.upsert({
              ...rw,
              sequence: rw.sequence - 1,
            });
          });
        break;
      }

      case ROUTE.HIGHLIGHT_WAYPOINT: {
        const highlightingWaypoint = session.RouteWaypoint.withId(payload.waypoint_id);

        highlightingWaypoint.update({ isHighlighted: true });

        break;
      }

      case ROUTE.FADE_WAYPOINT: {
        const fadingWaypoint = session.RouteWaypoint.withId(payload.waypoint_id);

        fadingWaypoint.update({ isHighlighted: false });

        break;
      }

      case ROUTE.DELETE:
        Route.withId(payload.request.data.route_id).delete();
        break;

      default:
        break;
    }
  }
}

Route.modelName = 'Route';

Route.fields = {
  id: attr(),
  name: attr(),
  ground_speed: attr(),
  deleted: attr(),
  deleted_on: attr(),
  creation_date: attr(),
  updated_on: attr(),
};
