import React from 'react';
import PropTypes from 'prop-types';

import CheckBox from 'react-uwp/CheckBox';

import InlineButton from 'components/form/InlineButton/InlineButton';

const ContentProfile3e3 = props => {
  const { terms, error, onChangeTerms, onTerms } = props;

  return (
    <div>
      <p>
        É importante você tome conhecimento dos nossos <InlineButton link='https://nexatlas.com/termos-de-uso'>Termos de uso</InlineButton> e <InlineButton link='https://nexatlas.com/politica-de-privacidade'>Política de privacidade</InlineButton>.
      </p>
      <p className="auth__create__terms-error">{error ? 'Você deve marcar a caixa abaixo:' : ''}</p>
      <p className="auth__create__terms-checkbox">
        <CheckBox
          defaultChecked={terms}
          label="Garanto que estou ciente e de acordo com os Termos de uso e Política de privacidade da NexAtlas."
          onCheck={onChangeTerms}
        />
      </p>
    </div>
  );
};

ContentProfile3e3.defaultProps = {
  error: false,
  onChangeTerms: () => { },
  onTerms: () => { },
};

ContentProfile3e3.propTypes = {
  terms: PropTypes.bool.isRequired,
  error: PropTypes.bool,
  onChangeTerms: PropTypes.func,
  onTerms: PropTypes.func,
};

export default ContentProfile3e3;
