import { ORM } from 'redux-orm';

import Route from './models/route';
import RouteWaypoint from './models/route_waypoint';
import Waypoint from './models/waypoint';
import WaypointType from './models/waypoint_type';
import News from './models/news';
import Plans from './models/plan';
import DataUpdate from './models/data_update';
import DataUpdateItem from './models/data_update_item';

export const schema = new ORM({
  stateSelector: state => state.orm,
});

schema.register(Route, RouteWaypoint, Waypoint, WaypointType, News, Plans, DataUpdate, DataUpdateItem);

export default schema;
