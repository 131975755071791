import React from 'react';
import { CircleMarker, Tooltip } from 'react-leaflet';
import { GeodesicLine } from 'react-leaflet-geodesic';
import Icon from 'react-uwp/Icon';

import { magneticBearing, distanceNM } from 'utils/map';

import './MapRule.scss';

const MapRule = props => {
  const { ruleActive, ruleStartLatLng, ruleEndLatLng, ruleDragging } = props.rule;

  if (!ruleActive) {
    return null;
  }

  let tooltip = null;
  if (ruleStartLatLng && ruleEndLatLng) {
    const distanceV = distanceNM(ruleStartLatLng, ruleEndLatLng);
    const bearingV = magneticBearing(ruleStartLatLng, ruleEndLatLng);

    tooltip = (
      <Tooltip permanent direction="top" opacity={1} className="map__rule__tooltip">
        <span className="map__rule__tooltip__distance">{`${distanceV} nm`}</span>
        <span className="map__rule__tooltip__bearing">{bearingV}</span>
        {!ruleDragging && ruleEndLatLng && (
          <button
            type="button"
            className="map__rule__tooltip__add"
            onMouseDown={e => {
              props.onAddToMap();
            }}
          >
            <Icon>Add</Icon>
          </button>
        )}
      </Tooltip>
    );
  }

  return (
    <div>
      {ruleStartLatLng && <CircleMarker center={ruleStartLatLng} radius={3} color="#fa7f2d" />}
      {ruleStartLatLng && ruleEndLatLng && (
        <CircleMarker center={ruleEndLatLng} radius={3} color="#fa7f2d">
          {tooltip}
        </CircleMarker>
      )}

      {ruleStartLatLng && ruleEndLatLng && <GeodesicLine positions={[ruleStartLatLng, ruleEndLatLng]} options={{ color: '#fa7f2d', steps: 8 }} />}
    </div>
  );
};

export default MapRule;
