import React from 'react';
import { connect } from 'react-redux';
import { BrowserRouter as Router, Route } from 'react-router-dom';
import { SnackbarProvider } from 'react-snackbar-alert';

import AnalyticsService from 'services/AnalyticsService';

import Snackbar from '../components/Snackbar/Snackbar';

import AuthLayout from './AuthLayout';
import DashboardLayout from './DashboardLayout';
import { AUTH, DASHBOARD } from './router';
import GoTo from './GoTo';

import ChatBox from '../components/ChatBox/ChatBox';

import RouterListener from './RouterListener';

function BaseLayout(props) {
  const { auth } = props;

  return (
    <div className="base">
      <AnalyticsService>
        <SnackbarProvider component={Snackbar} position="bottom-left" timeout={5000}>
          <Router>
            <RouterListener>
              <Route exact path="/" render={props => (!auth.logged_in ? <GoTo location={AUTH.MAIN} /> : <GoTo location={DASHBOARD.MAIN} />)} />

              <Route path={AUTH.MAIN} component={AuthLayout} />

              <Route path={DASHBOARD.MAIN} render={props => (auth.logged_in ? <DashboardLayout {...props} /> : <GoTo location={AUTH.MAIN} />)} />
            </RouterListener>
          </Router>
        </SnackbarProvider>
        <ChatBox />
      </AnalyticsService>
    </div>
  );
}

const mapDispatch = {};

const mapStateToProps = state => ({
  auth: state.auth,
});

export default connect(mapStateToProps, mapDispatch)(BaseLayout);
