import React from 'react';
import PropTypes from 'prop-types';

import './AuthScreen.style.scss';
import InlineButton from 'components/form/InlineButton/InlineButton';

const AuthScreen = props => {
  const { mainContainer, infoContainer } = props;

  return (
    <div className="auth__background">
      <div className="auth__container-wrapper">{mainContainer}</div>
      {infoContainer && <div className="auth__info-wrapper">{infoContainer}</div>}
      <div className="auth__bottom">
        <div className="auth__bottom__terms" role="button" tabIndex="0">
          <InlineButton link={'https://nexatlas.com/termos-de-uso'}>Termos de uso</InlineButton> e <InlineButton link={'https://nexatlas.com/politica-de-privacidade'}>Política de Privacidade</InlineButton>
        </div>
      </div>
    </div>
  );
};

AuthScreen.defaultProps = {
  infoContainer: null,
  onTerms: () => {},
};

AuthScreen.propTypes = {
  mainContainer: PropTypes.node.isRequired,
  infoContainer: PropTypes.node,
  onTerms: PropTypes.func,
};

export default AuthScreen;
