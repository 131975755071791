import React, { useState } from 'react';

import Button from 'react-uwp/Button';
import Icon from 'react-uwp/Icon';
import RadioButton from 'react-uwp/RadioButton';
import CustomScrollbar from 'components/CustomScrollbar/CustomScrollbar';

import metrics from '../../../../../../utils/metrics';

import './ConfigsPaymentMethod.styles.scss';

import nl2br from 'react-nl2br';

export default props => {
  const { values, single, user, onReturn, onFinish } = props;

  const [methodOption, setMethodOption] = useState(values.methodOption);

  return (
    <div className="configs__payment-method__base">
      <div className="configs__title">
        {!single && (
          <Icon onClick={() => onReturn()} className="configs__title__action">
            ChevronLeft
          </Icon>
        )}
        FORMA DE PAGAMENTO
      </div>
      <CustomScrollbar className="configs__scroll">
        <div className="configs__container">
          <div className="configs__container__session">
            <p className="configs__container__paragraph">Como deseja pagar a assinatura?</p>
            <RadioButton
              defaultChecked={methodOption === 'credit_card'}
              label="Cartão de crédito"
              onCheck={() => setMethodOption('credit_card')}
              className="configs__payment-method__radio"
            />
            <RadioButton
              defaultChecked={methodOption === 'boleto'}
              label="Boleto bancário"
              onCheck={() => setMethodOption('boleto')}
              className="configs__payment-method__radio"
            />
            <hr />
            <p className="configs__container__paragraph">
              {methodOption === 'credit_card'
                ? 'Pagando com cartão de crédito é a opção mais simples. Seu acesso será liberado imediatamente.'
                : methodOption === 'boleto'
                ? nl2br(
                    'Nesta opção, um boleto bancário será emitido e enviado para o seu endereço de e-mail.\n\nSeu acesso será liberado assim que o pagamento for confirmado pela instituição financeira, que poderá levar até 3 dias úteis após o pagamento do boleto.',
                  )
                : ''}
            </p>
            {user.subscription_id && user.subscription_status !== 'canceled' && values.methodOption === 'boleto' && (
              <div>
                <p className="configs__container__paragraph">
                  Já existe um boleto emitido para pagamento. Caso deseje apenas efetuar o pagamento do boleto, gere a 2ª via abaixo e o acesso será
                  liberado em pouco tempo. Caso efetue a troca do plano, um novo boleto será gerado e não há a necessidade de pagar o atual.
                </p>
                {user.subscription_boleto_url && (
                  <div
                    className="configs__container__link"
                    onClick={() => {
                      document.location = user.subscription_boleto_url;
                      metrics.collect(metrics.events.DRAWER.CONFIGURACOES.SEG_VIA_BOLETO);
                    }}
                  >
                    Gerar 2ª via do boleto
                  </div>
                )}
              </div>
            )}
            <div className="configs__payment-method__button">
              <Button
                className="user-options__logout__button"
                onClick={() => {
                  onFinish(methodOption);
                  metrics.collect(metrics.events.DRAWER.CONFIGURACOES.ASSINE_JA.FORMA_PGTO, methodOption);
                }}
                disabled={!methodOption}
              >
                {single ? 'Alterar' : 'Próximo'}
              </Button>
            </div>
          </div>
        </div>
      </CustomScrollbar>
    </div>
  );
};
