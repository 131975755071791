import React from 'react';

import Button from 'react-uwp/Button';
import Icon from 'react-uwp/Icon';

import CustomScrollbar from 'components/CustomScrollbar/CustomScrollbar';

export default props => {
  const { onReturn } = props;

  return (
    <div className="configs__base">
      <div className="configs__title">
        <Icon onClick={() => onReturn()} className="configs__title__action">
          LeftArrowKeyTime0
        </Icon>
        SOLICITAÇÃO ENVIADA COM SUCESSO!
      </div>
      <CustomScrollbar className="configs__scroll">
        <div className="configs__container">
          <div className="configs__container__session">
            Um link foi enviado para seu endereço de e-mail para alteração da senha.
            <br />
            <br />
            Acesse sua caixa de e-mails e siga as instruções!
          </div>

          <div className="configs__payment-data__button">
            <Button className="user-options__logout__button" onClick={() => onReturn()}>
              Voltar
            </Button>
          </div>
        </div>
      </CustomScrollbar>
    </div>
  );
};
