import { createSelector } from 'redux-orm';

import { schema } from '../ormSchema';
import { unrefObj, unrefObjArray } from '../../utils/structures';

export const appStateSelector = state => state.appState;

export const getActiveRoute = createSelector([schema, appStateSelector], (session, appState) => {
  const routeModel = session.Route.withId(appState.routeActive);
  if (!routeModel) {
    return null;
  }

  const routeRef = unrefObj(routeModel.ref);

  routeRef.waypoints = unrefObjArray(routeModel.waypoints.toRefArray()).sort((a, b) => {
    return a.sequence - b.sequence;
  });

  return routeRef;
});

export const getUserRoutes = createSelector([schema, appStateSelector], (session, appState) => {
  const routeModels = session.Route.all().toModelArray();

  return routeModels
    .filter(route => route.creation_date)
    .map(route => {
      const rtr = unrefObj(route.ref);
      rtr.waypoints = unrefObjArray(route.waypoints.toRefArray());
      return rtr;
    })
    .sort((a, b) => b.updated_on - a.updated_on);
});
