import React from 'react';

import Button from 'react-uwp/Button';
import CustomScrollbar from 'components/CustomScrollbar/CustomScrollbar';

import './ConfigsDependantEnd.styles.scss';

export default props => {
  const { userProfile, loading, onCheck } = props;

  return (
    <div className="configs__base">
      <div className="configs__title">CONTA DEPENDENTE</div>
      <CustomScrollbar className="configs__scroll">
        <div className="configs__container">
          <div className="configs__container__session">
            <p className="configs__container__paragraph">Olá, {userProfile.name}</p>
            <p className="configs__container__paragraph">Sua conta deixou de ser dependente, você pode iniciar sua própria assinatura a seguir.</p>
            <p className="configs__container__paragraph">Se você acha que isso é um erro, entre em contato com o administrador da sua conta.</p>
            <div className="configs__trial-check__button">
              <Button tooltip="Entrar na NexAtlas" className="user-options__logout__button" onClick={onCheck} disabled={loading}>
                Continuar
              </Button>
            </div>
          </div>
        </div>
      </CustomScrollbar>
      <div className="configs__bottom">
        Não é {userProfile.name}?{' '}
        <div className="configs__link" onClick={props.onLogout}>
          Entre com outra conta
        </div>
      </div>
    </div>
  );
};
