import { createSelector } from 'redux-orm';
import { schema } from '../ormSchema';

import { fakePythagoreanDistance, decimalToDMS, DMSToDecimal } from '../../utils/map';
import { normalizeForSearch } from '../../utils/string';

export const getCloserWaypoints = createSelector(
  schema,
  (_, props) => props.search.mapSearchLatLng,
  (session, position) => {
    if (!position) {
      return null;
    }

    const waypoints = session.Waypoint.all()
      .toModelArray()
      .map(waypoint => ({
        ...waypoint.ref,
        distance: fakePythagoreanDistance(waypoint.longitude, waypoint.latitude, position.lng, position.lat),
      }))
      .filter(waypoint => waypoint.distance < 0.5)
      .sort((a, b) => {
        if (a.distance === b.distance) return 0;

        let distanceA = a.distance;
        if (a.waypoint_type_id === 3) {
          distanceA /= 8;
        }
        if (a.waypoint_type_id === 1) {
          distanceA /= 4;
        }
        if (a.waypoint_type_id === 6 || a.waypoint_type_id === 7 || a.waypoint_type_id === 8 || a.waypoint_type_id === 9) {
          distanceA /= 2;
        }

        let distanceB = b.distance;
        if (b.waypoint_type_id === 3) {
          distanceB /= 8;
        }
        if (b.waypoint_type_id === 1) {
          distanceB /= 4;
        }
        if (b.waypoint_type_id === 6 || b.waypoint_type_id === 7 || b.waypoint_type_id === 8 || b.waypoint_type_id === 9) {
          distanceB /= 2;
        }

        return distanceA - distanceB;
      })
      .slice(0, 14);

    return [
      {
        id: null,
        distance: 0,
        code: null,
        latitude: position.lat,
        longitude: position.lng,
        name: `${decimalToDMS(position.lat)} ${decimalToDMS(position.lng, true)}`,
        meta: {},
        waypoint_type_id: 3,
      },
      ...waypoints,
    ];
  },
);

/* 
  ------Listagem dos tipos de ID's:
  1 --> Aeródromos
  2 --> Cidades
  3 --> Coordenadas
  4 --> Fixos
  5 --> Helipontos
  6 --> Auxílios NDB
  7 --> Auxílios VOR
  8 --> Auxílios VOR/DME
*/

export const searchWaypoints = createSelector(
  schema,
  (_, props) => props.search,
  (session, search) => {
    if (!search) {
      return null;
    }

    const latRE = /^([0-9]{1,2})\s*[º°]?\s*([0-5]{0,1}[0-9]{0,1})?\s*["']?\s*([0-5]{0,1}[0-9]{0,1})?\s*["']?\s*([NnSs])?\s*\/?,?-?\s*$/gi;
    const latSearch = latRE.exec(search);
    if (latSearch) {
      const position = DMSToDecimal(latSearch);

      return [
        {
          id: null,
          distance: 0,
          code: null,
          latitude: position.lat,
          longitude: 0,
          name: `${decimalToDMS(position.lat)} ${decimalToDMS(0, true)}`,
          meta: {},
          waypoint_type_id: 3,
          score: 0,
        },
      ];
    }

    const latlngRE = /^([0-9]{1,2})\s*[º°]?\s*([0-5]{0,1}[0-9]{0,1})?\s*["']?\s*([0-5]{0,1}[0-9]{0,1})?\s*["']?\s*([NnSs])?\s*\/?,?-?\s*([0-9]{1,3})\s*[º°]?\s*([0-5]{0,1}[0-9]{0,1})?\s*["']?\s*([0-5]{0,1}[0-9]{0,1})?\s*["']?\s*([LlOoEeWw])?\s*$/gi;
    const latlngSearch = latlngRE.exec(search);
    if (latlngSearch) {
      const position = DMSToDecimal(latlngSearch);

      return [
        {
          id: null,
          distance: 0,
          code: null,
          latitude: position.lat,
          longitude: position.lng,
          name: `${decimalToDMS(position.lat)} ${decimalToDMS(position.lng, true)}`,
          meta: {},
          waypoint_type_id: 3,
        },
      ];
    }

    search = normalizeForSearch(search);

    const orderBySearchScore = (waypoints, searchStr) => {
      return waypoints
        .map(waypoint => {
          // Calcular score
          if (searchStr && (searchStr === waypoint.name || searchStr === waypoint.code)) {
            return { ...waypoint, score: 100 };
          }

          const matchStartWith = new RegExp(`/^${searchStr}/`);
          if ((waypoint.name && waypoint.name.match(matchStartWith)) || (waypoint.code && waypoint.code.match(matchStartWith))) {
            return { ...waypoint, score: 5 };
          }

          const matchInside = new RegExp(`^.+${searchStr}.*$`);
          if ((waypoint.name && waypoint.name.match(matchInside)) || (waypoint.code && waypoint.code.match(matchInside))) {
            return { ...waypoint, score: 2 };
          }

          return { ...waypoint, score: 1 };
        })
        .sort((a, b) => {
          return b.score - a.score;
        });
    };

    const waypointsStarts = session.Waypoint.all()
      .toRefArray()
      .filter(
        waypoint =>
          (waypoint.code && waypoint.code.toUpperCase().startsWith(search)) ||
          normalizeForSearch(waypoint.name).startsWith(search) ||
          (waypoint.meta && waypoint.meta.city && normalizeForSearch(waypoint.meta.city).startsWith(search)),
      );

    if (waypointsStarts.length > 15) {
      return [...orderBySearchScore(waypointsStarts, search)].slice(0, 16);
    }

    const waypointsIncludes = session.Waypoint.all()
      .toRefArray()
      .filter(waypoint => {
        return (
          ((waypoint.code && waypoint.code.toUpperCase().includes(search)) || normalizeForSearch(waypoint.name).includes(search)) &&
          !((waypoint.code && waypoint.code.toUpperCase().startsWith(search)) || normalizeForSearch(waypoint.name).startsWith(search))
        );
      })
      .slice(0, 16 - waypointsStarts.length - 1);

    return [...orderBySearchScore(waypointsStarts.concat(waypointsIncludes), search)];
  },
);

export const userWaypointsSelector = createSelector(schema, session => {
  const userWaypoints = session.Waypoint.all()
    .filter(waypoint => waypoint.waypoint_type_id === 3)
    .toModelArray();
  return userWaypoints;
});
