import React, { useState } from 'react';
import Loader from 'react-loader-spinner';

import './NexMarket.style.scss';

export const NexMarket = () => {
  const [loaded, setLoaded] = useState(false);

  return (
    <div className="nex-market">
      {!loaded ? (
        <div className="nex-market__loader">
          <Loader type="TailSpin" color="#fcfcfc" height={80} width={80} />
        </div>
      ) : null}
      <iframe
        src="https://market.nexatlas.com/list?embedded=true"
        title="NexMarket"
        className="nex-market__iframe"
        width="100%"
        height="100%"
        frameBorder="0"
        style={{ display: loaded ? 'block' : 'none' }}
        onLoad={() => setLoaded(true)}
      />
    </div>
  );
};
