import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';

import TextBox from 'react-uwp/TextBox';
import Tooltip from 'react-uwp/Tooltip';

import { useTourContext } from 'context/TourContext/TourContext';

import { setVS } from 'redux/actions/routeActions';

import './VSInput.style.scss';

import { checkUInt } from 'utils/compare';
import metrics from 'utils/metrics';

import classnames from 'classnames';
import { textBoxStyle } from './VSInput.style';

const VSInput = props => {
  const { disabled } = props;

  const tourContext = useTourContext();

  const [VSValue, setVSValue] = useState(props.vs || '');

  const _setVSValue = value => {
    if (value === '') {
      setVSValue('');
      props.setVS(null);
      return;
    }

    if (!checkUInt(value)) return;

    const intval = parseInt(value, 10);

    setVSValue(intval);
    props.setVS(intval);
  };

  useEffect(() => {
    if (disabled) {
      setVSValue('');
    }
  }, [disabled]);

  useEffect(() => {
    tourContext.dispatchTourCondition(metrics.events.DRAWER.PLANEJAR_ROTA.INPUT_GS, VSValue > 0);
  }, [VSValue, tourContext]);

  return (
    <div className="vs-input">
      <Tooltip content="Velocidade de Solo">
        <span className={classnames({ disabled: props.disabled })}>VS: </span>
      </Tooltip>
      <TextBox
        textBoxStyle={textBoxStyle}
        placeholder="kt"
        maxLength={3}
        value={VSValue}
        onChangeValue={value => {
          if (!props.disabled) {
            _setVSValue(value);
          }
        }}
      />
    </div>
  );
};

const mapStateToProps = (state, props) => ({});

const mapDispatchToProps = {
  setVS,
};

export default connect(mapStateToProps, mapDispatchToProps)(VSInput);
