import { createSelector } from 'redux-orm';
import { schema } from '../ormSchema';

export const ormSelector = state => state.orm;

export const getNews = createSelector([schema], session => {
  return session.News.all().toRefArray();
});

export const getLastNewsId = createSelector([schema], session => {
  const first = session.News.first();
  if (first) {
    return first.id;
  }

  return -1;
});
