import React from 'react';

import Icon from 'react-uwp/Icon';

import LogoImage from 'assets/logos/nexatlas_horizontal.png';
import NovidadesIcon from 'assets/icons/barras/news.svg';
import NovaRotaIcon from '../../../pages/MapMain/components/RoutePanel/assets/icon_new.svg';
import SalvarIcon from '../../../pages/MapMain/components/RoutePanel/assets/icon_save.svg';
import ImprimirIcon from '../../../pages/MapMain/components/RoutePanel/assets/icon_print.svg';
import CentralizarIcon from './assets/icon_findmap.svg';
import DragGif from './assets/drag.gif';
import FinishGif from './assets/onboarding.gif';
import './Cards.styles.scss';

const Cards = [
  // Card 0
  {
    button: ['start', 'cancel'],
    content: user => (
      <div className="tour__list">
        <img src={LogoImage} alt="" className="tour__logo" />
        <div className="tour__text">Olá {user}, tudo bem?</div>
        <div className="tour__text">Criamos um breve tutorial para ensinar como melhor aproveitar o aplicativo NexAtlas.</div>
        <div className="tour__text">Leva cerca de 10 minutos e contém tudo que você precisa saber para se tornar um NexPilot!</div>
      </div>
    ),
  },
  // Card 1
  {
    selector: '#leaflet-map',
    button: 'next',
    position: [window.innerWidth / 5, window.innerHeight / 3],
    content: (
      <div className="tour__list">
        <div className="tour__text">
          Nossa plataforma funciona de forma semelhante ao Google Maps, você pode arrastar o mapa, aumentar ou diminuir o zoom como for melhor para
          você.
        </div>
      </div>
    ),
  },
  // Card 2
  {
    selector: '#cartas_aeronauticas',
    content: (
      <div className="tour__list">
        <div className="tour__text">Vamos começar escolhendo quais cartas aeronáuticas e mapas queremos habilitar.</div>
        <div className="tour__text tour__text--cta">Clique no botão para ver as opções</div>
      </div>
    ),
  },
  // Card 3
  {
    selector: '.map__options',
    content: (
      <div className="tour__list">
        <div className="tour__text">
          Nesse menu você pode selecionar as cartas que serão exibidas e um mapa base, de acordo com o tipo de voo que irá realizar.
        </div>
        <div className="tour__text tour__text--cta">Selecione a carta “ENRC High” para prosseguir</div>
      </div>
    ),
  },
  // Card 4
  {
    selector: '#leaflet-map',
    button: 'next',
    position: [window.innerWidth / 4, window.innerHeight / 3],
    content: (
      <div className="tour__list">
        <div className="tour__text">
          Na hora de realizar seu planejamento você poderá escolher, entre todas as cartas disponíveis, aquela que atende exatamente ao seu tipo de
          voo.
        </div>
      </div>
    ),
  },
  // Card 5
  {
    selector: '#wpt_input',
    button: 'next',
    disableFocusTrap: true,
    content: (
      <div className="tour__list">
        <div className="tour__text">
          Nosso sistema de buscas consegue localizar aeródromos, fixos, auxílios, municípios ou coordenadas. Esperto, não?
        </div>
        <div className="tour__text">Agora é hora de você escolher seu ponto de partida.</div>
        <div className="tour__text tour__text--cta">Digite o termo e clique em próximo.</div>
      </div>
    ),
  },
  // Card 6
  {
    selector: '.map__input-search__box',
    content: (
      <div className="tour__list">
        <div className="tour__text">
          É possível realizar 3 ações com o resultado de uma pesquisa: localizar, obter informações e adicionar à rota.
        </div>
        <div className="tour__list">
          <div className="tour__list__row">
            <div className="tour__list__row__icon">
              <img alt="" src={CentralizarIcon} />
            </div>
            <div className="tour__text">Localizar tem a função de centralizar o mapa no ponto escolhido.</div>
          </div>
          <div className="tour__list__row">
            <div className="tour__list__row__icon">
              <Icon className="tour__icon">Info</Icon>
            </div>
            <div className="tour__text">
              Obter informações é um atalho que abre direto a página AISWEB com todos os detalhes do ponto (só para aeroportos).
            </div>
          </div>
          <div className="tour__list__row">
            <div className="tour__text">Clicar sobre o nome do ponto desejado o adiciona à rota.</div>
          </div>
        </div>
        <div className="tour__text tour__text--cta">Adicione seu ponto de partida.</div>
      </div>
    ),
  },
  // Card 7
  {
    selector: '#wpt_input',
    button: 'next',
    disableFocusTrap: true,
    content: (
      <div className="tour__list">
        <div className="tour__text">Agora, busque pelo seu ponto de destino.</div>
        <div className="tour__text tour__text--cta">Após digitá-lo, clique em &quot;PRÓXIMO PASSO&quot;</div>
      </div>
    ),
  },
  // Card 8
  {
    selector: '.map__input-search__box',
    content: (
      <div className="tour__list">
        <div className="tour__text">
          Genial! Agora <span className="tour__text--cta">clique sobre o nome do ponto de destino</span> para adicioná-lo à sua rota.
        </div>
      </div>
    ),
  },
  // Card 9
  {
    selector: '#leaflet-map',
    button: 'next',
    position: [window.innerWidth / 4, window.innerHeight / 3],
    content: (
      <div className="tour__list">
        <div className="tour__text">Você está progredindo!</div>
        <div className="tour__text">Para avançarmos, é preciso adicionar um ponto intermediário entre o ponto de partida e o de destino. </div>
      </div>
    ),
  },
  // Card 10
  {
    selector: '#leaflet-map',
    position: [window.innerWidth / 4, window.innerHeight / 3],
    content: (
      <div className="tour__list">
        <div className="tour__text">
          Os mapas aeronáuticos são interativos. Ao se clicar em qualquer ponto do mapa pode-se ver pontos de interesse próximos e as coordenadas do
          ponto.{' '}
        </div>
        <div className="tour__text">
          <span className="tour__text--cta">Clique em qualquer lugar do mapa, de preferência próximo ao percurso que se pretende fazer.</span>
        </div>
      </div>
    ),
  },
  // Card 11
  {
    selector: '#mapa_wpt',
    // position: [window.innerWidth / 4, window.innerHeight / 3],
    content: (
      <div className="tour__list">
        <div className="tour__text">Esses são pontos de referência próximos ao ponto em que você clicou.</div>
        <div className="tour__text">
          <span className="tour__text--cta">Clique sobre um deles para selecioná-lo.</span>
        </div>
      </div>
    ),
  },
  // Card 12
  {
    selector: '.route-panel',
    button: 'next',
    disableFocusTrap: true,
    content: (
      <div className="tour__list">
        <div className="tour__text">Agora você pode ver todos os pontos que inseriu no painel de rotas e traçados sobre o mapa.</div>
      </div>
    ),
  },
  // Card 13
  {
    selector: '.vs-input',
    button: 'next',
    disableFocusTrap: true,
    content: (
      <div className="tour__list">
        <div className="tour__text">
          Para obter o tempo de voo, <span className="tour__text--cta">insira sua velocidade nesta caixa</span> (Ground Speed).
        </div>
      </div>
    ),
  },
  // Card 14
  {
    selector: '.route-planning-item__distance-field',
    button: 'next',
    stepInteraction: false,
    content: (
      <div className="tour__list">
        <div className="tour__text">
          Para cada etapa você terá informações da proa magnética, distância e tempo de voo. Os números verdes são os valores parciais e os amarelos
          são os cumulativos.
        </div>
      </div>
    ),
  },
  // Card 15
  {
    selector: '.route-planning',
    content: (
      <div className="tour__list">
        <div className="tour__text">
          Você pode facilmente remanejar a ordem dos pontos na sua rota clicando e arrastando o ponto para a nova posição.
        </div>
        <img src={DragGif} alt="" className="tour__gif tour__gif__drag" />
        <div className="tour__text tour__text--cta">Reorganize os pontos da sua rota</div>
      </div>
    ),
  },
  // Card 16
  {
    selector: '.route-planning--items',
    content: (
      <div className="tour__list">
        <div className="tour__text">
          <span className="tour__text--cta">Exclua um ponto da sua rota</span> clicando sobre o X.
        </div>
      </div>
    ),
  },
  // Card 17
  {
    selector: '.route-panel__header__head',
    button: 'next',
    disableFocusTrap: true,
    content: (
      <div className="tour__list">
        <div className="tour__text">Aqui você tem as seguintes opções:</div>
        <div className="tour__list__row">
          <div className="tour__list__row__icon">
            <img src={NovaRotaIcon} alt="" />
          </div>
          <div className="tour__text">Reinicia o processo de criação de rota.</div>
        </div>
        <div className="tour__list__row">
          <div className="tour__list__row__icon">
            <img src={SalvarIcon} alt="" />
          </div>
          <div className="tour__text">Salva a rota para consultas futuras.</div>
        </div>
        <div className="tour__list__row">
          <div className="tour__list__row__icon">
            <img className="tour__icon" src={ImprimirIcon} alt="" />
          </div>
          <div className="tour__text">Imprime um arquivo com os dados necessários para sua viagem.</div>
        </div>
        <div className="tour__list__row">
          <div className="tour__list__row__icon">
            <Icon className="tour__icon">Edit</Icon>
          </div>
          <div className="tour__text">Possibilita renomear sua rota e os pontos definidos por coordenadas.</div>
        </div>
      </div>
    ),
  },
  // Card 18
  {
    selector: '#salvar_rota',
    content: (
      <div className="tour__list">
        <div className="tour__text">
          <span className="tour__text--cta">Clique neste botão</span> e vamos salvar essa rota como teste.
        </div>
      </div>
    ),
  },
  // Card 19
  {
    selector: '#rotas_salvas',
    content: (
      <div className="tour__list">
        <div className="tour__text">A rota que você acabou de salvar agora vai aparecer aqui.</div>
        <div className="tour__text tour__text--cta">Clique neste botão.</div>
      </div>
    ),
  },
  // Card 20
  {
    selector: '.favorites',
    button: 'next',
    content: (
      <div className="tour__list">
        <div className="tour__text">
          Todas as rotas que você salvar ficarão disponíveis aqui para acesso rápido. Quando você não renomear uma rota, ela irá aparecer com o nome
          “ponto de partida - ponto de destino”.
        </div>
        <div className="tour__text">Você ainda pode visualizar as datas de atualização das rotas e até ordená-las por ordem alfabética ou data.</div>
      </div>
    ),
  },
  // Card 21
  {
    selector: '#regua',
    content: (
      <div className="tour__list">
        <div className="tour__text">Agora vamos experimentar a função Régua.</div>
        <div className="tour__text tour__text--cta">Clique no ícone da régua</div>
      </div>
    ),
  },
  // Card 22
  {
    selector: '#leaflet-map',
    position: [window.innerWidth / 3, window.innerHeight / 5],
    content: (
      <div className="tour__list">
        <div className="tour__text tour__text--cta">Clique no mapa para iniciar a medição</div>
      </div>
    ),
  },
  // Card 23
  {
    selector: '#leaflet-map',
    position: [window.innerWidth / 3, window.innerHeight / 3],
    content: (
      <div className="tour__list">
        <div className="tour__text tour__text--cta">Clique novamente para finalizar</div>
      </div>
    ),
  },
  // Card 24
  {
    selector: '.map__rule__tooltip',
    button: 'next',
    content: (
      <div className="tour__list">
        <div className="tour__text">As informações entregues são: distância e marcação magnética (direção).</div>
      </div>
    ),
  },
  // Card 25
  {
    selector: '#regua',
    content: (
      <div className="tour__list">
        <div className="tour__text tour__text--cta">Desligue a régua clicando novamente no botão</div>
      </div>
    ),
  },
  // Card 26
  {
    selector: '#mapas_meteorologicos',
    content: (
      <div className="tour__list">
        <div className="tour__text">
          Para verificar as condições meteorológicas de ventos, nuvens ou chuva previstas para o seu voo,{' '}
          <span className="tour__text-cta">clique neste ícone</span>
        </div>
      </div>
    ),
  },
  // Card 27
  {
    selector: '.methereology__options__item',
    content: (
      <div className="tour__list">
        <div className="tour__text">Perfeito! Vamos explorar um pouco um dos mapas?</div>
        <div className="tour__text tour__text--cta">Selecione “Ventos”</div>
      </div>
    ),
  },
  // Card 28
  {
    selector: '.map-main',
    button: 'next',
    stepInteraction: false,
    position: [window.innerWidth / 4, window.innerHeight / 3],
    content: (
      <div className="tour__list">
        <div className="tour__text">Nossa camada meteorológica fornecida pelo site Windy traz informações atualizadas e de fácil interpretação.</div>
      </div>
    ),
  },
  // Card 29
  {
    selector: '#progress-bar',
    button: 'next',
    content: (
      <div className="tour__list">
        <div className="tour__text">Aqui você pode selecionar em qual dia e horário gostaria de visualizar a meteorologia.</div>
        <div className="tour__text">Ou pode ainda clicar sobre o ícone de iniciar/pausar para observar a animação das informações.</div>
      </div>
    ),
  },
  // Card 30
  {
    selector: '#map-container',
    button: 'next',
    position: [window.innerWidth / 4, window.innerHeight / 3],
    content: (
      <div className="tour__list">
        <div className="tour__text">
          Ajuste a barra no canto inferior esquerdo para selecionar a altitude para a qual deseja visualizar as informações e clique sobre o mapa para
          receber informação pontual.
        </div>
      </div>
    ),
  },
  // Card 31
  {
    selector: '.main-drawer__container-bottom',
    button: 'next',
    stepInteraction: false,
    content: (
      <div className="tour__list">
        <div className="tour__text">Aqui você encontra duas outras opções:</div>
        <div className="tour__list__row">
          <div className="tour__list__row__icon">
            <img src={NovidadesIcon} alt="" />
          </div>
          <div className="tour__text">Novidades: nossos anúncios de atualizações dos dados e novas funcionalidades.</div>
        </div>
        <div className="tour__list__row">
          <div className="tour__list__row__icon">
            <Icon>Settings</Icon>
          </div>
          <div className="tour__text">
            Configurações: altere informações da sua conta, gerencie sua assinatura, verifique os dados de atualização das cartas e acesse nossas
            informações e Termos de Uso.
          </div>
        </div>
      </div>
    ),
  },
  // Card 32
  {
    button: 'finish',
    content: (
      <div className="tour__list">
        <img src={FinishGif} alt="" className="tour__gif tour__gif__finish" />
        <div className="tour__text">Pronto!</div>
        <div className="tour__text">Agora você tem todo o conhecimento necessário para aproveitar 100% da nossa plataforma.</div>
        <div className="tour__text">O que achou?</div>
      </div>
    ),
  },
];

export default Cards;
