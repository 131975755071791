import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';

import ReactGA from 'react-ga';

import TextBox from 'react-uwp/TextBox';
import Tooltip from 'react-uwp/Tooltip';
import Icon from 'react-uwp/Icon';
import Loader from 'react-loader-spinner';
import { wrapComponent as withSnackbar } from 'react-snackbar-alert';

import ConfirmModal from 'components/ConfirmModal/ConfirmModal';
import { invertRouteWaypoints, segmentRouteWaypoints, clearRoute, updateName, upsertRoute } from 'redux/actions/routeActions';
import { getActiveRoute } from 'redux/selectors/route';
import classnames from 'classnames';
import { useTourContext } from 'context/TourContext/TourContext';
import VSInput from './components/VSInput/VSInput';
import RoutePlanning from './components/RoutePlanning/RoutePlanning';
import ExportDropdown from './components/ExportDropdown/ExportDropdown';

import IconSave from './assets/icon_save.svg';
import IconSaveDisabled from './assets/icon_save_disabled.svg';
import IconSaveSaved from './assets/icon_save_saved.svg';

import IconNew from './assets/icon_new.svg';
import IconNewDisabled from './assets/icon_new_disabled.svg';

import IconInvert from './assets/icon_invert.svg';
import IconInvertDisabled from './assets/icon_invert_disabled.svg';

import IconSplit from './assets/icon_split.svg';
import IconSplitDisabled from './assets/icon_split_disabled.svg';

import IconExport from './assets/icon_export.svg';
import IconExportDisabled from './assets/icon_export_disabled.svg';

import metrics from '../../../../utils/metrics';
import generateGPX from '../../utils/exports/generateGPX';
import generateFMS from '../../utils/exports/generateFMS';
import generatePLN from '../../utils/exports/generatePLN';
import download from '../../../../utils/download';

import './RoutePanel.style.scss';
import { distanceNMFloat } from '../../../../utils/map';

const RoutePanel = props => {
  const { activeRoute, routeModified, routeSaving, onRouteItemClick, onClick, onLocate } = props;

  const tourContext = useTourContext();

  const [renaming, setRenaming] = useState(false);
  const [routeName, setRouteName] = useState(activeRoute ? activeRoute.name : null);
  const [deleting, setDeleting] = useState(false);

  const [clearing, setClearing] = useState(false);
  const [inverting, setInverting] = useState(false);
  const [splitting, setSplitting] = useState(null);
  const [pointsToAddSplit, setPointsToAddSplit] = useState(0);

  const [isExporting, setIsExporting] = useState(false);

  useEffect(() => {
    ReactGA.modalview('/drawer/route-panel');

    return () => {
      ReactGA.pageview('/dashboard');
    };
  }, []);

  useEffect(() => {
    if (activeRoute) {
      setRouteName(activeRoute.name);
    } else {
      setRouteName(null);
      setIsExporting(false);
    }
  }, [activeRoute]);

  const disabled = activeRoute === null;

  const routeNewComponent = (
    <img
      className="route-panel__header__actions__icon"
      src={disabled || tourContext.isDisabled(metrics.events.DRAWER.PLANEJAR_ROTA.NOVA_ROTA) ? IconNewDisabled : IconNew}
      onClick={() => {
        if (tourContext.isDisabled(metrics.events.DRAWER.PLANEJAR_ROTA.NOVA_ROTA)) {
          return;
        }

        if (!disabled) {
          if (!routeModified) {
            setRenaming(false);
            props.clearRoute();
          } else {
            setClearing(true);
          }
        }

        metrics.collect(metrics.events.DRAWER.PLANEJAR_ROTA.NOVA_ROTA.THIS);
      }}
      alt=""
    />
  );

  let routeSaveComponent = null;
  if (routeSaving) {
    routeSaveComponent = (
      <Loader
        type="TailSpin"
        color="#FDFDFD"
        height="20"
        width="24"
        className="route-panel__header__actions__icon route-panel__header__actions__icon--loader"
      />
    );
  } else {
    routeSaveComponent = (
      <img
        className="route-panel__header__actions__icon"
        src={
          disabled || tourContext.isDisabled(metrics.events.DRAWER.PLANEJAR_ROTA.SALVAR_ROTA)
            ? IconSaveDisabled
            : routeModified
            ? IconSave
            : IconSaveSaved
        }
        alt=""
        onClick={() => {
          if (tourContext.isDisabled(metrics.events.DRAWER.PLANEJAR_ROTA.SALVAR_ROTA)) {
            return;
          }
          if (!disabled && routeModified) {
            props
              .upsertRoute(activeRoute)
              .then(result => {
                props.createSnackbar({
                  message: 'Rota salva com sucesso!',
                });
              })
              .catch(e => {
                props.createSnackbar({
                  message: 'Não foi possível salvar sua rota. Verifique sua conexão com a internet.',
                });
              });
          }
          metrics.collect(metrics.events.DRAWER.PLANEJAR_ROTA.SALVAR_ROTA);
          tourContext.dispatchTourClick(metrics.events.DRAWER.PLANEJAR_ROTA.SALVAR_ROTA);
        }}
      />
    );
  }

  const handleRouteInvert = () => {
    if (!disabled && activeRoute?.waypoints?.length > 1) {
      setRenaming(false);
      setInverting(true);
    }

    metrics.collect(metrics.events.DRAWER.PLANEJAR_ROTA.INVERTER_ROTA.THIS);
  };

  const handleRouteSplit = () => {
    const { activeRoute } = props;
    const waypoints = activeRoute.waypoints;

    if (disabled || !activeRoute.ground_speed || waypoints.length < 2) return;

    let needsSplit = false;

    let pointsToAdd = 0;

    waypoints.forEach((waypoint, index) => {
      if (index === waypoints.length - 1) return null;
      const distance = distanceNMFloat([waypoint.latitude, waypoint.longitude], [waypoints[index + 1].latitude, waypoints[index + 1].longitude]);
      const time = distance / activeRoute.ground_speed;

      const pointToAddByTime = Math.floor(time / 0.5);
      const pointsToAddByDistance = Math.floor(distance / 200);

      if (pointToAddByTime > 0 || pointsToAddByDistance > 0) {
        needsSplit = true;

        pointsToAdd += pointToAddByTime > pointsToAddByDistance ? pointToAddByTime : pointsToAddByDistance;
      }
    });

    setSplitting(needsSplit ? 'needs-split' : 'no-split-needed');
    setPointsToAddSplit(pointsToAdd);

    metrics.collect(metrics.events.DRAWER.PLANEJAR_ROTA.SEGMENTAR_ROTA.THIS);
  };

  const routeInvertComponent = (
    <img
      className="route-panel__header__actions__icon"
      src={disabled || activeRoute?.waypoints?.length < 2 ? IconInvertDisabled : IconInvert}
      onClick={handleRouteInvert}
      alt=""
    />
  );

  const routeSplitComponent = (
    <img
      className="route-panel__header__actions__icon"
      src={disabled || !activeRoute.ground_speed || activeRoute?.waypoints?.length < 2 ? IconSplitDisabled : IconSplit}
      onClick={handleRouteSplit}
      alt=""
    />
  );

  const routeExportComponent = (
    <img
      className="route-panel__header__actions__icon"
      src={disabled ? IconExportDisabled : IconExport}
      onClick={() => {
        if (!disabled) {
          setIsExporting(state => !state);
        }
      }}
      alt=""
    />
  );

  const handlePrint = () => {
    if (!disabled) {
      props.onGenerateNavLog();
    }

    metrics.collect(metrics.events.DRAWER.PLANEJAR_ROTA.IMPRIMIR_NAV_LOG);
  };

  const handleExportToGPSGarmin = () => {
    const GPX = generateGPX(activeRoute.name, activeRoute.waypoints);

    metrics.collect(metrics.events.DRAWER.PLANEJAR_ROTA.EXPORT.GARMIN);
    download(`${activeRoute.name}.gpx`, GPX);
  };

  const handleExportToXPlane = () => {
    const FMS = generateFMS(activeRoute.waypoints, 3);

    metrics.collect(metrics.events.DRAWER.PLANEJAR_ROTA.EXPORT.XPLANE);
    download(`${activeRoute.name}.fms`, FMS);
  };

  const handleExportToXPlane11 = () => {
    const FMS = generateFMS(activeRoute.waypoints, 11);

    metrics.collect(metrics.events.DRAWER.PLANEJAR_ROTA.EXPORT.XPLANE11);
    download(`${activeRoute.name}.fms`, FMS);
  };

  const handleExportToPLN = () => {
    const PLN = generatePLN(activeRoute.name, activeRoute.waypoints, false);

    metrics.collect(metrics.events.DRAWER.PLANEJAR_ROTA.EXPORT.PLN);
    download(`${activeRoute.name}.pln`, PLN);
  };

  const startEditingName = e => {
    setRenaming(true);
  };

  const finishEditingName = e => {
    setRenaming(false);

    props.updateRouteName(activeRoute.id, routeName || 'Rota sem título');
    metrics.collect(metrics.events.DRAWER.PLANEJAR_ROTA.RENOMEAR);
  };

  const handleKeyDown = e => {
    if (e.key !== 'Enter') {
      return;
    }
    finishEditingName(e);
  };

  return (
    <div
      className="route-panel"
      onClick={() => {
        onClick();
      }}
    >
      <ConfirmModal
        open={clearing}
        message="As modificações serão perdidas. Prosseguir?"
        width="280px"
        onYes={() => {
          props.clearRoute();
          setRenaming(false);
          metrics.collect(metrics.events.DRAWER.PLANEJAR_ROTA.NOVA_ROTA.DIALOG.SIM);
        }}
        onCancel={() => metrics.collect(metrics.events.DRAWER.PLANEJAR_ROTA.NOVA_ROTA.DIALOG.CANCELAR)}
        onFinish={() => setClearing(false)}
      />

      <ConfirmModal
        open={inverting}
        message={
          routeModified
            ? 'Há modificações na rota atual que não foram salvas e serão perdidas. Prosseguir?'
            : 'Uma nova rota será criada com a ordem inversa. Prosseguir?'
        }
        width="280px"
        onYes={() => {
          props.invertRouteWaypoints(activeRoute.waypoints, activeRoute.ground_speed);
          setRenaming(false);
          metrics.collect(metrics.events.DRAWER.PLANEJAR_ROTA.INVERTER_ROTA.DIALOG.SIM);
        }}
        onCancel={() => metrics.collect(metrics.events.DRAWER.PLANEJAR_ROTA.INVERTER_ROTA.DIALOG.CANCELAR)}
        onFinish={() => setInverting(false)}
      />

      <ConfirmModal
        open={splitting === 'needs-split'}
        message="Sua rota será segmentada com pontos separados por não mais de 30 minutos de voo ou 200nm."
        width="280px"
        yesLabel="Confirmar"
        onYes={() => {
          props.segmentRouteWaypoints(activeRoute.waypoints, activeRoute.ground_speed);
          props.createSnackbar({
            message: pointsToAddSplit > 1 ? `${pointsToAddSplit} pontos foram adicionados à rota` : '1 ponto foi adicionado à rota',
          });
          setSplitting(null);
        }}
        onCancel={() => {}}
        onFinish={() => setSplitting(false)}
      />

      <ConfirmModal
        open={splitting === 'no-split-needed'}
        message="Sua rota não possui pontos separados por mais de 30 minutos de voo ou 200nm."
        width="280px"
        yesLabel="Entendi"
        disableCancel
        onYes={() => {
          setSplitting(null);
        }}
        onFinish={() => setSplitting(false)}
      />

      <div className="route-panel__header">
        <div className="route-panel__header__head">
          <div className={classnames('route-panel__header__title', { disabled })}>
            {renaming ? (
              <>
                <TextBox
                  background="none"
                  value={routeName}
                  onChangeValue={value => setRouteName(value)}
                  onKeyDown={handleKeyDown}
                  className="route-panel__header__textbox"
                />
                <Icon className="route-panel__header__confirm" onClick={finishEditingName}>
                  CheckMark
                </Icon>
              </>
            ) : routeName ? (
              <>
                <div className="route-panel__header__title__route-name">{routeName}</div>
                <Tooltip content="Renomear Rota" verticalPosition="top" horizontalPosition="center">
                  <Icon className="route-panel__header__actions__item" onClick={startEditingName}>
                    Edit
                  </Icon>
                </Tooltip>
              </>
            ) : (
              'NOVA ROTA'
            )}
          </div>
          <div className="route-panel__header__subline">
            <div className="route-panel__header__actions">
              <div className="route-panel__header__actions__group">
                <div className="route-panel__header__actions__item">
                  {!disabled && (
                    <Tooltip content="Nova Rota" verticalPosition="top" horizontalPosition="center">
                      {routeNewComponent}
                    </Tooltip>
                  )}
                  {disabled && routeNewComponent}
                </div>
              </div>
              <div className="route-panel__header__actions__group">
                <div className="route-panel__header__actions__item" id="salvar_rota">
                  {!disabled && routeModified && (
                    <Tooltip content="Salvar Rota" verticalPosition="top" horizontalPosition="center">
                      {routeSaveComponent}
                    </Tooltip>
                  )}
                  {(disabled || !routeModified) && routeSaveComponent}
                </div>
              </div>
              <div className="route-panel__header__actions__group">
                <div className="route-panel__header__actions__item">
                  {!disabled && (
                    <Tooltip content="Inverter Rota" verticalPosition="top" horizontalPosition="center">
                      {routeInvertComponent}
                    </Tooltip>
                  )}
                  {disabled && routeInvertComponent}
                </div>
              </div>
              <div className="route-panel__header__actions__group">
                <div className="route-panel__header__actions__item">
                  {!disabled && (
                    <Tooltip content="Segmentar Rota" verticalPosition="top" horizontalPosition="center">
                      {routeSplitComponent}
                    </Tooltip>
                  )}
                  {disabled && routeSplitComponent}
                </div>
              </div>
              <div className="route-panel__header__actions__group">
                <div className={`route-panel__header__actions__item ${isExporting ? 'active' : ''}`}>
                  {!disabled && (
                    <Tooltip content="Imprimir ou Exportar Rota" verticalPosition="top" horizontalPosition="center">
                      {routeExportComponent}
                    </Tooltip>
                  )}
                  {disabled && routeExportComponent}
                </div>
                {isExporting && (
                  <ExportDropdown className="route-panel__header__actions__dropdown--first">
                    <ExportDropdown text="Imprimir" onClick={handlePrint} />
                    <ExportDropdown text="Salvar PDF" onClick={handlePrint} />
                    <ExportDropdown text="Exportar para GPS">
                      <ExportDropdown text="Garmin (.gpx)" onClick={handleExportToGPSGarmin} />
                    </ExportDropdown>
                    <ExportDropdown text="Exportar para Simulador">
                      <ExportDropdown text="MS FS2020 (.pln)" onClick={handleExportToPLN} />
                      {/* <ExportDropdown text="MS FS2020 Mid-Air (.pln)" onClick={handleExportToPLNMidair} /> */}
                      <ExportDropdown text="XPlane 11 (.fms)" onClick={handleExportToXPlane11} />
                      <ExportDropdown text="XPlane 10- (.fms)" onClick={handleExportToXPlane} />
                    </ExportDropdown>
                  </ExportDropdown>
                )}
              </div>
            </div>
            <div className="route-panel__header__vs">
              <VSInput disabled={disabled} vs={activeRoute && activeRoute.ground_speed} />
            </div>
          </div>
          {deleting && (
            <div className="route-panel__header__delete">
              <div
                className="route-panel__header__delete__button yes"
                onClick={() => {
                  props.deleteRoute(activeRoute.id);
                  setDeleting(false);
                  props.clearRoute();
                }}
              >
                Excluir
              </div>
              <div
                className="route-panel__header__delete__button no"
                onClick={() => {
                  setDeleting(false);
                }}
              >
                Cancelar
              </div>
            </div>
          )}
        </div>
      </div>
      <RoutePlanning onItemClick={onRouteItemClick} onLocate={onLocate} />
    </div>
  );
};

const mapStateToProps = (state, props) => ({
  activeRoute: getActiveRoute(state, props),
  routeModified: state.appState.routeModified,
  routeSaving: state.appState.loading.has('update_route'),
});

const mapDispatchToProps = {
  invertRouteWaypoints,
  segmentRouteWaypoints,
  clearRoute,
  upsertRoute,
  updateRouteName: updateName,
};

export default connect(mapStateToProps, mapDispatchToProps)(withSnackbar(RoutePanel));
