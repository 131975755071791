import Cookies from 'js-cookie';

import { AUTH } from '../actions/types';

const initialState = {
  initializing: true,

  logged_in: false,
  loading: false,
  token: null,
  remember: false,
  user: null,
  user_profile: null,

  token_dead: false,
  error_code: null,
  profile_complete: null,

  password_recovery_sent: null,
  password_recovery_error: null,
};

export default function(state = initialState, action) {
  const { payload } = action;

  let newState = { ...state };

  switch (action.type) {
    case AUTH.LOGIN:
    case AUTH.FB_LOGIN:
    case AUTH.GOOGLE_LOGIN:
      return { ...state, loading: true, error_code: null };

    case `${AUTH.LOGIN}_SUCCESS`:
    case `${AUTH.FB_LOGIN}_SUCCESS`:
    case `${AUTH.GOOGLE_LOGIN}_SUCCESS`: {
      const { data } = payload;

      if (data.error) {
        if (data.message === 'invalidUserOrPassword') {
          return {
            ...state,
            loading: false,
            error_code: 'INVALID_USER_PASSWORD',
          };
        }
        return { ...state, loading: false, error_code: 'UNKNOWN_ERROR' };
      }

      if (!data.user) {
        return { ...state, loading: false, error_code: 'UNKNOWN_ERROR' };
      }

      newState = {
        ...state,
        token: data.token,
        token_dead: false,
        remember: data.remember,
        user: data.user,
        profile_complete: data.profile_complete,
        loading: false,
        error_code: null,
      };

      if (data.user.profile) {
        newState.user_profile = { ...data.user.profile };
        delete data.user.profile;
      }

      if (data.profile_complete) {
        newState.logged_in = true;
      }

      // Sets token cookie
      const cookieName = '_nat';
      const cookieValue = data.token;
      Cookies.set(cookieName, cookieValue, { expires: 365, domain: '.nexatlas.com' });

      // Sets profile cookie
      const profileCookieName = '_nap';
      const profileCookieValue = JSON.stringify({
        id: data.user.id,
        email: data.user.email,
        name: newState.user_profile?.name,
        surname: newState.user_profile?.surname,
        segment: newState.user_profile?.aviation_segment || newState.user_profile?.self_classification,
      });
      Cookies.set(profileCookieName, profileCookieValue, { expires: 365, domain: '.nexatlas.com' });

      return newState;
    }

    case AUTH.RECOVERY_PASSWORD:
      return { ...state, loading: true, password_recovery_sent: false, error_code: null };

    case `${AUTH.RECOVERY_PASSWORD}_SUCCESS`:
      if (payload.data.error) {
        if (payload.data.message === 'invalidUser') {
          return {
            ...state,
            loading: false,
            password_recovery_error: 'INVALID_USER',
            error_code: null,
          };
        }

        return {
          ...state,
          loading: false,
          password_recovery_error: 'UNKNOWN_ERROR',
          error_code: null,
        };
      }

      return {
        ...state,
        loading: false,
        password_recovery_sent: true,
        password_recovery_error: null,
        error_code: null,
      };

    case `${AUTH.CREATE}_SUCCESS`:
      return {
        ...state,
        loading: false,
        logged_in: false,
        profile_complete: false,
        user: payload.data.user,
        error_code: null,
        token: null,
        token_dead: false,
      };

    case AUTH.PROFILE_CREATE:
      return { ...state, loading: true, error_code: null };

    case `${AUTH.PROFILE_CREATE}_SUCCESS`:
      newState = {
        ...state,
        loading: false,
        logged_in: true,
        profile_complete: true,
        user: payload.data.user,
        error_code: null,
        remember: true,
        token: payload.data.token,
        token_dead: false,
      };

      if (payload.data.user && payload.data.user.profile) {
        newState.user_profile = payload.data.user.profile;
      }

      return newState;

    case `${AUTH.UPDATE_USER}_SUCCESS`:
      if (payload.data && payload.data.user) {
        return {
          ...state,
          user: payload.data.user,
        };
      }

      return { ...state };

    case `${AUTH.LOGOUT}_SUCCESS`: {
      // Removes token cookie
      const cookieName = '_nat';
      Cookies.remove(cookieName, { domain: '.nexatlas.com' });

      // Removes profile cookie
      const profileCookieName = '_nap';
      Cookies.remove(profileCookieName, { domain: '.nexatlas.com' });

      return {
        ...state,
        logged_in: false,
        loading: false,
        token: null,
        token_dead: false,
        user: null,
        user_profile: null,
        profile_complete: null,
      };
    }

    case AUTH.TOKEN_DEAD: {
      return {
        ...state,
        token_dead: true,
      };
    }

    case `${AUTH.SET_USER_FLAG}_SUCCESS`: {
      return {
        ...state,
        user: {
          ...state.user,
          ...payload.data.user,
        },
      };
    }

    case AUTH.UPDATE_LOCAL_USER: {
      return {
        ...state,
        user: {
          ...state.user,
          ...payload.user,
        },
      };
    }

    // Network error and unknown errors
    case `${AUTH.PROFILE_CREATE}_FAIL`:
    case `${AUTH.FB_LOGIN}_FAIL`:
    case `${AUTH.GOOGLE_LOGIN}_FAIL`:
    case `${AUTH.CREATE}_FAIL`:
    case `${AUTH.RECOVERY_PASSWORD}_FAIL`:
    case `${AUTH.LOGIN}_FAIL`:
    case `${AUTH.LOGOUT}_FAIL`:
      if (action.error.data === 'Network Error') {
        return { ...state, loading: false, error_code: 'NETWORK_ERROR' };
      }
      return { ...state, loading: false, error_code: 'UNKNOWN_ERROR' };

    default:
      return { ...state };
  }
}
