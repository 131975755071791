import React from 'react';
import PropTypes from 'prop-types';

import './ExportDropdown.style.scss';

const ExportDropdown = ({ className, text, children, onClick }) => (
  <div className={`route-panel__header__actions__dropdown ${className}`}>
    {text && (
      <button type="button" className="route-panel__header__actions__dropdown__button" onClick={onClick}>
        {text}
      </button>
    )}
    {children && <div className="route-panel__header__actions__dropdown__content">{children}</div>}
  </div>
);

ExportDropdown.propTypes = {
  className: PropTypes.string,
  text: PropTypes.string,
  children: PropTypes.element,
  onClick: PropTypes.func,
};

ExportDropdown.defaultProps = {
  className: '',
  text: '',
  children: null,
  onClick: () => {},
};

export default ExportDropdown;
