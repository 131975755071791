import { Map } from 'immutable';
import { STATE, ROUTE } from '../actions/types';

const initialState = {
  loading: Map(),

  errorDialog: '',
  errorDialogOpen: false,

  freezeUserAccount: null,

  routeActive: null,
  routeModified: false,
  newsPage: -1,
};

export default function (state = initialState, action) {
  switch (action.type) {
    case STATE.SHOW_ERROR:
      return {
        ...state,
        errorDialog: action.payload.error,
        errorDialogOpen: true,
      };

    case STATE.CLOSE_ERROR:
      return {
        ...state,
        errorDialog: '',
        errorDialogOpen: false,
      };

    case STATE.LOADING:
      return {
        ...state,
        loading: state.loading.set(action.payload.key, action.payload.display),
      };

    case STATE.LOADED:
      return {
        ...state,
        loading: state.loading.delete(action.payload.key),
      };

    case STATE.INCREASE_NEWS_PAGE:
      return { ...state, favoritesPage: state.newsPage + 1 };

    case STATE.SET_ROUTE_ACTIVE:
      return {
        ...state,
        routeActive: action.payload.route_active,
        routeModified: false,
      };

    case STATE.FREEZE_USER_ACCOUNT:
      return {
        ...state,
        freezeUserAccount: action.payload.freeze,
      };

    case ROUTE.CREATE:
    case ROUTE.INVERT:
      return { ...state, routeActive: action.payload.route_id, routeModified: true };

    case ROUTE.UPDATE:
    case ROUTE.SPLIT:
    case ROUTE.ADD_WAYPOINT:
    case ROUTE.SWITCH_WAYPOINT:
    case ROUTE.UPDATE_WAYPOINT:
    case ROUTE.SORT_WAYPOINT:
    case ROUTE.REMOVE_WAYPOINT:
      return { ...state, routeModified: true };

    case `${ROUTE.UPDATE_ALL}_SUCCESS`:
      return { ...state, routeModified: false };

    case ROUTE.CLEAR:
      return { ...state, routeActive: null, routeModified: false };

    default:
      return { ...state };
  }
}
