import React, { useEffect } from 'react';
import { connect } from 'react-redux';

import ReactGA from 'react-ga';

import { useTourContext } from 'context/TourContext/TourContext';

import { getCloserWaypoints } from 'redux/selectors/waypoint';
import Icon from 'react-uwp/Icon';

import classnames from 'classnames';
import metrics from '../../../../utils/metrics';

import IconFindMap from './assets/icon_findmap.svg';

import './MapSearch.scss';

export const MapSearchItem = props => {
  const { item } = props;

  let title = '';
  let subtitle = '';

  if (item.waypoint_type_id === 1) {
    title = item.code;
    subtitle = item.name;
  } else if (item.waypoint_type_id === 2) {
    title = item.name;
    subtitle = 'Centro da cidade';
  } else if (item.waypoint_type_id === 3) {
    title = item.name;
    subtitle = 'Coordenadas';
  } else if (item.waypoint_type_id === 4) {
    title = item.name;
    subtitle = 'Fixo';
  } else if (item.waypoint_type_id === 5) {
    title = item.code;
    subtitle = `HELPN ${item.name}`;
  } else if (item.waypoint_type_id === 6) {
    title = `${item.code} NDB ${item.meta && item.meta.frequency && item.meta.frequency.toFixed(0)}`;
    subtitle = item.name;
  } else if (item.waypoint_type_id === 7) {
    title = `${item.code} VOR ${item.meta && item.meta.frequency && item.meta.frequency.toFixed(1)}`;
    subtitle = item.name;
  } else if (item.waypoint_type_id === 8) {
    title = `${item.code} VOR/DME ${item.meta && item.meta.frequency && item.meta.frequency.toFixed(1)}`;
    subtitle = item.name;
  } else if (item.waypoint_type_id === 9) {
    title = `${item.code}`;
    subtitle = 'Posição Visual';
  }

  const airport = item.waypoint_type_id === 1 || item.waypoint_type_id === 5;

  return (
    <div className="map__search__item">
      <div className="map__search__item__text" onClick={() => props.onAdd(item)}>
        <div className="map__search__item__title">{title}</div>
        <div className="map__search__item__subtitle">{subtitle}</div>
      </div>
      <div className="map__search__item__actions">
        <ReactGA.OutboundLink
          to={airport ? `https://aisweb.decea.gov.br/?i=aerodromos&codigo=${item.code}` : null}
          eventLabel="aisweb-waypoint-search"
          alt="I"
          target="_blank"
          rel="noopener noreferrer"
          onClick={props.onInfo}
        >
          <Icon className={classnames('map__search__item__info', { disabled: !airport || props.isDrag })}>Info</Icon>
        </ReactGA.OutboundLink>
        <img
          alt="L"
          src={IconFindMap}
          className={classnames('map__search__item__add', { disabled: props.isDrag })}
          onClick={() => {
            props.onLocate(item.latitude, item.longitude);
          }}
        />
      </div>
    </div>
  );
};

const MapSearch = props => {
  const { searchResults, isDrag } = props;
  const { mapSearchActive, mapSearchXY } = props.search;

  const tourContext = useTourContext();

  useEffect(() => {
    if (mapSearchActive) {
      tourContext.dispatchTourClick(metrics.events.MAPA.THIS);
    }
  }, [mapSearchActive, tourContext]);

  let maxHeight = 400;
  if (window.innerHeight > 1366) {
    maxHeight = 400;
  } else if (window.innerHeight > 500) {
    maxHeight = 300;
  } else {
    maxHeight = 250;
  }

  const mapSearchHandledX = (mapSearchXY && mapSearchXY.x) || 0;
  const mapSearchHandledY = (mapSearchXY && mapSearchXY.y) || 0;

  const searchStyle = {
    maxHeight: `${maxHeight}px`,
    top: mapSearchHandledY,
    left: mapSearchHandledX,
  };

  if (window.innerHeight - maxHeight < mapSearchHandledY) {
    searchStyle.transform = 'translateY(-100%)';
  }

  if (window.innerWidth - 300 < mapSearchHandledX) {
    if (searchStyle.transform) {
      searchStyle.transform += ' translateX(-100%)';
    } else {
      searchStyle.transform = 'translateX(-100%)';
    }
  }

  const handleLocate = (lat, lng) => {
    if (tourContext.dispatchValidation(metrics.events.MAPA.LISTA.WPT.ADICIONAR)) {
      return;
    }
    props.onLocate(lat, lng);
    metrics.collect(metrics.events.MAPA.LISTA.WPT.CENTRALIZAR);
  };

  const handleAdd = item => {
    props.onAdd(item);
    metrics.collect(metrics.events.MAPA.LISTA.WPT.ADICIONAR, item.code || item.name);
    tourContext.dispatchTourClick(metrics.events.MAPA.LISTA.WPT.ADICIONAR);
  };

  return mapSearchActive ? (
    <div className="map__search" id="mapa_wpt" style={searchStyle}>
      {searchResults &&
        searchResults.map(result => (
          <MapSearchItem
            item={result}
            key={result.id || 0}
            onLocate={handleLocate}
            onAdd={handleAdd}
            onInfo={() => metrics.collect(metrics.events.MAPA.LISTA.WPT.AISWEB)}
            isDrag={isDrag}
          />
        ))}
    </div>
  ) : null;
};

const mapStateToProps = (state, props) => ({
  searchResults: getCloserWaypoints(state, props),
});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(MapSearch);
