import React from 'react';
import { connect } from 'react-redux';
import L from 'leaflet';

import { Marker, Tooltip, Polyline } from 'react-windy-leaflet';

import { getActiveRoute } from 'redux/selectors/route';

import './MapRouteWindy.style.scss';

const defaultIcon = L.icon({
  iconUrl: require('../../assets/map-marker.png'),
  iconSize: [12, 12],
  iconAnchor: [6, 6],
  popupAnchor: [-1, -8],
});

const upIcon = L.icon({
  iconUrl: require('../../assets/map-marker.png'),
  iconSize: [12, 12],
  iconAnchor: [6, 6],
  popupAnchor: [-1, -8],
});

const downIcon = L.icon({
  iconUrl: require('../../assets/map-marker.png'),
  iconSize: [12, 12],
  iconAnchor: [6, 6],
  popupAnchor: [-1, -8],
});

const loadingIcon = L.icon({
  iconUrl: require('../../assets/map-marker-loading.png'),
  iconSize: [12, 12],
  iconAnchor: [6, 6],
  popupAnchor: [-1, -8],
});

const MapRouteWindy = props => {
  let waypoints = [];
  if (props.activeRoute && props.activeRoute.waypoints) {
    waypoints = props.activeRoute.waypoints;
  }

  const polylineWeight = zoom => {
    if (zoom < 7) return 4;
    if (zoom >= 7 && zoom < 10) return 7;
    if (zoom >= 10 && zoom < 13) return 20;
    if (zoom >= 13) return 40;
  };

  return (
    <div>
      {waypoints.map((waypoint, i) => (
        <Marker
          position={
            props.mapSearchWaypointActive && props.mapSearchWaypointActive.i === i
              ? [props.mapSearchWaypointActive.pos.lat, props.mapSearchWaypointActive.pos.lng]
              : [waypoint.latitude, waypoint.longitude]
          }
          draggable={!waypoint.loading}
          onDrag={event => {
            props.onWaypointDrag(event, i);
          }}
          onDragend={event => {
            const containerPoint = event.target._map.latLngToContainerPoint(event.target._latlng);
            props.onWaypointDragEnd(waypoint, event.target, {
              x: containerPoint.x + 50,
              y: containerPoint.y,
            });
          }}
          key={waypoint.id || waypoint.name}
          icon={waypoint.loading ? loadingIcon : i === 0 ? upIcon : i === waypoints.length - 1 ? downIcon : defaultIcon}
        >
          <Tooltip permanent direction="top" opacity={1} className="map__route__marker__tooltip" offset={[-1, -8]}>
            {waypoint.code ? waypoint.code : waypoint.name}
          </Tooltip>
        </Marker>
      ))}

      <Polyline
        positions={waypoints.map((waypoint, i) => {
          if (props.mapSearchWaypointActive && props.mapSearchWaypointActive.i === i) {
            return [props.mapSearchWaypointActive.pos.lat, props.mapSearchWaypointActive.pos.lng];
          }
          return [waypoint.latitude, waypoint.longitude];
        })}
        color="#007D4A"
        smoothFactor={3}
        weight={polylineWeight(props.zoom)}
      />
    </div>
  );
};

const mapStateToProps = (state, props) => ({
  activeRoute: getActiveRoute(state, props),
});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(MapRouteWindy);
