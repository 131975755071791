import { Model, attr } from 'redux-orm';

import { DATA_UPDATE } from '../actions/types.js';

export default class DataUpdate extends Model {
  static reducer(action, DataUpdate, session) {
    const { payload, type } = action;

    switch (type) {
      case `${DATA_UPDATE.LOAD_LAST}_SUCCESS`:
        if (payload.data && payload.data.dataUpdate) {
          DataUpdate.upsert(payload.data.dataUpdate);
        }
        break;

      default:
        break;
    }
  }
}

DataUpdate.modelName = 'DataUpdate';

DataUpdate.fields = {
  id: attr(),
  code: attr(),
  start_date: attr(),
  valid_to: attr(),
};
