import React from 'react';

import Button from 'react-uwp/Button';

import metrics from '../../../../../../utils/metrics';

import CustomScrollbar from 'components/CustomScrollbar/CustomScrollbar';

import './ConfigsPaymentEnd.styles.scss';

export default props => {
  const { loading } = props;

  const method = 'boleto';

  return (
    <div className="configs__base">
      <div className="configs__title">{method === 'boleto' ? 'AGUARDANDO PAGAMENTO' : 'ASSINATURA ATIVADA'}</div>
      <CustomScrollbar className="configs__scroll">
        <div className="configs__container">
          <div className="configs__container__session">
            {method === 'boleto' ? (
              <p className="configs__container__paragraph">Falta pouco para você fazer parte do grupo de assinantes NexAtlas!</p>
            ) : (
              <p className="configs__container__paragraph">Seja-bem vindo(a) ao grupo de assinantes NexAtlas!</p>
            )}
            {method === 'boleto' && (
              <>
                <p className="configs__container__paragraph">
                  Um boleto bancário acaba de ser enviado para o seu e-mail. Realize o pagamento antes do vencimento para ativar a sua assinatura e
                  fazer parte da nossa comunidade!
                </p>
                <p className="configs__container__paragraph">Atenção:</p>
                <p className="configs__container__paragraph">
                  Seu acesso a ferramenta será liberado após o pagamento e confirmação pela instituição financeira. Essa compensação pode levar até 3
                  dias úteis.
                </p>
              </>
            )}
            <p className="configs__container__paragraph">
              Caso tenha alguma dúvida ou ainda não tenha recebido o boleto, entre em contato pelo WhatsApp (35) 99924-2497 ou no chat do atendimento.
            </p>
            <div className="configs__trial-end__button">
              <Button
                tooltip="Assine agora a NexAtlas"
                className="user-options__logout__button"
                onClick={() => {
                  metrics.collect(metrics.events.DRAWER.CONFIGURACOES.ASSINE_JA.INICIAR);
                  props.onFinish();
                }}
                disabled={loading}
              >
                Iniciar
              </Button>
            </div>
          </div>
        </div>
      </CustomScrollbar>
    </div>
  );
};
