import { NEWS } from '../actions/types';

const initialState = {
  lastNewsId: -1,
};

export default function(state = initialState, action) {
  switch (action.type) {
    case NEWS.UPDATE_LAST_NEWS_ID:
      return {
        ...state,
        lastNewsId: action.payload.id,
      };

    default:
      return { ...state };
  }
}
