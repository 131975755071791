import React from 'react';
import arrowRight from 'assets/icons/barras/arrow-right.svg';

import './BetaInfo.style.scss';

export const BetaInfo = () => {
  return (
    <div className="beta-info">
      <div className="beta-info__container">
        <h1 className="beta-info__container__title">Versão de testes</h1>
        <div className="beta-info__container__body">
          <h2 className="beta-info__container__body__subtitle">
            <span role="img" aria-label="magnifying glass icon">
              🔍
            </span>{' '}
            O que é a versão de testes?
          </h2>
          <div className="beta-info__container__body__content">
            Esta atualização está em fase de testes, onde você poderá experimentar as novas funcionalidades antes do lançamento oficial.
          </div>
          <div className="beta-info__container__body__content">
            Durante esse período, você pode encontrar alguns erros ou áreas que ainda estamos ajustando, e é aqui que sua ajuda entra!
          </div>
          <h2 className="beta-info__container__body__subtitle">
            <span role="img" aria-label="news icon">
              🆕
            </span>{' '}
            Principais novidades
          </h2>
          <div className="beta-info__container__body__content">
            A nova atualização trará algumas funcionalidades muito pedidas, como:
            <ul className="beta-info__container__body__content__bullet-list">
              <li>Cartas de procedimento;</li>
              <li>Espaços aéreos;</li>
              <li>Nova meteorologia;</li>
              <li>Aeródromos no mapa;</li>
            </ul>
            e muito mais!
          </div>
          <a className="beta-info__container__body__button" href="https://beta.app.nexatlas.com/beta-info">
            Acessar versão de testes
            <img src={arrowRight} alt="" />
          </a>
        </div>
      </div>
    </div>
  );
};
