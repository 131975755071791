import React, { useState, useEffect, useMemo, useRef } from 'react';
import { connect } from 'react-redux';
import Button from 'react-uwp/Button';
import Icon from 'react-uwp/Icon';
import TextBox from 'react-uwp/TextBox';
import uuidv4 from 'uuid/v4';
import { wrapComponent as withSnackbar } from 'react-snackbar-alert';

import { DMSToDecimal, decimalToDMS } from '../../../../../../utils/map';
import { getAllUserWaypoints as getAllUserWaypointsAction } from 'redux/actions/waypointActions';
import { userWaypointsSelector } from 'redux/selectors/waypoint';
import { apiWebClient } from 'services/webclient';

import ConfirmModal from 'components/ConfirmModal/ConfirmModal';

import './ConfigsUserWaypointManager.style.scss';

const ConfigUserWaypointManager = props => {
  const { onLocate, userWaypointId, userWaypoints, onReturn } = props;

  const [activeUserWaypoint, setActiveUserWaypoint] = useState(null);

  const [nameInputValue, setNameInputValue] = useState('');
  const [coordinatesInputValue, setCoordinatesInputValue] = useState('');

  const [isNameAlreadyInUse, setIsNameAlreadyInUse] = useState(false);
  const [isCoordinateInvalid, setIsCoordinateInvalid] = useState(false);

  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [isBackModalOpen, setIsBackModalOpen] = useState(false);

  const [hasUserWaypointChanged, setHasUserWaypointChanged] = useState(false);

  const hasLoadActiveOnStart = useRef(false);

  useEffect(() => {
    if (userWaypointId && !hasLoadActiveOnStart.current) {
      const loadUserWaypoint = userWaypoints.find(waypoint => waypoint.id === userWaypointId);
      setActiveUserWaypoint(loadUserWaypoint ?? null);
      hasLoadActiveOnStart.current = true;
    }
  }, [userWaypointId, userWaypoints]);

  const coordinatesDMS = useMemo(() => {
    if (!activeUserWaypoint) return '';
    return `${decimalToDMS(activeUserWaypoint.latitude, false)} ${decimalToDMS(activeUserWaypoint.longitude, true)}`;
  }, [activeUserWaypoint]);

  useEffect(() => {
    if (!activeUserWaypoint) return;
    setNameInputValue(activeUserWaypoint.name);
    setCoordinatesInputValue(coordinatesDMS);
  }, [activeUserWaypoint, coordinatesDMS]);

  const validateName = () => {
    const existsName = userWaypoints.some(waypoint => waypoint.name === nameInputValue && waypoint.name !== activeUserWaypoint?.name);
    setIsNameAlreadyInUse(existsName);
    return !existsName;
  };

  const validateAndTransformCoordinate = value => {
    const checkCoordinates = value ?? coordinatesInputValue;

    const checkRE = /^[0-9]{2}\s*[º°]?\s*[0-5][0-9]\s*["']?\s*[0-5][0-9]\s*["']?\s*[NnSs]\s*\/?,?-?\s*[0-1][0-9]{2}\s*[º°]?\s*[0-5][0-9]\s*["']?\s*[0-5][0-9]\s*["']?\s*[LlOoEeWw]\s*$/gi;
    const matchCheck = checkRE.exec(checkCoordinates);
    if (!matchCheck) {
      setIsCoordinateInvalid(true);
      return false;
    }

    const latlngRE = /^([0-9]{1,2})\s*[º°]?\s*([0-5]{0,1}[0-9]{0,1})?\s*["']?\s*([0-5]{0,1}[0-9]{0,1})?\s*["']?\s*([NnSs])?\s*\/?,?-?\s*([0-9]{1,3})\s*[º°]?\s*([0-5]{0,1}[0-9]{0,1})?\s*["']?\s*([0-5]{0,1}[0-9]{0,1})?\s*["']?\s*([LlOoEeWw])?\s*$/gi;
    const latlngMatch = latlngRE.exec(checkCoordinates);
    if (!latlngMatch) {
      setIsCoordinateInvalid(true);
      return false;
    }
    const coordinateConverted = DMSToDecimal(latlngMatch);

    if (coordinateConverted.lat < -90 || coordinateConverted.lat > 90 || coordinateConverted.lng < -180 || coordinateConverted.lng > 180) {
      setIsCoordinateInvalid(true);
      return false;
    }

    setIsCoordinateInvalid(false);
    return coordinateConverted;
  };

  const handleCreateUserWaypoint = async () => {
    const newCoordinate = validateAndTransformCoordinate();
    if (!newCoordinate || !validateName()) return;

    const newWaypoint = {
      id: uuidv4(),
      name: nameInputValue,
      latitude: newCoordinate.lat,
      longitude: newCoordinate.lng,
    };

    const result = await apiWebClient.post('/userWaypoint/upsertWaypoint', newWaypoint);

    if (!result?.data?.error) {
      setActiveUserWaypoint(result.data.waypoint);
      setHasUserWaypointChanged(false);

      props.createSnackbar({
        message: 'Ponto criado com sucesso!',
      });
    } else {
      props.createSnackbar({
        message: 'Erro ao criar ponto!',
      });
    }
  };

  const handleUpdateUserWaypoint = async () => {
    if (nameInputValue === activeUserWaypoint.name && coordinatesInputValue === coordinatesDMS) {
      setHasUserWaypointChanged(false);
      return;
    }

    const newCoordinate = validateAndTransformCoordinate();
    if (!newCoordinate || !validateName()) return;

    const deleteResult = await apiWebClient.post('/userWaypoint/removeWaypoint', {
      id: activeUserWaypoint?.id,
    });

    if (!deleteResult.data?.error) {
      const newWaypoint = {
        id: uuidv4(),
        name: nameInputValue,
        latitude: newCoordinate.lat,
        longitude: newCoordinate.lng,
      };

      const createResult = await apiWebClient.post('/userWaypoint/upsertWaypoint', newWaypoint);
      if (!createResult.data?.error) {
        setActiveUserWaypoint(createResult.data.waypoint);
        setHasUserWaypointChanged(false);
        props.getAllUserWaypointsAction();

        props.createSnackbar({
          message: 'Ponto atualizado com sucesso!',
        });
      } else {
        props.createSnackbar({
          message: 'Erro ao atualizar ponto!',
        });
      }
    } else {
      props.createSnackbar({
        message: 'Erro ao atualizar ponto!',
      });
    }
  };

  const handleDeleteUserWaypoint = async () => {
    const deleteResult = await apiWebClient.post('/userWaypoint/removeWaypoint', {
      id: activeUserWaypoint?.id,
    });
    if (deleteResult.data.status === 200) {
      props.createSnackbar({
        message: 'Ponto removido com sucesso!',
      });
      onReturn();
    } else {
      props.createSnackbar({
        message: 'Erro ao remover o ponto!',
      });
    }
  };

  const handleShowOnMap = () => {
    onLocate(activeUserWaypoint.latitude, activeUserWaypoint.longitude, 10);
  };

  const handleReturn = () => {
    if (activeUserWaypoint) {
      if (hasUserWaypointChanged) {
        setIsBackModalOpen(true);
      } else {
        onReturn();
      }
    } else if (nameInputValue || coordinatesInputValue) {
      setIsBackModalOpen(true);
    } else {
      onReturn();
    }
  };

  return (
    <div className="configs__user-waypoint-manager__base">
      <ConfirmModal
        open={isBackModalOpen}
        message="Você tem alterações não salvas. Deseja descartá-las?"
        onYes={() => onReturn()}
        onCancel={() => {
          setIsBackModalOpen(false);
          setHasUserWaypointChanged(false);
          setNameInputValue(activeUserWaypoint.name);
          setCoordinatesInputValue(coordinatesDMS);
        }}
        onFinish={() => setIsBackModalOpen(false)}
      />
      <ConfirmModal
        message="Esse ponto já existe. Escolha outro nome"
        open={isNameAlreadyInUse}
        yesLabel="Ok"
        disableCancel
        onYes={() => setIsNameAlreadyInUse(false)}
        onFinish={() => setIsNameAlreadyInUse(false)}
      />
      <ConfirmModal
        open={isDeleteModalOpen}
        message="Tem certeza que deseja excluir este ponto do usuário?"
        yesLabel="Excluir"
        cancelLabel="Cancelar"
        onYes={handleDeleteUserWaypoint}
        onCancel={() => setIsDeleteModalOpen(false)}
        onFinish={() => setIsDeleteModalOpen(false)}
      />
      <div className="configs__title">
        <Icon onClick={handleReturn} className="configs__title__action">
          ChevronLeft
        </Icon>
        {activeUserWaypoint ? activeUserWaypoint.name.toUpperCase() : 'NOVO PONTO DO USUÁRIO'}
      </div>
      <div className="configs__user-waypoint-manager__base__content">
        <div className="configs__user-waypoint-manager__base__content__inputs">
          {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
          <label className="configs__user-waypoint-manager__base__content__inputs__label">
            Nome do ponto
            <TextBox
              className="configs__user-waypoint-manager__base__content__inputs__input"
              placeholder="Insira o nome do ponto"
              required
              value={nameInputValue}
              onChangeValue={value => {
                setNameInputValue(value);
                setHasUserWaypointChanged(true);
                setIsNameAlreadyInUse(false);
              }}
              style={{ marginTop: '4px' }}
            />
          </label>

          {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
          <label className="configs__user-waypoint-manager__base__content__inputs__label">
            Coordenadas geográficas
            <TextBox
              className="configs__user-waypoint-manager__base__content__inputs__input"
              placeholder="Insira as coordenadas"
              required
              value={coordinatesInputValue}
              onChangeValue={value => {
                setCoordinatesInputValue(value);
                setHasUserWaypointChanged(true);
                setIsCoordinateInvalid(false);
                validateAndTransformCoordinate(value);
              }}
              style={{ marginTop: '4px' }}
            />
          </label>

          <div className="configs__user-waypoint-manager__base__content__example">
            <span>
              Exemplos:
              <br />
              203040S 0543040W
              <br />
              20°30'40"S / 054°30'40"W
            </span>
          </div>
        </div>

        <div className="configs__user-waypoint-manager__base__content__buttons">
          {!activeUserWaypoint ? (
            <Button
              className="configs__user-waypoint-manager__base__content__buttons__button"
              disabled={nameInputValue === '' || coordinatesInputValue === '' || isNameAlreadyInUse || isCoordinateInvalid}
              onClick={handleCreateUserWaypoint}
            >
              Confirmar
            </Button>
          ) : hasUserWaypointChanged ? (
            <>
              <Button
                className="configs__user-waypoint-manager__base__content__buttons__button"
                onClick={() => {
                  setNameInputValue(activeUserWaypoint.name);
                  setCoordinatesInputValue(coordinatesDMS);
                  setHasUserWaypointChanged(false);
                }}
              >
                Cancelar
              </Button>
              <Button
                className="configs__user-waypoint-manager__base__content__buttons__button"
                disabled={nameInputValue === '' || coordinatesInputValue === '' || isNameAlreadyInUse || isCoordinateInvalid}
                onClick={handleUpdateUserWaypoint}
              >
                Salvar alterações
              </Button>
            </>
          ) : (
            <>
              <Button className="configs__user-waypoint-manager__base__content__buttons__button" onClick={handleShowOnMap}>
                Mostrar no mapa
              </Button>
              <Button className="configs__user-waypoint-manager__base__content__buttons__button" onClick={() => setIsDeleteModalOpen(true)}>
                Excluir ponto
              </Button>
            </>
          )}
        </div>
      </div>
    </div>
  );
};

const mapDispatch = {
  getAllUserWaypointsAction,
};

const mapStateToProps = (state, props) => ({
  userWaypoints: userWaypointsSelector(state, props),
});

export default connect(mapStateToProps, mapDispatch)(withSnackbar(ConfigUserWaypointManager));
