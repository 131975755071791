import './utils/polyfills';

import React from 'react';
import ReactDOM from 'react-dom';
import * as Sentry from '@sentry/browser';
import amplitude from 'amplitude-js';

import App from './App';
import { ENVIRONMENT } from './global';

if (ENVIRONMENT !== 'development') {
  Sentry.init({
    release: process.env.GIT_COMMITHASH,
    environment: ENVIRONMENT,
    dsn: 'https://95eb1c2fa74a400a963bacabb0cb13ed@sentry.nexatlas.com/2',
  });
}

amplitude.getInstance().init('beb00c03c09f2ab2332e93b99f65d55a', null, {
  includeUtm: true,
  includeReferrer: true,
  logAttributionCapturedEvent: true,
  storage: 'cookies',
  sameSiteCookie: 'Lax',
});

amplitude.getInstance().setVersionName(process.env.GIT_COMMITHASH);

ReactDOM.render(<App />, document.getElementById('root'));
