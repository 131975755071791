import React, { useState, useCallback, useContext } from 'react';

import { useSelector, useDispatch } from 'react-redux';

import Stars from 'react-stars';
import { TextBox } from 'react-uwp';
import { getUserTrialFinishDate, isUserTrialChecked, getUserName, getUserSurname, getUserEmail } from '../../redux/selectors/user';
import { setUserFlag } from '../../redux/actions/authActions';
import { sendFeedback } from '../../redux/actions/feedbackActions';

import Cards from './components/Cards';
import metrics from '../../utils/metrics';

import { isTourCompatibleWidth } from '../../utils/screen';
import { getUserFlag } from '../../utils/user';

import './TourContext.styles.scss';

const TourContext = React.createContext();

const TourContextProvider = props => {
  const [currentStep, setCurrentStep] = useState(0);

  const tourInitCondition = getUserFlag('TOUR_DONE') !== 'true' && isTourCompatibleWidth();

  const [tourOpen, setTourOpen] = useState(tourInitCondition);
  const [exitTourDialog, setExitTourDialog] = useState(false);
  const [condStep, setCondStep] = useState(undefined);
  const [feedbackRating, setFeedbackRating] = useState(0);
  const [feedbackComment, setFeedbackComment] = useState('');

  const userTrialFinishDate = useSelector(getUserTrialFinishDate);
  const isTrialChecked = useSelector(isUserTrialChecked);
  const userName = useSelector(getUserName);
  const userSurname = useSelector(getUserSurname);
  const userEmail = useSelector(getUserEmail);

  const dispatch = useDispatch();

  const goToNextStep = useCallback(() => {
    metrics.collect(metrics.events.TOUR.STEP, `${currentStep}`);
    setCurrentStep(prevStep => prevStep + 1);
  }, [currentStep]);

  const startButton = (
    <button
      className="tour__button tour__button--important"
      type="submit"
      onClick={e => {
        e.preventDefault();
        goToNextStep();
      }}
      key={1}
    >
      Iniciar tour
    </button>
  );

  const nextButton = (
    <button
      className="tour__button"
      type="submit"
      onClick={e => {
        e.preventDefault();
        if (condStep === undefined) {
          return goToNextStep();
        }

        if (condStep) {
          setCondStep(undefined);
          return goToNextStep();
        }
      }}
      disabled={condStep === false}
      key={2}
    >
      Próximo passo
    </button>
  );

  const finishButton = (
    <button
      className="tour__button tour__button--important"
      type="submit"
      onClick={async e => {
        e.preventDefault();
        setTourOpen(false);

        if (feedbackComment !== '') {
          await dispatch(sendFeedback(`${userName} ${userSurname}`, userEmail, 'Tour', `Nota: ${feedbackRating}\nComentário: ${feedbackComment}`));
        }

        await dispatch(setUserFlag('TOUR_DONE', 'true'));
        window.location.reload();
      }}
      key={3}
    >
      Concluir
    </button>
  );

  const cancelButton = (
    <button
      className="tour__button tour__button--important tour__button--cancel"
      type="submit"
      onClick={e => {
        e.preventDefault();
        setTourOpen(false);
        setExitTourDialog(true);
      }}
      key={4}
    >
      Não desejo realizar o tour
    </button>
  );

  const steps = Cards.map(card => {
    const processedCard = {};

    const getButton = buttonName => {
      switch (buttonName) {
        case 'next':
          return nextButton;
        case 'start':
          return startButton;
        case 'finish':
          return finishButton;
        case 'cancel':
          return isTrialChecked && new Date() > new Date(userTrialFinishDate) ? cancelButton : null;
        default:
          return null;
      }
    };

    let button;
    if (!card.button) {
      button = null;
    } else if (typeof card.button === 'string') {
      button = getButton(card.button);
    } else {
      button = card.button.map(btn => getButton(btn));
    }

    const content = (
      <div>
        <div className="tour__container">
          {typeof card.content === 'function' ? card.content(userName) : card.content}
          {card.button === 'finish' ? (
            <div className="tour__container">
              <Stars value={feedbackRating} color2="#009a6d" onChange={setFeedbackRating} size={40} className="tour__center" />
              <TextBox placeholder="Algum comentário?" onChangeValue={value => setFeedbackComment(value)} className="tour__feedback__comment" />
              <div /> {/* don't apply last-child style to TextBox */}
            </div>
          ) : null}

          {button}
        </div>
      </div>
    );

    processedCard.content = content;

    if (card.selector) {
      processedCard.selector = card.selector;
    }

    if (card.disableFocusTrap !== undefined) {
      processedCard.action = node => node.focus();
    }

    if (card.stepInteraction !== undefined) {
      processedCard.stepInteraction = false;
    }

    if (card.position !== undefined) {
      processedCard.position = card.position;
    }

    processedCard.style = {
      backgroundColor: '#212121',
      border: '2px solid #009a6d',
    };

    return processedCard;
  });

  const events = {
    [metrics.events.MENU_MAPA.CARTAS_AERONAUTICAS.THIS]: [2],
    [metrics.events.MENU_MAPA.CARTAS_AERONAUTICAS.CARTA]: [3],

    [metrics.events.MENU_PESQUISA.THIS]: [5, 7],
    [metrics.events.MENU_PESQUISA.WPT.ADICIONAR]: [6, 8],

    [metrics.events.MAPA.THIS]: [10],
    [metrics.events.MAPA.REGUA]: [22, 23],
    [metrics.events.MAPA.LISTA.WPT.ADICIONAR]: [11],

    [metrics.events.DRAWER.PLANEJAR_ROTA.INPUT_GS]: [13],
    [metrics.events.DRAWER.PLANEJAR_ROTA.WPT.TROCAR]: [15],
    [metrics.events.DRAWER.PLANEJAR_ROTA.WPT.EXCLUIR]: [16],
    [metrics.events.DRAWER.PLANEJAR_ROTA.SALVAR_ROTA]: [18],

    [metrics.events.DRAWER.ROTAS_SALVAS.THIS]: [19],

    [metrics.events.MENU_MAPA.REGUA]: [21, 25],

    [metrics.events.MENU_MAPA.MAPAS_METEOROLOGICOS.THIS]: [26],
    [metrics.events.MENU_MAPA.MAPAS_METEOROLOGICOS.MAPA]: [27],
  };

  const disables = {
    [metrics.events.MAPA.THIS]: [0, 1, 3, 4, 9], // 0 and 3 are here just for possible information missmatch

    [metrics.events.MENU_MAPA.CARTAS_AERONAUTICAS.THIS]: [1, 30],
    [metrics.events.MENU_MAPA.REGUA]: [1, 4, 9, 22, 23],
    [metrics.events.MENU_MAPA.MAPAS_METEOROLOGICOS.THIS]: [1, 4, 9, 22, 23, 30],

    [metrics.events.DRAWER.PLANEJAR_ROTA.NOVA_ROTA]: [9, 12, 17],
    [metrics.events.DRAWER.PLANEJAR_ROTA.WPT.EXCLUIR]: [9, 12, 15],
    [metrics.events.DRAWER.PLANEJAR_ROTA.SALVAR_ROTA]: [17],
  };

  const isDisabled = elem => {
    if (!tourOpen) {
      return;
    }

    const isEventOnThisStep = disables[elem].find(item => item === currentStep);
    if (!disables[elem] || isEventOnThisStep === undefined) {
      return false;
    }

    return true;
  };

  const dispatchValidation = useCallback(
    elem => {
      if (!tourOpen) {
        return;
      }

      const isEventOnThisStep = events[elem].find(item => item === currentStep);
      if (!events[elem] || !isEventOnThisStep) {
        return;
      }

      return true;
    },
    [currentStep, events, tourOpen],
  );

  const dispatchTourClick = useCallback(
    elem => {
      if (!tourOpen) {
        return;
      }

      if (!dispatchValidation(elem)) {
        return;
      }
      goToNextStep();
    },
    [tourOpen, dispatchValidation, goToNextStep],
  );

  const dispatchTourCondition = (elem, cond) => {
    if (!tourOpen) {
      return;
    }

    if (!dispatchValidation(elem)) {
      return;
    }
    setCondStep(cond);
  };

  const value = {
    steps,
    currentStep,
    condStep,

    dispatchTourClick,
    dispatchTourCondition,
    dispatchValidation,
    isDisabled,

    open: tourOpen,
    openTour: () => setTourOpen(true),
    closeTour: () => setTourOpen(false),

    exitDialog: exitTourDialog,
    openExitDialog: () => setExitTourDialog(true),
    closeExitDialog: () => setExitTourDialog(false),
  };

  return <TourContext.Provider value={value}>{props.children}</TourContext.Provider>;
};

const useTourContext = () => {
  return useContext(TourContext);
};

export { TourContext, TourContextProvider, useTourContext };
