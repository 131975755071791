import { Model, attr } from 'redux-orm';

import { PLAN } from '../actions/types.js';

export default class Plan extends Model {
  static reducer(action, Plan, session) {
    const { payload, type } = action;

    switch (type) {
      case `${PLAN.LOAD_COLLECTION}_SUCCESS`:
        if (payload.data && payload.data.plans) {
          payload.data.plans.forEach(plan => {
            Plan.upsert(plan);
          });
        }
        break;

      default:
        break;
    }
  }
}

Plan.modelName = 'Plan';

Plan.fields = {
  id: attr(),
  text: attr(),
  sequence: attr(),
  active: attr(),
};
