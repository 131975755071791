import React, { useState } from 'react';
import { connect } from 'react-redux';

import Button from 'react-uwp/Button';
import Dropdown from 'components/form/Dropdown/Dropdown';
import CustomScrollbar from 'components/CustomScrollbar/CustomScrollbar';

import { sendFeedback, returnFeedback } from 'redux/actions/feedbackActions';

import metrics from '../../../../utils/metrics';

import './Feedback.style.scss';
import voltarFeedback from 'assets/icons/barras/voltarFeedback.svg';
import TextArea from 'components/form/TextArea/TextArea';

import classnames from 'classnames';

const Feedback = props => {
  const [motivo, setMotivo] = useState(null);
  const [mensagem, setMensagem] = useState('');
  const { onClick } = props;

  if (props.authState.user && props.authState.user_profile) {
    return (
      <div className={classnames('feedback', { 'feedback--open': props.open })} onClick={onClick}>
        <CustomScrollbar className="feedback__scrollbar">
          <div className="feedback__container">
            {!props.feedbackState.feedbackSent ? (
              <div>
                <div className="feedback__title">ENVIAR COMENTÁRIOS</div>
                <div className="feedback__container__session">
                  <p>
                    Compartilhe sua opinião! Você nos aponta onde podemos melhorar e nós garantimos o desenvolvimento da ferramenta ideal para você.
                  </p>
                </div>
                <div className="feedback__container__session">
                  <Dropdown
                    placeholder="Selecione o Motivo"
                    className="feedback__container__dropdown"
                    onChange={v => setMotivo(v !== null ? v.value : null)}
                    options={[
                      {
                        value: 'Crítica',
                        label: 'Crítica',
                      },
                      {
                        value: 'Elogio',
                        label: 'Elogio',
                      },
                      {
                        value: 'Dúvida',
                        label: 'Dúvida',
                      },
                      {
                        value: 'Sugestão',
                        label: 'Sugestão',
                      },
                      {
                        value: 'Suporte técnico',
                        label: 'Suporte técnico',
                      },
                      {
                        value: 'Outro',
                        label: 'Outro',
                      },
                    ]}
                  />
                  <TextArea extraClass="feedback__container__textarea" value={mensagem} onChangeValue={value => setMensagem(value)} />

                  <div className="feedback__container__button-container">
                    <Button
                      tooltip={motivo ? 'Enviar mensagem' : 'Selecione o Motivo'}
                      className="feedback__container__button"
                      disabled={!(mensagem && motivo)}
                      onClick={() => {
                        props.sendFeedback(
                          `${props.authState.user_profile.name} ${props.authState.user_profile.surname}`,
                          props.authState.user.email,
                          motivo,
                          mensagem,
                        );
                        metrics.collect(metrics.events.DRAWER.FALE_CONOSCO.ENVIAR);
                      }}
                    >
                      <span className="feedback__container__button__text">Enviar</span>
                    </Button>
                  </div>
                </div>
              </div>
            ) : (
              <div>
                <img className="feedback__return" onClick={() => props.returnFeedback()} src={voltarFeedback} width={30} height={30} alt="voltar" />
                <div className="feedback__container__session">
                  <p className="feedback__container__session__answerPartOne">Obrigado, sua opinião é muito valiosa para nós!</p>
                  <p className="feedback__container__session__answerPartTwo">
                    Responderemos o mais breve possível diretamente em seu endereço de e-mail.
                  </p>
                  <p className="feedback__container__session__answerPartThree">- Equipe NexAtlas</p>
                </div>
              </div>
            )}
          </div>
        </CustomScrollbar>
      </div>
    );
  }
  return null;
};

const mapStateToProps = (state, props) => ({ authState: state.auth, feedbackState: state.feedback });

const mapDispatchToProps = {
  sendFeedback,
  returnFeedback,
};

export default connect(mapStateToProps, mapDispatchToProps)(Feedback);
