import React from 'react';

import './Snackbar.style.scss';

const Snackbar = props => {
  return (
    <div className="snackbar">
      <div className="snackbar__box">{props.message}</div>
    </div>
  );
};

export default Snackbar;
