import React from 'react';
import PropTypes from 'prop-types';

import CheckBox from 'react-uwp/CheckBox';

import MaterialInput from 'components/form/MaterialInput/MaterialInput';
import InlineButton from 'components/form/InlineButton/InlineButton';

const ContentLoginPassword = props => {
  const { password, keepConected, error, onEnter, onChangePassword, onChangeKeepConnected, onForgotPassword } = props;

  let errorString = null;
  if (error === 'PASSWORD') {
    errorString = (
      <div>
        Senha incorreta. <InlineButton onClick={onForgotPassword}>Esqueceu?</InlineButton>
      </div>
    );
  }

  if (error === 'GOOGLE') {
    errorString = <div>Essa conta também está conectada com sua conta Google</div>;
  }

  return (
    <div>
      <MaterialInput
        type="password"
        name="password"
        label="Senha"
        value={password}
        info={errorString}
        infoLevel={error && 'warning'}
        infoMargin
        onEnter={onEnter}
        onChangeValue={onChangePassword}
      />
      <p>
        <CheckBox defaultChecked={keepConected} label="Mantenha-me conectado" onCheck={onChangeKeepConnected} />
      </p>
      <p>
        <InlineButton onClick={onForgotPassword}>Esqueci minha senha</InlineButton>
      </p>
    </div>
  );
};

ContentLoginPassword.defaultProps = {
  error: null,
  onEnter: () => {},
  onChangePassword: () => {},
  onChangeKeepConnected: () => {},
  onForgotPassword: () => {},
};

ContentLoginPassword.propTypes = {
  password: PropTypes.string.isRequired,
  keepConected: PropTypes.bool.isRequired,
  error: PropTypes.oneOf(['PASSWORD']),
  onEnter: PropTypes.func,
  onChangePassword: PropTypes.func,
  onChangeKeepConnected: PropTypes.func,
  onForgotPassword: PropTypes.func,
};

export default ContentLoginPassword;
