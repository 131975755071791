/* global gtag */
import amplitude from 'amplitude-js';

import { store } from '../redux/store';
import { apiWebClient } from '../services/webclient';

const events = {
  APP: {
    ACCESS: 'APP_ACCESS',
  },
  AUTH: {
    LOGIN_EMAIL: 'AUTH_LOGIN_EMAIL',
    LOGIN_PASSWORD: 'AUTH_LOGIN_PASSWORD',
    LOGIN_GOOGLE: 'AUTH_LOGIN_GOOGLE',
    LOGIN_SUCCESS: 'AUTH_LOGIN_SUCCESS',
    CREATE_EMAIL: 'AUTH_CREATE_EMAIL',
    CREATE_PASSWORD: 'AUTH_CREATE_PASSWORD',
    CREATE_SUCCESS: 'AUTH_CREATE_SUCCESS',
    FORGOT_PASSWORD_EMAIL: 'AUTH_FORGOT_PASSWORD_EMAIL',
    FORGOT_PASSWORD_CHECK: 'AUTH_FORGOT_PASSWORD_CHECK',
    FORGOT_PASSWORD_PASSWORD: 'AUTH_FORGOT_PASSWORD_PASSWORD',
    FORGOT_PASSWORD_FINISH: 'AUTH_FORGOT_PASSWORD_FINISH',
    EMAIL_NEEDS_CONFIRM_FINISH: 'AUTH_EMAIL_NEEDS_CONFIRM_FINISH',
    PROFILE_TYPE: 'AUTH_PROFILE_TYPE',
    PROFILE_TYPE_HELP: 'AUTH_PROFILE_TYPE_HELP',
    PROFILE_PJ_DATA: 'AUTH_PROFILE_PJ_DATA',
    PROFILE_PJ_SEGMENTATION: 'AUTH_PROFILE_PJ_SEGMENTATION',
    PROFILE_PJ_TERMS: 'AUTH_PROFILE_PJ_TERMS',
    PROFILE_PF_DATA: 'AUTH_PROFILE_PJ_DATA',
    PROFILE_PF_SEGMENTATION: 'AUTH_PROFILE_PJ_SEGMENTATION',
    PROFILE_PF_TERMS: 'AUTH_PROFILE_PJ_TERMS',
    PROFILE_FINISH: 'AUTH_PROFILE_FINISH',
  },
  TOUR: {
    STEP: 'TOUR_STEP',
  },
  MAPA: {
    THIS: 'MAPA',
    REGUA: 'MAPA_RULE', // Tour purposes
    PAGE: 'MAPA_PAGE',
    PAYMENT: 'MAPA_PAYMENT',
    PROMO: 'MAPA_PROMO',
    PROMO_CLICK: 'MAPA_PROMO_CLICK',
    WPT: {
      AISWEB: 'MAPA_WPT_AISWEB',
      ARRASTAR: 'MAPA_WPT_ARRASTAR',
    },
    LISTA: {
      WPT: {
        ADICIONAR: 'MAPA_LISTA_WPT_ADICIONAR',
        AISWEB: 'MAPA_LISTA_WPT_AISWEB',
        CENTRALIZAR: 'MAPA_LISTA_WPT_CENTRALIZAR',
      },
    },
  },
  MENU_PESQUISA: {
    THIS: 'MENU_PESQUISA',
    WPT: {
      ADICIONAR: 'MENU_PESQUISA_WPT_ADICIONAR',
      AISWEB: 'MENU_PESQUISA_WPT_AISWEB',
      CENTRALIZAR: 'MENU_PESQUISA_WPT_CENTRALIZAR',
    },
  },
  MENU_MAPA: {
    CARTAS_AERONAUTICAS: {
      THIS: 'MENU_MAPA_CARTAS_AERONAUTICAS',
      CARTA: 'MENU_MAPA_CARTAS_AERONAUTICAS_CARTA',
    },
    MAPAS_METEOROLOGICOS: {
      THIS: 'MENU_MAPA_MAPAS_METEOROLOGICOS',
      MAPA: 'MENU_MAPA_MAPAS_METEOROLOGICOS_MAPA',
    },
    REGUA: 'MENU_MAPA_REGUA',
    AJUSTAR: 'MENU_MAPA_AJUSTAR',
    AMPLIAR: 'MENU_MAPA_AMPLIAR',
    REDUZIR: 'MENU_MAPA_REDUZIR',
  },
  APP_BAR: {
    CLOSE: 'APP_BAR_CLOSE',
    PLAY_STORE: 'APP_BAR_PLAY_STORE',
    APP_STORE: 'APP_BAR_APP_STORE',
    DOWNLOAD_MODAL: 'APP_BAR_DOWNLOAD_MODAL',
    DOWNLOAD_MOBILE: 'APP_BAR_DOWNLOAD_MOBILE',
  },
  DRAWER: {
    HAMBURGUER: 'DRAWER_HAMBURGUER',
    MOSTRAR_MAPA: 'DRAWER_MOSTRAR_MAPA',
    PLANEJAR_ROTA: {
      THIS: 'DRAWER_PLANEJAR_ROTA',
      NOVA_ROTA: {
        THIS: 'DRAWER_PLANEJAR_ROTA_NOVA_ROTA',
        DIALOG: {
          SIM: 'DRAWER_PLANEJAR_ROTA_NOVA_ROTA_DIALOG_SIM',
          CANCELAR: 'DRAWER_PLANEJAR_ROTA_NOVA_ROTA_DIALOG_CANCELAR',
        },
      },
      SALVAR_ROTA: 'DRAWER_PLANEJAR_ROTA_SALVAR_ROTA',
      INVERTER_ROTA: {
        THIS: 'DRAWER_PLANEJAR_ROTA_INVERTER_ROTA',
        DIALOG: {
          SIM: 'DRAWER_PLANEJAR_ROTA_INVERTER_ROTA_DIALOG_SIM',
          CANCELAR: 'DRAWER_PLANEJAR_ROTA_INVERTER_ROTA_DIALOG_CANCELAR',
        },
      },
      SEGMENTAR_ROTA: {
        THIS: 'DRAWER_PLANEJAR_ROTA_SEGMENTAR_ROTA'
      },
      IMPRIMIR_NAV_LOG: 'DRAWER_PLANEJAR_ROTA_IMPRIMIR_NAV_LOG',
      EXPORT: {
        GARMIN: 'DRAWER_PLANEJAR_ROTA_EXPORT_GARMIN',
        XPLANE: 'DRAWER_PLANEJAR_ROTA_EXPORT_XPLANE',
        XPLANE11: 'DRAWER_PLANEJAR_ROTA_EXPORT_XPLANE11',
        PLN: 'DRAWER_PLANEJAR_ROTA_EXPORT_PLN',
      },
      RENOMEAR: 'DRAWER_PLANEJAR_ROTA_RENOMEAR',
      INPUT_GS: 'DRAWER_PLANEJAR_ROTA_INPUT_GS',
      WPT: {
        EXCLUIR: 'DRAWER_PLANEJAR_ROTA_WPT_EXCLUIR',
        RENOMEAR: 'DRAWER_PLANEJAR_ROTA_WPT_RENOMEAR',
        AISWEB: 'DRAWER_PLANEJAR_ROTA_WPT_AISWEB',
        TROCAR: 'DRAWER_PLANEJAR_ROTA_WPT_TROCAR',
      },
    },
    ROTAS_SALVAS: {
      THIS: 'DRAWER_ROTAS_SALVAS',
      ORDEM_CRONO: 'DRAWER_ROTAS_SALVAS_ORDEM_CRONO',
      ORDEM_AZ: 'DRAWER_ROTAS_SALVAS_ORDEM_AZ',
      ROTA_SELECIONAR: 'DRAWER_ROTAS_SALVAS_ROTA_SELECIONAR',
      ROTA_EXCLUIR: {
        THIS: 'DRAWER_ROTAS_SALVAS_ROTA_EXCLUIR',
        DIALOG: {
          SIM: 'DRAWER_ROTAS_SALVAS_ROTA_EXCLUIR_DIALOG_SIM',
          CANCELAR: 'DRAWER_ROTAS_SALVAS_ROTA_EXCLUIR_DIALOG_CANCELAR',
        },
      },
    },
    APLICATIVO: {
      THIS: 'DRAWER_APLICATIVO',
      PLAY_STORE: 'DRAWER_APLICATIVO_PLAY_STORE',
      APP_STORE: 'DRAWER_APLICATIVO_APP_STORE',
    },
    NOVIDADES: 'DRAWER_NOVIDADES',
    FALE_CONOSCO: {
      THIS: 'DRAWER_FALE_CONOSCO',
      ENVIAR: 'DRAWER_FALE_CONOSCO_ENVIAR',
    },
    CONFIGURACOES: {
      THIS: 'DRAWER_CONFIG',
      ALTERAR_SENHA: {
        THIS: 'DRAWER_CONFIG_ALTERAR_SENHA',
        DIALOG: {
          SIM: 'DRAWER_CONFIG_ALTERAR_SENHA_DIALOG_SIM',
          CANCELAR: 'DRAWER_CONFIG_ALTERAR_SENHA_DIALOG_CANCELAR',
        },
      },
      REFAZER_TOUR: 'DRAWER_CONFIG_REFAZER_TOUR',
      SAIR: 'DRAWER_CONFIG_SAIR',
      ASSINE_JA: {
        THIS: 'DRAWER_CONFIG_ASS_JA',
        PLANO: 'DRAWER_CONFIG_ASS_JA_PLANO',
        FORMA_PGTO: 'DRAWER_CONFIG_ASS_JA_FORMA_PGTO',
        INFO_NF: 'DRAWER_CONFIG_ASS_JA_INFO_NF',
        INFO_CARTAO: 'DRAWER_CONFIG_ASS_JA_INFO_CARTAO',
        CONFIRMAR: 'DRAWER_CONFIG_ASS_JA_CONFIRMAR',
        INICIAR: 'DRAWER_CONFIG_ASS_JA_INICIAR',
      },
      SEG_VIA_BOLETO: 'DRAWER_CONFIG_ASS_JA_SEG_VIA_BOLETO',
      MODIFICAR_ASS: 'DRAWER_CONFIG_ASS_JA_MOD_ASS',
      GERENCIAR_DEPENDENTES: 'DRAWER_CONFIG_GEREN_DEPEND',
      DEIXAR_SER_DEPENDENTE: {
        THIS: 'DRAWER_CONFIG_DEIXAR_SER_DEPEND',
        DIALOG: {
          SIM: 'DRAWER_CONFIG_DEIXAR_SER_DEPEND_DIALOG_SIM',
          CANCELAR: 'DRAWER_CONFIG_DEIXAR_SER_DEPEND_DIALOG_CANCELAR',
        },
      },
      CANCELAR_ASS: {
        THIS: 'DRAWER_CONFIG_ASS_JA_CAN_ASS',
        CANCELAR: 'DRAWER_CONFIG_ASS_JA_CAN_ASS_CANCELAR',
        RETORNAR: 'DRAWER_CONFIG_ASS_JA_CAN_ASS_RETORNAR',
      },
      MAIS_INFORMACOES: 'DRAWER_CONFIG_MAIS_INFORMACOES',
      TERMOS: 'DRAWER_CONFIG_TERMOS',
    },
  },
  AVGAS: {
    ACTIVATE_BUTTON: 'AVGAS_PROBLEM_ACTIVATE_BUTTON',
    ACTIVATE_LAYER: 'AVGAS_PROBLEM_ACTIVATE_LAYER',
    SELECT_WAYPOINT: 'AVGAS_PROBLEM_SELECT_WAYPOINT',
  },
};

const collect = (event, extra) => {
  if (!event) {
    throw new Error('Event is mandatory!');
  }
  if (typeof event !== 'string') {
    throw new Error(`Event type is ${typeof event} and should be string.`);
  }
  if (typeof extra !== 'undefined' && typeof extra !== 'string') {
    throw new Error(`If extra isn't undefined, it should be a string. Actually it's ${typeof extra}`);
  }

  amplitude.getInstance().logEvent(event, { source: 'webapp', extra });

  gtag('event', event.toLowerCase(), {
    extra,
  });

  const authState = store.getState().auth;
  if (authState.logged_in && authState.token) {
    apiWebClient.post('/user/registerMetricEvent', { source: 'webapp', event, extra });
  }
};

export default { collect, events };
