import { FEEDBACK } from './types';

export function sendFeedback(name, email, motive, message) {
  return {
    type: FEEDBACK.SEND,
    payload: {
      client: 'api',
      request: {
        url: '/user/contact',
        method: 'post',
        data: {
          name,
          email,
          motive,
          message,
          timestamp: new Date().toISOString(),
        },
      },
    },
  };
}

export function returnFeedback() {
  return {
    type: FEEDBACK.RESET_STATE,
  };
}
