import React, { Component } from 'react';

import './AppContent.style.scss';

import AppMobile from 'assets/logos/nexatlas-mobile.svg';
import AppStar from 'assets/icons/barras/star-app.svg';
import PlayStore from 'assets/icons/barras/play-store-logo.png';
import AppStore from 'assets/icons/barras/app-store-logo.png';
import Circle from 'assets/icons/barras/ellipse.svg';
import QrCode from 'assets/icons/barras/qrcode.svg';

import metrics from '../../../../../../utils/metrics';

class AppContent extends Component {
  render() {
    return (
      <div className="app__content">
        <div className="app__content__logo">
          <img src={AppMobile} alt="logo-nexatlas" />
          <div className="app__content__app-info">
            <div className="app__content__title-container">
              <span className="app__content__title">NexAtlas</span>
              <img src={AppStar} alt="app-star" />
            </div>
            <p className="app__content__subtitle">Planejamento e navegação aérea</p>
          </div>
        </div>
        <div className="app__content__description">
          <p className="app__content__description__text">Tenha a experiência completa com o aplicativo NexAtlas.</p>
          <p className="app__content__description__subtext">Baixe agora em seu dispositivo Android ou iOS e comece a navegar!</p>
        </div>
        <div className="app__content__buttons">
          <a
            className="app__content__buttons__play-store"
            href="https://play.google.com/store/apps/details?id=com.nexatlas.pilotapp"
            onClick={() => metrics.collect(metrics.events.DRAWER.APLICATIVO.PLAY_STORE)}
            target="_blank"
            rel="noopener noreferrer"
          >
            <img src={PlayStore} alt="play-store" width={130} height={40} />
          </a>
          <a
            href="https://apps.apple.com/us/app/nexatlas/id1562704198"
            onClick={() => metrics.collect(metrics.events.DRAWER.APLICATIVO.APP_STORE)}
            target="_blank"
            rel="noopener noreferrer"
          >
            <img src={AppStore} alt="app-store" width={130} height={40} />
          </a>
        </div>
        <div className="app__content__description-qrcode">
          <div className="app__content__description-qrcode__text">
            <img src={Circle} alt="circulo" />
            <span>Enviar para seu tablet ou celular:</span>
          </div>
          <div className="app__content__description-qrcode__image">
            <img src={QrCode} alt="qr-code" />
          </div>
          <div className="app__content__description-qrcode__subtext">
            <p>1. Abra a câmera ou o leitor de QR codes de seu telefone;</p>
            <p>2. Alinhe o QR code na moldura da câmera. Você verá uma notificação quando o código for lido;</p>
            <p>3. Clique na notificação para abrir o link para download;</p>
          </div>
        </div>
      </div>
    );
  }
}

export default AppContent;
