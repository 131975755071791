import { Component } from 'react';

export default class GoTo extends Component {
  componentDidMount() {
    window.setTimeout(() => {
      document.location = this.props.location;
    }, 1000);
  }

  render() {
    return null;
  }
}
