import React, { useState, useEffect, useCallback } from 'react';

import Button from 'react-uwp/Button';
import Icon from 'react-uwp/Icon';
import TextBox from 'react-uwp/TextBox';
import Dropdown from 'components/form/Dropdown/Dropdown';
import CustomScrollbar from 'components/CustomScrollbar/CustomScrollbar';

import CardValidator from 'card-validator';

import metrics from '../../../../../../utils/metrics';

import ImageCards from './assets/cards.svg';

import './ConfigsCreditCard.styles.scss';

const cardNumberRegex = /^\d{0,20}$/;
const csvRegex = /^\d{0,4}$/;

const csvFullRegex = /^\d{3,4}$/;

export default props => {
  const { values, single, error, onReturn, onFinish } = props;

  const [number, setNumber] = useState((values && values.number) || null);
  const [name, setName] = useState((values && values.name) || null);
  const [validMonth, setValidMonth] = useState((values && values.validMonth) || null);
  const [validYear, setValidYear] = useState((values && values.validYear) || null);
  const [csv, setCsv] = useState((values && values.csv) || null);

  const [isCardPotentiallyValid, setCardPotentiallyValid] = useState(true);
  const [isCardValid, setCardValid] = useState(false);

  useEffect(() => {
    if (number && number.length > 0) {
      const cardValidation = CardValidator.number(number);
      setCardPotentiallyValid(cardValidation.isPotentiallyValid);
      setCardValid(cardValidation.isValid);
    } else {
      setCardPotentiallyValid(true);
      setCardValid(false);
    }
  }, [number]);

  const _setNumber = useCallback(
    number => {
      if (number.match(cardNumberRegex)) {
        setNumber(number);
      }
    },
    [setNumber],
  );

  const _setName = useCallback(
    name => {
      setName(name.toUpperCase());
    },
    [setName],
  );

  const _setCsv = useCallback(
    csv => {
      if (csv.match(csvRegex)) {
        setCsv(csv);
      }
    },
    [setCsv],
  );

  return (
    <div className="configs__base">
      <div className="configs__title">
        {!single && (
          <Icon onClick={() => onReturn()} className="configs__title__action">
            ChevronLeft
          </Icon>
        )}
        DADOS DO CARTÃO
      </div>
      <CustomScrollbar className="configs__scroll">
        <div className="configs__container">
          <div className="configs__container__session">
            <img src={ImageCards} alt="" className="configs__credit-card__cards" />

            <hr className="configs__credit-card__separator" />

            {error === 'INVALID_CREDIT_CARD' && (
              <div className="configs__credit-card__alert">
                Cartão de crédito com número incorreto ou com restrição bancária. Por favor, consulte o seu banco para desbloquear essa operação.
                <br />
                Caso precise de ajuda, contacte-nos pelo chat no canto inferior esquerdo!
              </div>
            )}

            <div className="configs__credit-card__formItem">
              <label>Número do Cartão:</label>
              <TextBox value={number || ''} onChangeValue={value => _setNumber(value)} background="#1e262a" />
            </div>
            {!isCardPotentiallyValid && <div className="configs__credit-card__helper">Cartão de Crédito Inválido</div>}

            <div className="configs__credit-card__formItem">
              <label>Nome (igual no cartão):</label>
              <TextBox value={name || ''} onChangeValue={value => _setName(value)} background="#1e262a" />
            </div>

            <div className="configs__credit-card__formItem">
              <label>Data de validade:</label>

              <div className="configs__credit-card__formItem__valid-to">
                <Dropdown
                  options={[...Array(12).keys()].map(i => ({
                    value: `000000000${i + 1}`.substr(-2),
                    label: `000000000${i + 1}`.substr(-2),
                  }))}
                  onChange={value => setValidMonth(value)}
                  placeholder=""
                  value={validMonth || ''}
                  className="configs__credit-card__formItem__small--valid-to"
                />
                <Dropdown
                  options={['22', '23', '24', '25', '26', '27', '28', '29', '30', '31', '32', '33', '34', '35', '36', '37', '38', '39', '40'].map(
                    year => ({
                      value: year,
                      label: `20${year}`,
                    }),
                  )}
                  onChange={value => setValidYear(value)}
                  placeholder=""
                  value={validYear || ''}
                  className="configs__credit-card__formItem__small--valid-to"
                />
              </div>
            </div>

            <div className="configs__credit-card__formItem">
              <label>Código de segurança:</label>
              <TextBox
                value={csv || ''}
                onChangeValue={value => _setCsv(value)}
                className="configs__credit-card__formItem__small"
                background="#1e262a"
              />
            </div>

            <p className="configs__container__paragraph">
              Suas informações estão protegidas pela tecnologia Pagar.me utilizando os mais rigorosos padrões de segurança na indústria de pagamentos.
            </p>
            <div className="configs__credit-card__button">
              <Button
                className="user-options__logout__button"
                onClick={() => {
                  onFinish({ number, name, validMonth, validYear, csv });
                  metrics.collect(metrics.events.DRAWER.CONFIGURACOES.ASSINE_JA.INFO_CARTAO);
                }}
                disabled={!number || !isCardValid || !name || !validMonth || !validYear || !csv || !csv.match(csvFullRegex)}
              >
                {single ? 'Alterar' : 'Próximo'}
              </Button>
            </div>
          </div>
        </div>
      </CustomScrollbar>
    </div>
  );
};
