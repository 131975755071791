export function sortMapByKeyAndGetValues(map) {
  const keys = [...map.keys()];
  keys.sort();

  return keys.map(key => map.get(key));
}

export const unrefObj = obj => {
  return { ...obj };
};

export const unrefObjArray = obj => {
  return obj.map(o => ({ ...o }));
};
