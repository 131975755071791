import React from 'react';
import Slider from 'react-rangeslider';
import './MeteorologySlider.scss';

import { resolveLevelToLabel } from '../../utils';

const MeteorologySlider = props => {
  return (
    <div className="map__meteorology-slider">
      <Slider
        min={0}
        max={15}
        value={props.value}
        tooltip={false}
        onChange={props.onChange}
        orientation="vertical"
        handleLabel={resolveLevelToLabel(props.value)}
      />
    </div>
  );
};

export default MeteorologySlider;
