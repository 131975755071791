import React from 'react';
import PropTypes from 'prop-types';

import Dropdown from 'components/form/Dropdown/Dropdown';

const ContentProfile2 = props => {
  const { onChangeSegment } = props;

  return (
    <div>
      <Dropdown
        name="airship"
        isClearable
        placeholder="Qual é a área de atuação da empresa?"
        onChange={v => onChangeSegment(v !== null ? v.value : null)}
        options={[
          {
            value: 'Escola de Aviação / Aeroclube',
            label: 'Escola de Aviação / Aeroclube',
          },
          {
            value: 'Taxi aéreo',
            label: 'Taxi aéreo',
          },
          {
            value: 'Aviação particular',
            label: 'Aviação particular',
          },
          {
            value: 'Outro',
            label: 'Outro',
          },
        ]}
      />
    </div>
  );
};

ContentProfile2.defaultProps = {
  onChangeSegment: () => {},
};

ContentProfile2.propTypes = {
  onChangeSegment: PropTypes.func,
};

export default ContentProfile2;
