import React from 'react';
import PropTypes from 'prop-types';

import { maskTypingCNPJ } from 'utils/masks';

import MaterialInput from 'components/form/MaterialInput/MaterialInput';

const ContentProfile2 = props => {
  const { razaoSocial, cnpj, cnpjError, phone, onChangeRazaoSocial, onChangeCNPJ, onChangePhone } = props;

  let phoneNumber = '';
  if (phone[1].length <= 4) {
    phoneNumber = phone[1].slice(0, 4);
  } else if (phone[1].length <= 8) {
    phoneNumber = `${phone[1].slice(0, 4)}-${phone[1].slice(4, 8)}`;
  } else {
    phoneNumber = `${phone[1].slice(0, 5)}-${phone[1].slice(5, 9)}`;
  }

  let phoneValue = '';
  if (phone[0].length === 1) {
    phoneValue = `(${phone[0]}`;
  } else if (phone[0].length === 2 && phone[1].length === 0) {
    phoneValue = `(${phone[0]}`;
  } else if (phone[0].length === 2 && phone[1].length > 0) {
    phoneValue = `(${phone[0]}) ${phoneNumber}`;
  }

  const preOnChangePhone = phoneInputValue => {
    if (!phoneInputValue) return ['', ''];

    const phoneClean = phoneInputValue.replace(/[^0-9]/g, '');

    const phoneSlice = [];
    if (phoneClean.length <= 2) {
      phoneSlice[0] = String(phoneClean);
      phoneSlice[1] = '';
    } else {
      phoneSlice[0] = String(phoneClean).slice(0, 2);
      phoneSlice[1] = String(phoneClean).slice(2);
    }

    if (phoneSlice[1].length <= 9) {
      onChangePhone(phoneSlice);
    }

    return phoneSlice;
  };

  return (
    <div>
      <div style={{ marginBottom: '50px' }}>
        <MaterialInput
          name="razao-social"
          label="Nome da empresa (razão social)*"
          value={razaoSocial}
          infoMargin
          onChangeValue={onChangeRazaoSocial}
        />
      </div>
      <div>
        <MaterialInput
          name="cnpj"
          label="CNPJ*"
          value={cnpj}
          info={cnpjError && 'CNPJ incorreto'}
          infoLevel={cnpjError ? 'warning' : 'message'}
          infoMargin
          onChangeValue={value => {
            const cleanValue = value.replace(/[^0-9]/g, '');

            if (cleanValue.length > 14) {
              return;
            }

            onChangeCNPJ(maskTypingCNPJ(cleanValue));
          }}
        />
      </div>
      <div>
        <MaterialInput name="phone" label="Celular" value={phoneValue} infoMargin onChangeValue={preOnChangePhone} />
      </div>
    </div>
  );
};

ContentProfile2.defaultProps = {
  cnpjError: false,
  phone: ['', ''],
  onChangeRazaoSocial: () => {},
  onChangeCNPJ: () => {},
  onChangePhone: () => {},
};

ContentProfile2.propTypes = {
  razaoSocial: PropTypes.string.isRequired,
  cnpj: PropTypes.string.isRequired,
  cnpjError: PropTypes.bool,
  phone: PropTypes.arrayOf(PropTypes.string),
  onChangeRazaoSocial: PropTypes.func,
  onChangeCNPJ: PropTypes.func,
  onChangePhone: PropTypes.func,
};

export default ContentProfile2;
