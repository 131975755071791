/**
 *
 * @param {string} routeName
 * @param {{
 *  name: string;
 *  latitude: number;
 *  longitude: number;
 * }[]} points
 * @return {string}
 */
function generateGPX(routeName, points) {
  const parsedPoints = points.reduce(
    (total, point) => `${total}<trkpt lat="${point.latitude}" lon="${point.longitude}"><name>${point.name}</name></trkpt>`,
    '',
  );

  const GPX = `<gpx><metadata><name>NexAtlas | ${routeName}</name><author>NexAtlas</author><time>${new Date().toISOString()}</time></metadata><trk><name>${routeName}</name><trkseg>${parsedPoints}</trkseg></trk></gpx>`;

  return GPX;
}

export default generateGPX;
