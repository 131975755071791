import React from 'react';

import './DependantItem.style.scss';
import IconTrash from '../../../../../assets/icon_trash.svg';

export default props => {
  const { item, onRemove } = props;

  return (
    <div className="config__dependant__item__wrapper">
      <div className="config__dependant__item__left">
        <div className="config__dependant__item__title">{item.email}</div>
      </div>
      <div className="config__dependant__item__right">
        <div
          className="config__dependant__item__icon"
          onClick={e => {
            onRemove(item.email);
            e.stopPropagation();
          }}
          role="button"
          onKeyDown={() => onRemove(item.email)}
          tabIndex="0"
        >
          <img src={IconTrash} alt="" />
        </div>
      </div>
    </div>
  );
};
