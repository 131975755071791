import React from 'react';
import PropTypes from 'prop-types';

import MaterialInput from 'components/form/MaterialInput/MaterialInput';

const ContentProfile2e2 = props => {
  const { name, surname, phone, onChangeName, onChangeSurname, onChangePhone } = props;

  let phoneNumber = '';
  if (phone[1].length <= 4) {
    phoneNumber = phone[1].slice(0, 4);
  } else if (phone[1].length <= 8) {
    phoneNumber = `${phone[1].slice(0, 4)}-${phone[1].slice(4, 8)}`;
  } else {
    phoneNumber = `${phone[1].slice(0, 5)}-${phone[1].slice(5, 9)}`;
  }

  let phoneValue = '';
  if (phone[0].length === 1) {
    phoneValue = `(${phone[0]}`;
  } else if (phone[0].length === 2 && phone[1].length === 0) {
    phoneValue = `(${phone[0]}`;
  } else if (phone[0].length === 2 && phone[1].length > 0) {
    phoneValue = `(${phone[0]}) ${phoneNumber}`;
  }

  const preOnChangePhone = phoneInputValue => {
    if (!phoneInputValue) return ['', ''];

    const phoneClean = phoneInputValue.replace(/[^0-9]/g, '');

    const phoneSlice = [];
    if (phoneClean.length <= 2) {
      phoneSlice[0] = String(phoneClean);
      phoneSlice[1] = '';
    } else {
      phoneSlice[0] = String(phoneClean).slice(0, 2);
      phoneSlice[1] = String(phoneClean).slice(2);
    }

    if (phoneSlice[1].length <= 9) {
      onChangePhone(phoneSlice);
    }

    return phoneSlice;
  };

  return (
    <div>
      <div>
        <MaterialInput name="name" label="Nome*" value={name} infoMargin onChangeValue={onChangeName} />
      </div>
      <div>
        <MaterialInput name="surname" label="Sobrenome*" value={surname} infoMargin onChangeValue={onChangeSurname} />
      </div>
      <div>
        <MaterialInput name="phone" label="Celular" value={phoneValue} infoMargin onChangeValue={preOnChangePhone} />
      </div>
    </div>
  );
};

ContentProfile2e2.defaultProps = {
  phone: ['', ''],
  onChangeName: () => {},
  onChangeSurname: () => {},
  onChangePhone: () => {},
};

ContentProfile2e2.propTypes = {
  name: PropTypes.string.isRequired,
  surname: PropTypes.string.isRequired,
  phone: PropTypes.arrayOf(PropTypes.string),
  onChangeName: PropTypes.func,
  onChangeSurname: PropTypes.func,
  onChangePhone: PropTypes.func,
};

export default ContentProfile2e2;
