import React, { Component } from 'react';
import { connect } from 'react-redux';

import Cookies from 'js-cookie';
import CheckPayment, { STATUS as PAYMENT_STATUS } from 'components/CheckPayment/CheckPayment';
import ConfirmModal from 'components/ConfirmModal/ConfirmModal.jsx';

import { registerAccess as registerAccessAction, refreshUser as refreshUserAction, logout as logoutAction } from 'redux/actions/authActions';
import { loadNews as loadNewsAction } from 'redux/actions/newsActions';
import { loadPlans as loadPlansAction } from 'redux/actions/planActions';
import { loadLastUpdate as loadLastUpdateAction, loadUpdateItems as loadUpdateItemsAction } from 'redux/actions/dataUpdateActions';

import { TourContextProvider } from 'context/TourContext/TourContext';
import MapPayment from '../pages/MapPayment/MapPayment.jsx';
import MapMain from '../pages/MapMain/MapMain.jsx';

class DashboardLayout extends Component {
  componentDidMount() {
    this.props.registerAccessAction('/dashboard');
    this.props.loadNewsAction(0);
    this.props.loadPlansAction();
    this.props.loadLastUpdateAction();
    this.props.loadUpdateItemsAction();
    this.migrateUserCookie();

    this.refreshUserInterval = setInterval(() => {
      this.props.refreshUserAction();
    }, 60000);
  }

  componentWillUnmount() {
    clearInterval(this.refreshUserInterval);
  }

  migrateUserCookie() {
    const { auth } = this.props;

    // Sets login cookie
    const cookieName = '_nat';
    const cookieValue = auth.token;
    Cookies.set(cookieName, cookieValue, { expires: 365, domain: '.nexatlas.com' });

    // Sets profile cookie
    const profileCookieName = '_nap';
    const profileCookieValue = JSON.stringify({
      id: auth.user.id,
      email: auth.user.email,
      name: auth.user_profile?.name,
      surname: auth.user_profile?.surname,
      segment: auth.user_profile?.aviation_segment || auth.user_profile?.self_classification,
    });
    Cookies.set(profileCookieName, profileCookieValue, { expires: 365, domain: '.nexatlas.com' });
  }

  render() {
    const { auth, logoutAction } = this.props;

    return (
      <div className="dashboard">
        {auth.token_dead && (
          <ConfirmModal
            message={
              'Você será desconectado pois entrou em outro computador ou sua sessão expirou.\n\nCaso queira um plano com mais computadores, entre em contato pelo painel de Feedbacks ou envie uma mensagem para contato@nexatlas.com'
            }
            width={480}
            open
            onYes={() => logoutAction()}
            disableCancel
          />
        )}

        <CheckPayment>
          {({ status }) => {
            if (status === PAYMENT_STATUS.SUBSCRIPTION_FINISHED_INTRIAL || status === PAYMENT_STATUS.OK) {
              return (
                <TourContextProvider>
                  <MapMain status={status === PAYMENT_STATUS.SUBSCRIPTION_FINISHED_INTRIAL ? PAYMENT_STATUS.SUBSCRIPTION_FINISHED : 'MAIN'} />
                </TourContextProvider>
              );
            }
            return <MapPayment status={status} />;
          }}
        </CheckPayment>
      </div>
    );
  }
}

const mapDispatch = {
  registerAccessAction,
  refreshUserAction,
  logoutAction,
  loadNewsAction,
  loadPlansAction,
  loadLastUpdateAction,
  loadUpdateItemsAction,
};

const mapStateToProps = state => ({
  auth: state.auth,
});

export default connect(mapStateToProps, mapDispatch)(DashboardLayout);
