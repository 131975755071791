import React from 'react';
import { connect } from 'react-redux';
import { Route, Switch } from 'react-router-dom';

import AuthPage from '../pages/Auth/AuthPage.jsx';

import GoTo from './GoTo';
import { AUTH, DASHBOARD } from './router';

const mainRouter = (auth, props) => {
  const { location } = props;

  if (auth.logged_in && !location.pathname.startsWith(`${AUTH.MAIN}/confirmEmail/`) && !location.pathname.startsWith(`${AUTH.MAIN}/recovery/`)) {
    return <GoTo location={DASHBOARD.MAIN} />;
  }

  return (<AuthPage {...props} />);
};

const AuthLayout = props => {
  const { auth } = props;

  return (
    <Switch>
      <Route path={AUTH.MAIN} render={props => mainRouter(auth, props)} />
    </Switch>
  );
};

const mapDispatch = {};
const mapStateToProps = state => ({
  auth: state.auth,
});

export default connect(mapStateToProps, mapDispatch)(AuthLayout);
