/* global gtag */

import React, { useState, useEffect, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import ReactRouterPropTypes from 'react-router-prop-types';
import { useTurnstile } from "react-turnstile";

import ReactPixel from 'react-facebook-pixel';
import LinkedInTag from 'react-linkedin-insight';

import { loginWebClient } from 'services/webclient';

import { checkCNPJ } from 'utils/compare';
import Metrics from 'utils/metrics';

import InlineButton from 'components/form/InlineButton/InlineButton';

import AuthScreen from './components/Screen/AuthScreen';
import MainContainer from './components/MainContainer/Container';
import InfoContainer from './components/InfoContainer/InfoContainer';
import TermsModal from './components/TermsModal/TermsModal';

import ContentLoginUser from './components/ContentLoginUser/ContentLoginUser';
import ContentLoginPassword from './components/ContentLoginPassword/ContentLoginPassword';
import ContentChangePassword2 from './components/ContentChangePassword2/ContentChangePassword2';
import ContentChangePassword3 from './components/ContentChangePassword3/ContentChangePassword3';
import ContentChangePassword4 from './components/ContentChangePassword4/ContentChangePassword4';
import ContentChangePassword5 from './components/ContentChangePassword5/ContentChangePassword5';
import ContentCreate1 from './components/ContentCreate1/ContentCreate1';
import ContentCreate2 from './components/ContentCreate2/ContentCreate2';
import ContentCreate3 from './components/ContentCreate3/ContentCreate3';
import ContentCreate4 from './components/ContentCreate4/ContentCreate4';
import ContentProfile1 from './components/ContentProfile1/ContentProfile1';
import ContentProfile2 from './components/ContentProfile2/ContentProfile2';
import ContentProfile2e1 from './components/ContentProfile2_1/ContentProfile2_1';
import ContentProfile2e2 from './components/ContentProfile2_2/ContentProfile2_2';
import ContentProfile2e3 from './components/ContentProfile2_3/ContentProfile2_3';
import ContentProfile3 from './components/ContentProfile3/ContentProfile3';
import ContentProfile3e3 from './components/ContentProfile3_3/ContentProfile3_3';
import ContentProfile4 from './components/ContentProfile4/ContentProfile4';

import { emailConfirmRegex, recoveryRegex, emailRegex } from './constants';

import {
  login as loginAction,
  googleLogin as googleLoginAction,
  recoveryPassword as recoveryPasswordAction,
  userCreate as userCreateAction,
  userProfileCreate as userProfileCreateAction,
} from '../../redux/actions/authActions';

import './AuthPage.style.scss';

const AuthPage = props => {
  const { location, history } = props;

  const dispatch = useDispatch();
  const authState = useSelector(state => state.auth);
  const turnstile = useTurnstile();

  const [activePage, setActivePage] = useState('LoginUser');
  const [errors, setErrors] = useState(null);
  const [loading, setLoading] = useState(false);
  const [termsOpen, setTermsOpen] = useState(false);

  const [error, setError] = useState(null);
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [captchaToken, setCaptchaToken] = useState(null);
  const [keepConnected, setKeepConnected] = useState(true);

  const [type, setType] = useState('PF');
  const [emailConfirmLoading, setEmailConfirmLoading] = useState(false);
  const [emailConfirmError, setEmailConfirmError] = useState(false);
  const [name, setName] = useState(null);
  const [surname, setSurname] = useState(null);
  const [telephone, setTelephone] = useState(['', '']);
  const [document, setDocument] = useState(null);
  const [cnpjError, setCnpjError] = useState(false);
  const [pilot, setPilot] = useState(true);
  const [pilotProfessional, setPilotProfessional] = useState(true);
  const [airship, setAirship] = useState(null);
  const [segment, setSegment] = useState(null);
  const [classification, setClassification] = useState(null);
  const [terms, setTerms] = useState(false);
  const [termsError, setTermsError] = useState(false);

  const [recoveryToken, setRecoveryToken] = useState(null);
  const [recoveryLoading, setRecoveryLoading] = useState(false);

  useEffect(() => {
    const emailConfirmMatch = location.pathname.match(emailConfirmRegex);
    if (emailConfirmMatch && emailConfirmMatch.length === 2) {
      setEmailConfirmLoading(true);

      loginWebClient
        .post('/user/confirmEmail', {
          token: emailConfirmMatch[1],
        })
        .then(response => {
          if (response.data && response.data.error) {
            setEmailConfirmError(true);
          }

          setEmailConfirmLoading(false);
        })
        .catch(() => {
          setEmailConfirmLoading(false);
          setActivePage('Profile1');
        });

      setActivePage('Profile1');
      return;
    }

    const recoveryMatch = location.pathname.match(recoveryRegex);
    if (recoveryMatch && recoveryMatch.length === 2) {
      setRecoveryToken(recoveryMatch[1]);
      setRecoveryLoading(true);

      loginWebClient
        .post('/user/checkPasswordRequest', {
          token: recoveryMatch[1],
        })
        .then(response => {
          if (response.data.status !== 302) {
            setError('INVALID_TOKEN');
          }

          setRecoveryLoading(false);
        })
        .catch(() => {
          setError('INVALID_TOKEN');
          setRecoveryLoading(false);
        });

      setActivePage('ChangePassword3');
    }
  }, [location]);

  useEffect(() => {
    const emailConfirmMatch = location.pathname.match(emailConfirmRegex);

    if (
      !emailConfirmMatch &&
      authState?.user?.id !== null &&
      authState?.user?.id !== undefined &&
      (!authState.user_profile || authState?.user_profile?.complete !== 1) &&
      (activePage === 'LoginUser' || activePage === 'LoginPassword' || activePage === 'Create3')
    ) {
      setActivePage('Create1');
    }
  }, [authState, activePage, location]);

  useEffect(() => {
    switch (activePage) {
      case 'LoginUser':
        Metrics.collect(Metrics.events.AUTH.LOGIN_EMAIL);
        break;
      case 'LoginPassword':
        Metrics.collect(Metrics.events.AUTH.LOGIN_PASSWORD);
        break;
      case 'ChangePassword2':
        Metrics.collect(Metrics.events.AUTH.FORGOT_PASSWORD_EMAIL);
        break;
      case 'ChangePassword3':
        Metrics.collect(Metrics.events.AUTH.FORGOT_PASSWORD_CHECK);
        break;
      case 'ChangePassword4':
        Metrics.collect(Metrics.events.AUTH.FORGOT_PASSWORD_PASSWORD);
        break;
      case 'ChangePassword5':
        Metrics.collect(Metrics.events.AUTH.FORGOT_PASSWORD_FINISH);
        break;
      case 'Create3':
        Metrics.collect(Metrics.events.AUTH.CREATE_EMAIL);
        break;
      case 'Create4':
        Metrics.collect(Metrics.events.AUTH.CREATE_PASSWORD);
        break;
      case 'Create5':
        Metrics.collect(Metrics.events.AUTH.CREATE_SUCCESS);
        break;
      case 'Profile1':
        Metrics.collect(Metrics.events.AUTH.EMAIL_NEEDS_CONFIRM_FINISH);
        break;
      case 'Create1':
        Metrics.collect(Metrics.events.AUTH.PROFILE_TYPE);
        break;
      case 'Create2':
        Metrics.collect(Metrics.events.AUTH.PROFILE_TYPE_HELP);
        break;
      case 'Profile2':
        Metrics.collect(Metrics.events.AUTH.PROFILE_PJ_DATA);
        break;
      case 'Profile2_1':
        Metrics.collect(Metrics.events.AUTH.PROFILE_PJ_SEGMENTATION);
        break;
      case 'Profile3':
        Metrics.collect(Metrics.events.AUTH.PROFILE_PJ_TERMS);
        break;
      case 'Profile4':
        Metrics.collect(Metrics.events.AUTH.PROFILE_FINISH);
        ReactPixel.track('CompleteRegistration', { segment: segment || classification });
        LinkedInTag.track('4592281');
        gtag('event', 'conversion', { send_to: 'AW-529361823/qh7eCIWuvvsBEJ_XtfwB' });
        break;
      case 'Profile2_2':
        Metrics.collect(Metrics.events.AUTH.PROFILE_PF_DATA);
        break;
      case 'Profile2_3':
        Metrics.collect(Metrics.events.AUTH.PROFILE_PF_SEGMENTATION);
        break;
      case 'Profile3_3':
        Metrics.collect(Metrics.events.AUTH.PROFILE_PF_TERMS);
        break;
      default:
        break;
    }
  }, [activePage, segment, classification]);

  const handleGoogleLogin = googleResponse => {
    const { tokenId, profileObj } = googleResponse;
    const { givenName, familyName, email: googleEmail, googleId } = profileObj;

    dispatch(googleLoginAction(givenName, familyName, googleEmail, googleId, tokenId, null));
  };

  const handleLoginEmail = () => {
    setLoading(true);
    loginWebClient.post('/user/checkEmail', { email }).then(res => {
      if (!res.data) return;

      if (res.data.exists && !res.data.status) {
        setError(null);
        setActivePage('LoginPassword');
      } else if (res.data.status === 'USER_DOESNT_EXISTS') {
        setError(
          <div>
            Essa conta não existe.{' '}
            <InlineButton
              onClick={() => {
                setError(null);
                setActivePage('Create3');
              }}
            >
              Criar conta?
            </InlineButton>
          </div>,
        );
      } else if (res.data.status === 'USER_GOOGLE_LOGIN') {
        setError('GOOGLE');
        setActivePage('LoginPassword');
      } else if (res.data.status === 'EMAIL_NOT_CONFIRMED') {
        setError(null);
        setActivePage('LoginPassword');
      } else if (res.data.status === 'USER_FACEBOOK_LOGIN') {
        setError(null);
        setActivePage('LoginPassword');
      }

      setLoading(false);
    });
  };

  const handleLoginPassword = () => {
    dispatch(loginAction(email, password, keepConnected));
  };

  const handlePasswordChange = () => {
    if (password.length < 4) {
      setError('SHORT_PASSWORD');
      return;
    }

    setLoading(true);

    loginWebClient
      .post('/user/resetPassword', {
        token: recoveryToken,
        password,
        passwordConfirmation: password,
      })
      .then(response => {
        if (response.data.status !== 200) {
          setError('PASSWORD_CHANGE_ERROR');
        }

        setLoading(false);
        setActivePage('ChangePassword5');
      })
      .catch(() => {
        setLoading(false);
        setError('PASSWORD_CHANGE_ERROR');
      });
  };

  const handleCreateEmail = () => {
    if (!emailRegex.test(email)) {
      setError('Insira um endereço de e-mail válido');
      return;
    }

    setLoading(true);
    loginWebClient.post('/user/checkEmailCreation', { email }).then(res => {
      if (!res.data) return;

      if (res.data.exists) {
        setError(
          <div>
            Essa conta já existe.{' '}
            <InlineButton
              onClick={() => {
                setError(null);
                setActivePage('LoginUser');
              }}
            >
              Entrar?
            </InlineButton>
          </div>,
        );
        setLoading(false);
        return;
      }

      if (res.data.did_you_mean) {
        setError(
          <div>
            E-mail inválido. Você quis dizer <span className="auth__em">{res.data.did_you_mean}</span>
          </div>,
        );
        setLoading(false);
        return;
      }

      if (res.data.result === 'undeliverable' || res.data.result === 'unknown') {
        setError(<div>E-mail inválido! Verifique se o e-mail digitado é real.</div>);
        setLoading(false);
        return;
      }

      if (res.data.result === 'risky' && res.data.reason === 'disposable') {
        setError(<div>Esse e-mail não pode ser utilizado.</div>);
        setLoading(false);
        return;
      }

      setError(null);
      setActivePage('Create4');
      setLoading(false);
    });
  };

  const handleCreatePassword = async () => {
    if (password.length < 4) {
      setError('SHORT_PASSWORD');
      return;
    }
    dispatch(userCreateAction(email, password, captchaToken));
    setError(null);
    setActivePage('Create1');
  };

  let activePageProps = null;
  if (activePage === 'LoginUser') {
    activePageProps = {
      title: 'Entrar',
      component: (
        <ContentLoginUser
          email={email}
          error={error}
          onChangeEmail={setEmail}
          onGoogleFinish={handleGoogleLogin}
          onCreateAccount={() => {
            setError(null);
            setActivePage('Create3');
          }}
          onEnter={handleLoginEmail}
        />
      ),
      nextButton: true,
      previousButton: false,
      nextDisabled: !email,
      onNext: handleLoginEmail,
    };
  } else if (activePage === 'LoginPassword') {
    let errorCode = null;
    if (authState.error_code) {
      errorCode = 'PASSWORD';
    }

    activePageProps = {
      title: 'Insira sua senha',
      component: (
        <ContentLoginPassword
          password={password}
          onChangePassword={setPassword}
          keepConected={keepConnected}
          error={error || errorCode}
          onEnter={handleLoginPassword}
          onChangeKeepConnected={setKeepConnected}
          onForgotPassword={() => {
            dispatch(recoveryPasswordAction(email));
            setActivePage('ChangePassword2');
          }}
        />
      ),
      nextButton: true,
      previousButton: true,
      nextDisabled: !password,
      onPrevious: () => {
        setPassword(null);
        setError(null);
        setActivePage('LoginUser');
      },
      onNext: () => handleLoginPassword(),
    };
  } else if (activePage === 'ChangePassword2') {
    activePageProps = {
      title: 'Verifique seu e-mail',
      component: <ContentChangePassword2 />,
      previousButton: true,
      onPrevious: () => setActivePage('LoginUser'),
    };
  } else if (activePage === 'ChangePassword3') {
    let title = 'Identidade confirmada!';
    if (error) title = 'Não foi possível verificar';
    if (recoveryLoading) title = 'Verificando...';

    activePageProps = {
      title,
      component: <ContentChangePassword3 loading={recoveryLoading} error={error} />,
      previousButton: error,
      nextButton: !error && !recoveryLoading,
      onPrevious: () => {
        setError(null);
        history.push('/auth');
        setActivePage('LoginUser');
      },
      onNext: () => setActivePage('ChangePassword4'),
    };
  } else if (activePage === 'ChangePassword4') {
    activePageProps = {
      title: 'Crie uma nova senha',
      component: <ContentChangePassword4 password={password} onChangePassword={setPassword} error={error} />,
      previousButton: true,
      nextButton: true,
      onPrevious: () => {
        setError(null);
        setActivePage('ChangePassword3');
      },
      onNext: () => handlePasswordChange(),
    };
  } else if (activePage === 'ChangePassword5') {
    activePageProps = {
      title: error ? 'Ocorreu um erro' : 'Sucesso!',
      component: <ContentChangePassword5 error={error} />,
      previousButton: true,
      onPrevious: () => {
        setError(false);
        history.push('/auth');
        setActivePage('LoginUser');
      },
    };
  } else if (activePage === 'Create3') {
    activePageProps = {
      title: 'Criar uma conta',
      component: (
        <ContentCreate3
          email={email}
          onChangeEmail={setEmail}
          error={error}
          disableSocialLogin={type === 'PJ'}
          onEnter={handleCreateEmail}
          onGoogleFinish={handleGoogleLogin}
        />
      ),
      previousButton: true,
      nextButton: true,
      nextDisabled: !email,
      onPrevious: () => {
        setError(null);
        setActivePage('LoginUser');
      },
      onNext: handleCreateEmail,
    };
  } else if (activePage === 'Create4') {
    activePageProps = {
      title: 'Crie uma senha',
      component: (
        <ContentCreate4
          password={password}
          onChangePassword={setPassword}
          error={error}
          onEnter={() => handleCreatePassword()}
          onCaptchaChange={setCaptchaToken}
        />
      ),
      previousButton: true,
      nextButton: true,
      nextDisabled: !password || !captchaToken,
      onPrevious: () => {
        setError(null);
        setCaptchaToken(null);
        turnstile.reset();

        setActivePage('Create3');
      },
      onNext: () => handleCreatePassword(),
    };
  } else if (activePage === 'Profile1') {
    let title = 'Identidade confirmada!';
    if (emailConfirmLoading) {
      title = 'Confirmando...';
    } else if (emailConfirmError) {
      title = 'Identidade já confirmada';
    }

    activePageProps = {
      title,
      component: <ContentProfile1 loading={emailConfirmLoading} error={emailConfirmError} />,
      previousButton: true,
      onPrevious: () => {
        history.push('/auth');
        setActivePage('LoginUser');
      },
      nextButton: false,
    };
  } else if (activePage === 'Create1') {
    activePageProps = {
      title: 'Tipo da conta',
      component: <ContentCreate1 type={type} onChangeType={setType} onBestOption={() => setActivePage('Create2')} />,
      previousButton: true,
      nextButton: true,
      onPrevious: () => {
        setError(false);
        setActivePage('Profile1');
      },
      onNext: () => {
        setError(false);

        if (type === 'PJ') {
          setActivePage('Profile2');
        } else {
          setActivePage('Profile2_2');
        }
      },
    };
  } else if (activePage === 'Create2') {
    activePageProps = {
      title: null,
      component: <ContentCreate2 />,
      previousButton: true,
      onPrevious: () => {
        setActivePage('Create1');
      },
    };
  } else if (activePage === 'Profile2') {
    activePageProps = {
      title: 'Complete o seu perfil',
      height: 530,
      component: (
        <ContentProfile2
          razaoSocial={name}
          cnpj={document}
          cnpjError={cnpjError}
          phone={telephone}
          onChangeRazaoSocial={setName}
          onChangeCNPJ={setDocument}
          onChangePhone={setTelephone}
        />
      ),
      previousButton: true,
      onPrevious: () => setActivePage('Create1'),
      nextButton: true,
      nextDisabled: !(name && document && document.length === 18),
      onNext: () => {
        if (!checkCNPJ(document)) {
          setCnpjError(true);
          return;
        }

        setCnpjError(false);
        setActivePage('Profile2_1');
      },
    };
  } else if (activePage === 'Profile2_1') {
    activePageProps = {
      title: 'Sobre a Empresa',
      height: 530,
      component: <ContentProfile2e1 onChangeSegment={setSegment} />,
      previousButton: true,
      onPrevious: () => setActivePage('Profile2'),
      nextButton: true,
      nextDisabled: !segment,
      onNext: () => setActivePage('Profile3'),
    };
  } else if (activePage === 'Profile3') {
    activePageProps = {
      title: 'Leia atentamente',
      component: <ContentProfile3 terms={terms} error={termsError} onChangeTerms={setTerms} onTerms={() => setTermsOpen(true)} />,
      previousButton: true,
      onPrevious: () => setActivePage('Profile2_1'),
      nextButton: true,
      onNext: () => {
        if (!terms) {
          setTermsError(true);
          return;
        }

        setActivePage('Profile4');
      },
    };
  } else if (activePage === 'Profile4') {
    activePageProps = {
      title: 'Tudo pronto',
      component: <ContentProfile4 />,
      nextButton: true,
      nextLabel: 'Iniciar',
      onNext: () => {
        setLoading(true);

        let cleanDocument = null;
        if (document) {
          cleanDocument = document.replace(/[^0-9]+/g, '');
        }

        dispatch(
          userProfileCreateAction(
            authState.user.id,
            type,
            pilot,
            pilot ? pilotProfessional : false,
            name,
            surname,
            telephone[0],
            telephone[1],
            pilot ? airship : null,
            pilot ? segment : null,
            !pilot ? classification : null,
            cleanDocument,
            terms,
          ),
        );
      },
    };
  } else if (activePage === 'Profile2_2') {
    activePageProps = {
      title: 'Complete o seu perfil',
      component: (
        <ContentProfile2e2
          name={name}
          surname={surname}
          phone={telephone}
          onChangeName={setName}
          onChangeSurname={setSurname}
          onChangePhone={setTelephone}
        />
      ),
      previousButton: true,
      onPrevious: () => setActivePage('Create1'),
      nextButton: true,
      nextDisabled: !(name && surname),
      onNext: () => setActivePage('Profile2_3'),
    };
  } else if (activePage === 'Profile2_3') {
    activePageProps = {
      height: 540,
      component: (
        <ContentProfile2e3
          pilot={pilot}
          pilotProfessional={pilotProfessional}
          onChangePilot={setPilot}
          onChangePilotProfessional={setPilotProfessional}
          onChangeAirship={setAirship}
          onChangeSegment={setSegment}
          onChangeClassification={setClassification}
        />
      ),
      previousButton: true,
      onPrevious: () => setActivePage('Profile2_2'),
      nextButton: true,
      nextDisabled: !((pilot && airship && segment) || (!pilot && classification)),
      onNext: () => setActivePage('Profile3_3'),
    };
  } else if (activePage === 'Profile3_3') {
    activePageProps = {
      title: 'Leia atentamente',
      component: <ContentProfile3e3 terms={terms} error={termsError} onChangeTerms={setTerms} onTerms={() => setTermsOpen(true)} />,
      previousButton: true,
      onPrevious: () => setActivePage('Profile2_3'),
      nextButton: true,
      onNext: () => {
        if (!terms) {
          setTermsError(true);
          return;
        }

        setActivePage('Profile4');
      },
    };
  }

  let AuthContainer = null;
  if (activePageProps) {
    AuthContainer = (
      <MainContainer
        loading={authState.loading || loading}
        title={activePageProps.title}
        height={activePageProps.height}
        nextButton={activePageProps.nextButton}
        nextLabel={activePageProps.nextLabel}
        nextDisabled={activePageProps.nextDisabled}
        previousButton={activePageProps.previousButton}
        previousLabel={activePageProps.previousLabel}
        previousDisabled={activePageProps.previousDisabled}
        onNext={activePageProps.onNext}
        onPrevious={activePageProps.onPrevious}
        logo
      >
        {activePageProps.component}
      </MainContainer>
    );
  }

  const AuthInfoContainer = useCallback(() => {
    if (errors) return <InfoContainer errors={errors} onClose={() => setErrors(null)} />;
    return null;
  }, [errors]);

  return (
    <div className="auth__base">
      <TermsModal open={termsOpen} onClose={() => setTermsOpen(false)} />
      <AuthScreen mainContainer={AuthContainer} infoContainer={AuthInfoContainer()} onTerms={() => setTermsOpen(true)} />
    </div>
  );
};

AuthPage.propTypes = {
  location: ReactRouterPropTypes.location.isRequired,
  history: ReactRouterPropTypes.history.isRequired,
};

export default AuthPage;
