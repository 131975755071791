import React from 'react';

import Button from 'react-uwp/Button';
import CustomScrollbar from 'components/CustomScrollbar/CustomScrollbar';

import './ConfigsDependantStart.styles.scss';

export default props => {
  const { user, userProfile, loading, onCheck } = props;

  const managerEmail = user.manager && user.manager.email;

  return (
    <div className="configs__base">
      <div className="configs__title">CONTA DEPENDENTE</div>
      <CustomScrollbar className="configs__scroll">
        <div className="configs__container">
          <div className="configs__container__session">
            <p className="configs__container__paragraph">Olá, {userProfile.name}</p>
            <p className="configs__container__paragraph">A assinatura da sua conta passou a ser gerenciada por {managerEmail}.</p>
            <p className="configs__container__paragraph">Bons voos!</p>
            <div className="configs__trial-check__button">
              <Button tooltip="Entrar na NexAtlas" className="user-options__logout__button" onClick={onCheck} disabled={loading}>
                Entrar
              </Button>
            </div>
          </div>
        </div>
      </CustomScrollbar>
      <div className="configs__bottom">
        Não é {userProfile.name}?{' '}
        <div className="configs__link" onClick={props.onLogout}>
          Entre com outra conta
        </div>
      </div>
    </div>
  );
};
