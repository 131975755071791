import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import Drawer from 'pages/MapMain/components/Drawer/Drawer';

export const MainDrawerContext = React.createContext();

export const Provider = props => {
  const history = useHistory();

  const [drawerOpen, setDrawerOpen] = useState();
  const [drawerRoute, setDrawerRoute] = useState(`/${history.location.pathname.replace(/\/dashboard\/?/, '').split('/')[0]}`);
  const [drawerDisabled, setDrawerDisabled] = useState(false);

  useEffect(() => {
    if (drawerDisabled) {
      setDrawerRoute('/configs');
    }
  }, [drawerDisabled]);

  const value = {
    open: drawerOpen,
    openDrawer: () => setDrawerOpen(true),
    closeDrawer: () => setDrawerOpen(false),
    toggleDrawer: () => setDrawerOpen(!drawerOpen),
    disableDrawer: () => setDrawerDisabled(true),
    drawerRoute,
    setDrawerRoute: drawerRoute => {
      history.push(`/dashboard${drawerRoute}`);
      setDrawerRoute(drawerRoute);
      setDrawerOpen(false);
    },
  };

  return (
    <MainDrawerContext.Provider value={value}>
      <Drawer drawer={value} disabled={drawerDisabled} />
      {props.children}
    </MainDrawerContext.Provider>
  );
};

export const withDrawer = Component => {
  return props => (
    <Provider>
      <MainDrawerContext.Consumer>{value => <Component drawer={value} {...props} />}</MainDrawerContext.Consumer>
    </Provider>
  );
};
