import React, { Component } from 'react';

import CloseIcon from 'assets/icons/barras/close.svg';
import QrCode from 'assets/icons/barras/qrcode-download-dark.svg';

import './AppModalDesktop.style.scss';

class AppModalDesktop extends Component {
  render() {
    const { onClose } = this.props;

    return (
      <div className="app-modal-desktop" onClick={onClose}>
        <div className="app-modal-desktop__content">
          <div className="app-modal-desktop__content__header">
            <div className="app-modal-desktop__content__header__box" />
            <div className="app-modal-desktop__content__header__close" onClick={onClose}>
              <img src={CloseIcon} alt="X" />
            </div>
          </div>
          <div className="app-modal-desktop__content__title__container">
            <h1>Baixe o aplicativo:</h1>
          </div>
          <div className="app-modal-desktop__content__body">
            <div className="app-modal-desktop__content__body__box">
              <img src={QrCode} alt="qr-code" />
            </div>
            <div className="app-modal-desktop__content__body__text">
              <p>1. Abra a câmera ou o leitor de QR codes de seu tablet ou telefone;</p>
              <p>2. Alinhe o QR code na moldura da câmera. Você verá uma notificação quando o código for lido;</p>
              <p>3. Clique na notificação para abrir o link para download.</p>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default AppModalDesktop;
