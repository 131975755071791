import { Component } from 'react';
import { connect } from 'react-redux';

import { refreshUser } from 'redux/actions/authActions';

import moment from 'moment';

import { getUserFlag } from 'utils/user';

export const STATUS = {
  OK: 1,
  TRIAL_CHECK: 2,
  TRIAL_ENDED: 3,
  SUBSCRIPTION_FINISHED: 4,
  SUBSCRIPTION_FINISHED_INTRIAL: 5,
  NEED_PAYMENT: 6,
  DEPENDANT_START: 7,
  DEPENDANT_END: 8,
};

class CheckPayment extends Component {
  state = {
    userRefreshed: false,
    status: null,
  };

  componentDidMount() {
    this.props.refreshUser().then(response => {
      this.setState({ userRefreshed: true });
    });
  }

  componentDidUpdate() {
    if (this.state.userRefreshed) {
      this.checkAccountStatus();
    }
  }

  checkAccountStatus = () => {
    const { status } = this.state;
    const caseStatus = this.checkCaseStatus();

    if (status !== caseStatus) {
      this.setState({ status: caseStatus });
    }
  };

  checkCaseStatus = () => {
    const { user } = this.props;

    // Flags
    const flagDependantStart = getUserFlag('MESSAGE_DEPENDANT_START');
    if (flagDependantStart === 'SHOW') {
      return STATUS.DEPENDANT_START;
    }

    const flagDependantEnd = getUserFlag('MESSAGE_DEPENDANT_END');
    if (flagDependantEnd === 'SHOW') {
      return STATUS.DEPENDANT_END;
    }

    // Manager
    if (user.manager && user.manager.id) {
      const { manager } = user;

      if (
        (manager.subscription_status === 'unpaid' || manager.subscription_status === 'canceled' || manager.subscription_status === 'ended') &&
        (moment(manager.subscription_period_end) < moment() || manager.subscription_period_end === '0000-00-00 00:00:00')
      ) {
        return STATUS.NEED_PAYMENT;
      }

      return STATUS.OK;
    }

    if (!user.trial_checked) {
      return STATUS.TRIAL_CHECK;
    }

    if (moment(user.trial_to) < moment() && !user.subscription_id) {
      return STATUS.TRIAL_ENDED;
    }

    if (user.subscription_id && !user.subscription_checked && moment(user.trial_to) > moment()) {
      return STATUS.SUBSCRIPTION_FINISHED_INTRIAL;
    }

    if (user.subscription_id && !user.subscription_checked) {
      return STATUS.SUBSCRIPTION_FINISHED;
    }

    if (
      (user.subscription_status === 'unpaid' || user.subscription_status === 'canceled' || user.subscription_status === 'ended') &&
      (moment(user.subscription_period_end) < moment() || user.subscription_period_end === '0000-00-00 00:00:00')
    ) {
      return STATUS.NEED_PAYMENT;
    }

    return STATUS.OK;
  };

  render() {
    const { status } = this.state;

    if (!status) {
      return null;
    }

    return this.props.children({ status });
  }
}

const mapStateToProps = (state, props) => ({
  user: state.auth.user,
});

const mapDispatchToProps = {
  refreshUser,
};

export default connect(mapStateToProps, mapDispatchToProps)(CheckPayment);
