import { numberToFixedLength } from '../../../../utils/string';

/**
 * X Plane codes:
 *  1: airport
 *  2: NDB
 *  3: VOR
 *  11: Named Fix
 *  28: Unamed lat/log waypoints
 *
 * Waypoints Type Codes as indexes
 *  1: Aerodrome (AD)
 *  2: City (CITY)
 *  3: Geographic Coordinate (COORDS)
 *  4: Fix (FIX)
 *  5: Heliport (HELPN)
 *  6: Non-directional Beacon (NDB)
 *  7: VHF Omnidirectional Range (VOR)
 *  8: VHF Omnidirectional Range with Distance Measuring (VOR/DME)
 *  9: Visual Position (PV)
 */
const CODES = [0, 1, 28, 28, 11, 1, 2, 3, 3, 28];

/**
 *
 * @param {{
 *  latitude: number;
 *  longitude: number;
 * }} point
 * @return {string}
 */
function getWaypointName(point) {
  const [latInteger, latDecimal] = Math.abs(point.latitude)
    .toString()
    .split('.');
  const [lonInteger, lonDecimal] = Math.abs(point.longitude)
    .toString()
    .split('.');

  const latitude = `${point.latitude >= 0 ? '+' : '-'}${numberToFixedLength(latInteger, 2, '0')}.${numberToFixedLength(latDecimal, 3, '0')}`;
  const longitude = `${point.longitude >= 0 ? '+' : '-'}${numberToFixedLength(lonInteger, 3, '0')}.${numberToFixedLength(lonDecimal, 3, '0')}`;

  return `${latitude}_${longitude}`;
}

/**
 *
 * @param {{
 *  waypoint_type_id: number;
 *  code: string;
 *  latitude: number;
 *  longitude: number;
 * }[]} waypoints
 * @param {number} version
 * @return {string}
 */
function generateFMS(waypoints, version) {
  const parsedWaypoints = waypoints.map(waypoint => {
    const fmsType = CODES[waypoint.waypoint_type_id];

    return {
      fmsType,
      code: fmsType === 28 ? getWaypointName(waypoint) : waypoint.code,
      latitude: waypoint.latitude.toFixed(6),
      longitude: waypoint.longitude.toFixed(6),
    };
  });

  if (version === 11) {
    const firstWaypoint = parsedWaypoints[0];
    const lastWaypoint = parsedWaypoints[parsedWaypoints.length - 1];

    const firstRouteType = firstWaypoint.fmsType === 1 ? 'ADEP' : 'DEP';
    const lastRouteType = lastWaypoint.fmsType === 1 ? 'ADES' : 'DES';

    const departure = `${firstRouteType} ${firstWaypoint.code}`;
    const destination = `${lastRouteType} ${lastWaypoint.code}`;

    const FMSWaypoints = parsedWaypoints.reduce((total, waypoint, index) => {
      let routeCode;

      if (index === 0) {
        routeCode = firstRouteType;
      } else if (index === parsedWaypoints.length - 1) {
        routeCode = lastRouteType;
      } else {
        routeCode = 'DRCT';
      }

      return `${total}\n${waypoint.fmsType} ${waypoint.code} ${routeCode} 0 ${waypoint.latitude} ${waypoint.longitude}`;
    }, '');

    const FMS = `I\n1100 Version\nCYCLE 2009\n${departure}\n${destination}\nNUMENR ${parsedWaypoints.length}${FMSWaypoints}`;

    return FMS;
  }

  const parsedPoints = parsedWaypoints.reduce(
    (total, waypoint) => `${total}\n${waypoint.fmsType} ${waypoint.code} 0 ${waypoint.latitude} ${waypoint.longitude}`,
    '',
  );

  const FMS = `I\n3 version\n1\n${parsedWaypoints.length}${parsedPoints}`;

  return FMS;
}

export default generateFMS;
