import React, { useState } from 'react';
import { useDispatch } from 'react-redux';

import parse from 'html-react-parser';
import moment from 'moment';

import ConfirmModal from 'components/ConfirmModal/ConfirmModal';

import { apiWebClient } from 'services/webclient';
import { updateLocalUser, setUserFlag } from 'redux/actions/authActions';

import metrics from '../../../../../../utils/metrics';

import './ConfigsMain.style.scss';
import InlineButton from 'components/form/InlineButton/InlineButton';

export default props => {
  const {
    user,
    userProfile,
    userPlan,
    lastUpdate,
    onChangePassword,
    onLogout,
    onMoreInfo,
    onUserWaypoints,
    onTerms,
    onManageDependants,
    onChangeSubscription,
    onCancelSubscription,
  } = props;

  const dispatch = useDispatch();

  const [changePasswordConfirm, setChangePasswordConfirm] = useState(false);
  const [removeFromDependantConfirm, setRemoveFromDependantConfirm] = useState(false);

  const removeFromDependant = () => {
    apiWebClient
      .post('/user/removeFromDependant', {})
      .then(response => {
        const { data } = response;

        if (data.user) {
          dispatch(updateLocalUser(data.user));
        }

        document.location.reload();
      })
      .catch(error => { });
  };

  const isTrial = user.trial_to && moment(user.trial_to) > moment() && user.subscription_status === '';
  let subscriptionType = '';
  let paymentMethod = '';
  let manageSubscriptionText = 'Modificar algo na assinatura';

  if (user.manager_id && user.manager) {
    if (user.manager.subscription_status === 'paid') {
      subscriptionType = <p>Conta dependente de {user.manager && user.manager.email}</p>;
    } else if (user.manager.subscription_status === 'canceled') {
      subscriptionType = (
        <p className="configs__main__warning">Conta gestora ({user.manager && user.manager.email}) está com a assinatura cancelada.</p>
      );
    } else {
      subscriptionType = (
        <p className="configs__main__warning">Conta gestora ({user.manager && user.manager.email}) está com o pagamento pendente.</p>
      );
    }
  } else if (isTrial) {
    subscriptionType = <p>Período de avaliação</p>;
  } else if (user.subscription_status === 'paid') {
    subscriptionType = <p>Assinatura ativa</p>;
  } else if (user.subscription_status === 'pending_payment') {
    subscriptionType = <p className="configs__main__warning">Aguardando o pagamento</p>;
  } else if (user.subscription_status === 'unpaid') {
    subscriptionType = <p className="configs__main__warning">Pagamento pendente. Por favor, realize o pagamento ou altere a forma de pagamento.</p>;
  } else if (user.subscription_status === 'canceled') {
    subscriptionType = <p className="configs__main__warning">Assinatura cancelada</p>;
  } else if (user.subscription_status === 'ended') {
    subscriptionType = <p className="configs__main__warning">O tempo do seu plano encerrou! Para continuar usando, basta clicar no botão abaixo.</p>;
  }

  if (user.subscription_status !== 'canceled' && user.subscription_status !== 'ended' && !user.manager_id) {
    if (user.subscription_method === 'credit_card') {
      paymentMethod = <p>Método: Cartão de Crédito final {user.subscription_card_last}</p>;
    } else if (user.subscription_method === 'boleto') {
      paymentMethod = <p>Método: Boleto bancário</p>;
    }
  }

  if (isTrial) {
    manageSubscriptionText = 'Assine já';
  } else if (user.subscription_status === 'canceled' || user.subscription_status === 'ended') {
    manageSubscriptionText = 'Criar nova assinatura';
  }

  return (
    <div className="configs">
      <div className="configs__main__base">
        <ConfirmModal
          message="Deseja realmente alterar a senha?"
          open={changePasswordConfirm}
          width="280px"
          onYes={() => {
            onChangePassword();
            metrics.collect(metrics.events.DRAWER.CONFIGURACOES.ALTERAR_SENHA.DIALOG.SIM);
          }}
          onCancel={() => metrics.collect(metrics.events.DRAWER.CONFIGURACOES.ALTERAR_SENHA.DIALOG.CANCELAR)}
          onFinish={() => setChangePasswordConfirm(false)}
        />

        <ConfirmModal
          message="Deseja realmente deixar de ser dependente?"
          open={removeFromDependantConfirm}
          width="280px"
          onYes={() => {
            removeFromDependant();
            metrics.collect(metrics.events.DRAWER.CONFIGURACOES.DEIXAR_SER_DEPENDENTE.DIALOG.SIM);
          }}
          onCancel={() => metrics.collect(metrics.events.DRAWER.CONFIGURACOES.DEIXAR_SER_DEPENDENTE.DIALOG.CANCELAR)}
          onFinish={() => setRemoveFromDependantConfirm(false)}
        />

        <div className="configs__title">CONFIGURAÇÕES</div>
        <div className="configs__container">
          <div className="configs__container__session">
            <div className="configs__container__paragraph">
              <div className="configs__main__title">CONTA</div>
              <div className="configs__main__text">{userProfile && `${userProfile.name} ${userProfile.surname}`}</div>
              <div className="configs__main__text">{user && user.email}</div>
              <div
                className="configs__main__link"
                onClick={async () => {
                  await dispatch(setUserFlag('TOUR_DONE', 'false'));
                  await metrics.collect(metrics.events.DRAWER.CONFIGURACOES.REFAZER_TOUR);
                  window.location.href = '/dashboard';
                }}
              >
                Refazer tour
              </div>
              <div
                className="configs__main__link"
                onClick={() => {
                  setChangePasswordConfirm(true);
                  metrics.collect(metrics.events.DRAWER.CONFIGURACOES.ALTERAR_SENHA.THIS);
                }}
              >
                Alterar Senha
              </div>
              <div
                className="configs__main__link"
                onClick={() => {
                  onLogout();
                  metrics.collect(metrics.events.DRAWER.CONFIGURACOES.SAIR);
                }}
              >
                Sair
              </div>
            </div>
          </div>
          <hr />
          <div className="configs__container__session">
            <div className="configs__container__paragraph">
              <div className="configs__main__title">PERSONALIZAÇÃO</div>
              <div
                className="configs__main__link"
                onClick={() => {
                  onUserWaypoints();
                }}
              >
                Pontos do usuário
              </div>
            </div>
          </div>
          <hr />
          <div className="configs__container__session">
            <h2>ASSINATURA</h2>
            {subscriptionType}
            <p>{user.subscription_status !== 'canceled' && user.subscription_status !== 'ended' && userPlan && parse(userPlan.text)}</p>
            {paymentMethod}

            {isTrial && user.trial_to && <p>Fim da avaliação: {moment(user.trial_to).format('DD/MM/YYYY')}</p>}

            {!isTrial &&
              user.subscription_status !== 'canceled' &&
              user.subscription_status !== 'ended' &&
              user.subscription_period_end &&
              user.subscription_period_end !== '0000-00-00 00:00:00' && (
                <p>Próxima cobrança: {moment(user.subscription_period_end).format('DD/MM/YYYY')}</p>
              )}

            {user.subscription_status === 'canceled' && user.subscription_period_end && user.subscription_period_end !== '0000-00-00 00:00:00' && (
              <p>Acesso até: {moment(user.subscription_period_end).format('DD/MM/YYYY')}</p>
            )}

            {!isTrial &&
              user.subscription_status !== 'canceled' &&
              user.subscription_status !== 'ended' &&
              user.subscription_method === 'boleto' &&
              user.subscription_period_end &&
              user.subscription_period_end === '0000-00-00 00:00:00' && (
                <div>
                  <p>Aguardando pagamento do boleto</p>
                  {user.subscription_boleto_url && (
                    <div
                      className="configs__container__link"
                      onClick={() => {
                        window.open(user.subscription_boleto_url, '_blank');
                        metrics.collect(metrics.events.DRAWER.CONFIGURACOES.SEG_VIA_BOLETO);
                      }}
                    >
                      Gerar 2ª via do boleto
                    </div>
                  )}
                </div>
              )}

            {user.subscription_status === 'unpaid' && user.subscription_method === 'boleto' && user.subscription_boleto_url && (
              <div
                className="configs__container__link"
                onClick={() => {
                  window.open(user.subscription_boleto_url, '_blank');
                  metrics.collect(metrics.events.DRAWER.CONFIGURACOES.SEG_VIA_BOLETO);
                }}
              >
                Gerar 2ª via do boleto
              </div>
            )}

            {!user.manager_id && (
              <div
                className="configs__container__link"
                onClick={() => {
                  onChangeSubscription();
                  metrics.collect(metrics.events.DRAWER.CONFIGURACOES.ASSINE_JA.THIS);
                }}
              >
                <p>{manageSubscriptionText}</p>
              </div>
            )}

            {user.manager_id && user.manager && (
              <div
                className="configs__container__link"
                onClick={() => {
                  setRemoveFromDependantConfirm(true);
                  metrics.collect(metrics.events.DRAWER.CONFIGURACOES.DEIXAR_SER_DEPENDENTE.THIS);
                }}
              >
                Deixar de ser dependente
              </div>
            )}

            {((user.plan && user.plan.dependants > 0) || (user?.subscription_special_dependants ?? 0) > 0) && (
              <div
                className="configs__container__link"
                onClick={() => {
                  onManageDependants();
                  metrics.collect(metrics.events.DRAWER.CONFIGURACOES.GERENCIAR_DEPENDENTES);
                }}
              >
                Gerenciar dependentes
              </div>
            )}

            {!isTrial && user.subscription_status !== 'canceled' && user.subscription_status !== 'ended' && !user.manager_id && (
              <div
                className="configs__container__link"
                onClick={() => {
                  onCancelSubscription();
                  metrics.collect(metrics.events.DRAWER.CONFIGURACOES.CANCELAR_ASS.THIS);
                }}
              >
                Cancelar assinatura
              </div>
            )}
          </div>
          <hr />
          <div className="configs__container__session">
            <h2>DADOS E CARTAS</h2>
            <p>
              <em>Atualizados até</em>
              {lastUpdate && ` ${moment(lastUpdate.valid_to).format('DD-MM-YYYY')}`}
            </p>
            <div
              className="configs__container__link"
              onClick={() => {
                onMoreInfo();
                metrics.collect(metrics.events.DRAWER.CONFIGURACOES.MAIS_INFORMACOES);
              }}
            >
              Mais informações
            </div>
          </div>
          <hr />
          <div className="configs__container__session">
            <h2>SOBRE ESTE APLICATIVO</h2>
            <p>NexAtlas WebApp</p>
            <p>&copy; 2019 NexAtlas - 23.958.431/0001-60</p>
            <p>contato@nexatlas.com</p>
            <div
              className="configs__container__link"
              onClick={() => {
                metrics.collect(metrics.events.DRAWER.CONFIGURACOES.TERMOS);
              }}
            >
              <ul>
                <li>
                  <InlineButton link={'https://nexatlas.com/termos-de-uso'}>Termos de Uso</InlineButton>
                </li>
                <li>
                  <InlineButton link={'https://nexatlas.com/politica-de-privacidade'}>Política de Privacidade</InlineButton>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
