import React from 'react';

import moment from 'moment';

import metrics from 'utils/metrics';
import { flightTime, millisecondsToHourMinute } from 'utils/map';

import IconTrash from '../../assets/icon_trash.svg';

import './RoutesListItem.style.scss';

const RoutesListItem = props => {
  const { route, onItemClick, onItemDelete } = props;

  const routeDistance = route.total_distance || 0;
  const routeTime = flightTime(routeDistance, route.ground_speed);
  const routeDuration = moment.duration(routeTime, 'h');

  return (
    <div className="routes-list-item" onClick={() => onItemClick(route.id)}>
      <div className="routes-list-item__container-info">
        <div className="routes-list-item__container-info__title">{route.name}</div>
        <div className="routes-list-item__container-info__details">
          <div className="routes-list-item__container-info__details__item">
            <span className="routes-list-item__container-info__details__item__title routes-list-item__container-info__details__item__title__distance">
              DISTÂNCIA
            </span>
            <div className="routes-list-item__container-info__details__item__distance-info">
              <span className="routes-list-item__container-info__details__item__value">{routeDistance.toFixed(1)}</span>
              <span className="routes-list-item__container-info__details__item__unit">nm</span>
            </div>
          </div>
          <div className="routes-list-item__container-info__details__item">
            <span className="routes-list-item__container-info__details__item__title  routes-list-item__container-info__details__item__title__flight-time">
              TEMPO DE VOO
            </span>
            <span className="routes-list-item__container-info__details__item__value">
              {routeTime ? millisecondsToHourMinute(routeDuration.as('milliseconds')) : '--'} ({route.ground_speed}kt)
            </span>
          </div>
          <div className="routes-list-item__container-info__details__item">
            <span className="routes-list-item__container-info__details__item__title">ATUALIZADO EM</span>
            <span className="routes-list-item__container-info__details__item__value">{moment(route.updated_on * 1000).format('DD/MM/YYYY')}</span>
          </div>
        </div>
      </div>
      <div className="routes-list-item__container-right">
        <img
          src={IconTrash}
          alt=""
          onClick={e => {
            onItemDelete(route.id);
            e.stopPropagation();
            metrics.collect(metrics.events.DRAWER.ROTAS_SALVAS.ROTA_EXCLUIR.THIS);
          }}
        />
      </div>
    </div>
  );
};

export default RoutesListItem;
