import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';

import { searchWaypoints } from 'redux/selectors/waypoint';

import { useTourContext } from 'context/TourContext/TourContext';

import metrics from '../../../../utils/metrics';

import { MapSearchItem } from '../MapSearch/MapSearch';
import './WaypointInputSearch.style.scss';

const WaypointInputSearchBox = props => {
  const { search, searchResults, show } = props;

  const [isVisible, setVisible] = useState(false);

  const tourContext = useTourContext();

  useEffect(() => {
    const componentShouldShow = searchResults && show;
    setVisible(componentShouldShow);

    if (componentShouldShow) {
      tourContext.dispatchTourCondition(metrics.events.MENU_PESQUISA.THIS, searchResults.length > 0);
    } else {
      tourContext.dispatchTourCondition(metrics.events.MENU_PESQUISA.THIS, false);
    }
  }, [searchResults, show, tourContext]);

  const handleAdd = item => {
    props.onAdd(item);
    metrics.collect(metrics.events.MENU_PESQUISA.WPT.ADICIONAR, item.code || item.name);
    tourContext.dispatchTourClick(metrics.events.MENU_PESQUISA.WPT.ADICIONAR);
  };

  return isVisible ? (
    <div>
      <div className="map__input-search__box">
        {searchResults && searchResults.length === 0 && <div className="map__input-search__coord-search">Sem resultados para a busca!</div>}
        {search.match(/^-?[0-9]{1,}/g) && searchResults && searchResults.length === 0 && (
          <div className="map__input-search__coord-search">Para buscar por coordenada, continue digitando no formato: 000000S 0000000W</div>
        )}

        {searchResults &&
          searchResults.map(result => (
            <MapSearchItem
              item={result}
              key={result.id || 0}
              onLocate={(lat, lng) => {
                props.onLocate(lat, lng);
                metrics.collect(metrics.events.MENU_PESQUISA.WPT.CENTRALIZAR);
              }}
              onAdd={handleAdd}
              onInfo={() => metrics.collect(metrics.events.MENU_PESQUISA.WPT.AISWEB)}
            />
          ))}
      </div>
    </div>
  ) : null;
};
const mapStateToProps = (state, props) => ({
  searchResults: searchWaypoints(state, props),
});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(WaypointInputSearchBox);
