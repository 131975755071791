import React, { useState, useEffect } from 'react';
import './Splash.style.scss';

import NexAtlasIcon from 'assets/nexatlas_icon.png';
import classnames from 'classnames';

const Splash = () => {
  const [showText, setShowText] = useState(false);

  useEffect(() => {
    const timeout = setTimeout(() => {
      setShowText(true);
    }, 5000);

    return () => {
      clearTimeout(timeout);
    };
  });

  return (
    <div className="splash">
      <img src={NexAtlasIcon} alt="NexAtlas" className="splash__icon" />
      <div className={classnames('splash__text', { 'splash__text--active': showText })}>
        Ainda carregando...
        <br />
        Talvez sua conexão com a internet esteja instável
      </div>
    </div>
  );
};

export default Splash;
