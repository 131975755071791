import React, { useState, useMemo, useEffect } from 'react';
import { connect } from 'react-redux';
import { wrapComponent as withSnackbar } from 'react-snackbar-alert';
import moment from 'moment';

import Icon from 'react-uwp/Icon';
import { Button, CheckBox, TextBox } from 'react-uwp';
import { getAllUserWaypoints as getAllUserWaypointsAction } from 'redux/actions/waypointActions';
import { userWaypointsSelector } from 'redux/selectors/waypoint';

import './ConfigsUserWaypoints.style.scss';
import { apiWebClient } from 'services/webclient';
import ConfirmModal from 'components/ConfirmModal/ConfirmModal';

const ConfigsUserWaypoints = props => {
  const { onReturn, onManage, userWaypoints } = props;

  const [searchInputValue, setSearchInputValue] = useState('');
  const [selectedWaypoints, setSelectedWaypoints] = useState([]);

  const [batchDeleteModalOpened, setBatchDeleteModalOpened] = useState(false);

  useEffect(() => {
    props.getAllUserWaypointsAction();
  }, []);

  const filteredWaypoints = useMemo(() => {
    if (searchInputValue === '') return userWaypoints;
    return userWaypoints.filter(item => item.name.toLowerCase().includes(searchInputValue.toLowerCase()));
  }, [userWaypoints, searchInputValue]);

  const handleImport = () => {
    // Prompts for a CSV or KML file and uploads to the server as a multipart form
    const input = document.createElement('input');
    input.type = 'file';
    input.accept = '.csv,.kml';
    input.onchange = async () => {
      const file = input.files[0];
      const formData = new FormData();
      formData.append('file', file);

      const result = await apiWebClient.post('/userWaypoint/importFile', formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });

      if (result?.data?.error) {
        props.createSnackbar({
          message: 'Erro ao importar pontos. Verifique se o arquivo está no formato correto.',
        });
      } else {
        const waypointsLength = result?.data?.waypoints?.length || 0;

        props.createSnackbar({
          message: `${waypointsLength} ponto${waypointsLength > 1 ? 's' : ''} importado${waypointsLength > 1 ? 's' : ''} com sucesso!`,
        });
      }

      props.getAllUserWaypointsAction();
      input.value = null;
    };
    input.click();
  };

  const handleExport = async () => {
    const result = await apiWebClient.post(
      '/userWaypoint/exportFile',
      {
        ids: selectedWaypoints,
      },
      {
        responseType: 'blob',
      },
    );

    if (result?.data?.error) {
      props.createSnackbar({
        message: 'Erro ao exportar pontos. Tente novamente mais tarde.',
      });
    } else {
      const url = window.URL.createObjectURL(new Blob([result.data]));
      const link = document.createElement('a');
      link.href = url;
      const date = moment().format('DD-MM-YYYY-HH-mm-ss');
      link.setAttribute('download', `waypoints-${date}.csv`);
      document.body.appendChild(link);
      link.click();

      props.createSnackbar({
        message: `${selectedWaypoints.length} ponto${selectedWaypoints.length > 1 ? 's' : ''} exportado${
          selectedWaypoints.length > 1 ? 's' : ''
        } com sucesso!`,
      });
    }
  };

  const handleBatchDelete = () => {
    setBatchDeleteModalOpened(true);
  };

  const handleBatchDeleteConfirm = async () => {
    const result = await apiWebClient.post('/userWaypoint/removeBatch', {
      ids: selectedWaypoints,
    });

    if (result?.data?.error) {
      props.createSnackbar({
        message: 'Erro ao excluir pontos. Tente novamente mais tarde.',
      });
    } else {
      props.getAllUserWaypointsAction();

      props.createSnackbar({
        message: `${selectedWaypoints.length} ponto${selectedWaypoints.length > 1 ? 's' : ''} excluído${
          selectedWaypoints.length > 1 ? 's' : ''
        } com sucesso!`,
      });

      setSelectedWaypoints([]);
    }
  };

  return (
    <div className="configs__user-waypoints__base">
      <ConfirmModal
        message={`Tem certeza que deseja excluir ${selectedWaypoints.length} ponto${selectedWaypoints.length > 1 ? 's' : ''} de usuário?`}
        open={batchDeleteModalOpened}
        yesLabel="Excluir"
        cancelLabel="Cancelar"
        onYes={handleBatchDeleteConfirm}
        onCancel={() => setBatchDeleteModalOpened(false)}
        onFinish={() => setBatchDeleteModalOpened(false)}
      />
      <div className="configs__title">
        <Icon onClick={() => onReturn()} className="configs__title__action">
          ChevronLeft
        </Icon>
        PONTOS DO USUÁRIO
      </div>
      <div className="configs__user-waypoints__base__content">
        <div className="configs__user-waypoints__base__content__search">
          <TextBox
            className="configs__user-waypoints__base__content__search__input"
            value={searchInputValue}
            onChange={event => setSearchInputValue(event.target.value)}
            placeholder="Pesquisar"
          />
          <button type="button" className="configs__user-waypoints__base__content__search__button-add" onClick={() => onManage(null)}>
            <Icon>Add</Icon>
          </button>
        </div>
        {filteredWaypoints.length === 0 ? (
          searchInputValue === '' ? (
            <div className="configs__user-waypoints__base__content__empty">Clique no + para criar um ponto personalizado</div>
          ) : (
            <div className="configs__user-waypoints__base__content__empty">Nenhum ponto encontrado</div>
          )
        ) : (
          <div className="configs__user-waypoints__base__content__scroll">
            <div className="configs__user-waypoints__base__content__list__menu-group">
              {filteredWaypoints.map(item => (
                <div
                  className="configs__user-waypoints__base__content__list__menu-group__menu-item"
                  key={item.id}
                  onClick={() => {
                    onManage(item.id);
                  }}
                >
                  <CheckBox
                    defaultChecked={selectedWaypoints.includes(item.id)}
                    value={item.id}
                    onClick={event => {
                      event.stopPropagation();
                    }}
                    onCheck={() => {
                      if (selectedWaypoints.includes(item.id)) {
                        setSelectedWaypoints(selectedWaypoints.filter(id => id !== item.id));
                      } else {
                        setSelectedWaypoints([...selectedWaypoints, item.id]);
                      }
                    }}
                  />
                  <span className="configs__user-waypoints__base__content__list__menu-group__menu-item__text">{item.name}</span>
                  <Icon>ChevronRight</Icon>
                </div>
              ))}
            </div>
          </div>
        )}
        <div className="configs__user-waypoints__base__content__footer">
          {selectedWaypoints.length > 0 && (
            <div className="configs__user-waypoints__base__content__footer__selected">
              <Button onClick={handleExport}>
                Exportar {selectedWaypoints.length} ponto{selectedWaypoints.length > 1 ? 's' : ''}
              </Button>
              <Button className="configs__user-waypoints__base__content__footer__selected__warning" onClick={handleBatchDelete}>
                Excluir {selectedWaypoints.length} ponto{selectedWaypoints.length > 1 ? 's' : ''}
              </Button>
            </div>
          )}
          <div className="configs__main__link" onClick={handleImport}>
            Importar pontos em massa (.csv, .kml)
          </div>
        </div>
      </div>
    </div>
  );
};

const mapDispatch = {
  getAllUserWaypointsAction,
};

const mapStateToProps = (state, props) => ({
  userWaypoints: userWaypointsSelector(state, props),
});

export default connect(mapStateToProps, mapDispatch)(withSnackbar(ConfigsUserWaypoints));
