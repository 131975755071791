import React from 'react';
import PropTypes from 'prop-types';

import classnames from 'classnames';

import './OutlinedButton.style.scss';

const OutlinedButton = props => {
  const { label, filled, disabled, className, onClick } = props;

  return (
    <button
      type="button"
      className={classnames('auth__button', { 'auth__button--filled': filled, 'auth__button--disabled': disabled }, className)}
      onClick={() => {
        if (!disabled) onClick();
      }}
    >
      {label}
    </button>
  );
};

OutlinedButton.defaultProps = {
  filled: false,
  disabled: false,
  className: null,
  onClick: () => {},
};

OutlinedButton.propTypes = {
  label: PropTypes.string.isRequired,
  filled: PropTypes.bool,
  disabled: PropTypes.bool,
  className: PropTypes.string,
  onClick: PropTypes.func,
};

export default OutlinedButton;
