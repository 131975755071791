import { NEWS } from './types';

export function loadNews(page) {
  return {
    type: NEWS.LOAD_COLLECTION,
    payload: {
      client: 'api',
      request: {
        url: `/news/get?page=${page}`,
        method: 'get',
      },
    },
  };
}

export function updateLastNewsId(id) {
  return {
    type: NEWS.UPDATE_LAST_NEWS_ID,
    payload: {
      id,
    },
  };
}
