import React, { useState, useCallback } from 'react';

import Button from 'react-uwp/Button';
import Icon from 'react-uwp/Icon';
import TextBox from 'react-uwp/TextBox';
import Dropdown from 'components/form/Dropdown/Dropdown';
import CustomScrollbar from 'components/CustomScrollbar/CustomScrollbar';

import { maskTypingCPF, maskTypingCNPJ, maskTypingCEP, maskTypingPhone } from 'utils/masks';
import { checkCPF, checkCNPJ } from 'utils/compare';
import metrics from '../../../../../../utils/metrics';

import './ConfigsPaymentData.styles.scss';

const cpfRegex = /^\d{0,11}$/;
const cnpjRegex = /^\d{0,14}$/;
const cepRegex = /^\d{0,8}$/;
const dddRegex = /^\d{0,2}$/;
const phoneRegex = /^\d{0,9}$/;

const cpfFullRegex = /^\d{3}\.\d{3}\.\d{3}-\d{2}$/;
const cnpjFullRegex = /^\d{2}\.\d{3}\.\d{3}\/\d{4}-\d{2}$/;
const cepFullRegex = /^\d{5}-\d{3}$/;
const phoneFullRegex = /^\d{4,5}-\d{4}$/;

export default props => {
  const { values, single, error, onReturn, onFinish } = props;

  const [type, setType] = useState(values && values.cpf && values.cpf.length > 14 ? 'PJ' : 'PF');
  const [name, setName] = useState((values && values.name) || null);
  const [cpf, setCpf] = useState((values && values.cpf) || null);
  const [address, setAddress] = useState((values && values.address) || null);
  const [streetNumber, setStreetNumber] = useState((values && values.streetNumber) || null);
  const [complementary, setComplementary] = useState((values && values.complementary) || null);
  const [neighborhood, setNeighborhood] = useState((values && values.neighborhood) || null);
  const [phoneDdd, setPhoneDdd] = useState((values && values.phoneDdd) || null);
  const [phoneNumber, setPhoneNumber] = useState((values && values.phoneNumber) || null);
  const [cep, setCep] = useState((values && values.cep) || null);
  const [bornAtDay, setBornAtDay] = useState((values && values.bornAtDay) || null);
  const [bornAtMonth, setBornAtMonth] = useState((values && values.bornAtMonth) || null);
  const [bornAtYear, setBornAtYear] = useState((values && values.bornAtYear) || null);

  const [errorCPF, setErrorCPF] = useState(false);

  const _setCpf = useCallback(
    cpf => {
      const _cpf = cpf
        .replace(/\./g, '')
        .replace(/-/g, '')
        .replace(/\//g, '');

      if (type === 'PF') {
        if (_cpf.length > 11) return;

        if (_cpf.match(cpfRegex)) {
          setCpf(maskTypingCPF(_cpf));
        }

        if (_cpf.length >= 11 && !checkCPF(_cpf)) {
          setErrorCPF(true);
        } else {
          setErrorCPF(false);
        }
      } else if (type === 'PJ') {
        if (_cpf.length > 14) return;

        if (_cpf.match(cnpjRegex)) {
          setCpf(maskTypingCNPJ(_cpf));
        }

        if (_cpf.length >= 14 && !checkCNPJ(_cpf)) {
          setErrorCPF(true);
        } else {
          setErrorCPF(false);
        }
      }
    },
    [setCpf, type],
  );

  const _setCep = useCallback(
    cep => {
      const _cep = cep.replace(/-/g, '');

      if (_cep.match(cepRegex)) {
        setCep(maskTypingCEP(_cep));
      }
    },
    [setCep],
  );

  const _setPhoneDdd = useCallback(
    ddd => {
      if (ddd.match(dddRegex)) {
        setPhoneDdd(ddd);
      }
    },
    [setPhoneDdd],
  );

  const _setPhoneNumber = useCallback(
    number => {
      const _number = number.replace(/-/g, '');

      if (_number.match(phoneRegex)) {
        setPhoneNumber(maskTypingPhone(_number));
      }
    },
    [setPhoneNumber],
  );

  const typeItems = [
    { value: 'PF', label: 'Pessoa Física' },
    { value: 'PJ', label: 'Pessoa Jurídica' },
  ];

  return (
    <div className="configs__base">
      <div className="configs__title">
        {!single && (
          <Icon onClick={() => onReturn()} className="configs__title__action">
            ChevronLeft
          </Icon>
        )}
        DADOS PARA A NOTA FISCAL
      </div>
      <CustomScrollbar className="configs__scroll">
        <div className="configs__container">
          <div className="configs__container__session">
            {error === 'INVALID_ZIPCODE' && <div className="configs__payment-data__alert">CEP inválido</div>}

            <div className="configs__payment-data__formItem configs__payment-data__tipo">
              <label>Tipo:</label>
              <Dropdown
                options={typeItems}
                onChange={value => {
                  setType(value.value);
                  setCpf(null);
                  setErrorCPF(null);
                }}
                placeholder=""
                value={typeItems.find(item => item.value === type)}
                className="configs__payment-data__formItem--born-at-day__field"
              />
            </div>
            <div className="configs__payment-data__formItem">
              <label>{type === 'PF' ? 'Nome completo' : 'Razão social'}:</label>
              <TextBox value={name || ''} onChangeValue={value => setName(value)} background="#1e262a" />
            </div>
            <div className="configs__payment-data__formItem">
              <label>{type === 'PF' ? 'CPF' : 'CNPJ'}:</label>
              <TextBox value={cpf || ''} onChangeValue={value => _setCpf(value)} background="#1e262a" />
              {errorCPF && <div className="configs__payment-data__cpf-error">{type === 'PF' ? 'CPF inválido' : 'CNPJ inválido'}</div>}
            </div>

            <div className="configs__payment-data__formItem configs__payment-data__formItem--address-line">
              <div className="configs__payment-data__formItem--address">
                <label>Endereço:</label>
                <TextBox
                  value={address || ''}
                  onChangeValue={value => setAddress(value)}
                  background="#1e262a"
                  className="configs__payment-data__formItem--address__field"
                />
              </div>
              <div className="configs__payment-data__formItem--street-number">
                <label>Número:</label>
                <TextBox
                  value={streetNumber || ''}
                  onChangeValue={value => setStreetNumber(value)}
                  background="#1e262a"
                  className="configs__payment-data__formItem--street-number__field"
                />
              </div>
            </div>

            <div className="configs__payment-data__formItem">
              <label>Complemento:</label>
              <TextBox value={complementary || ''} onChangeValue={value => setComplementary(value)} background="#1e262a" />
            </div>

            <div className="configs__payment-data__formItem">
              <label>Bairro:</label>
              <TextBox value={neighborhood || ''} onChangeValue={value => setNeighborhood(value)} background="#1e262a" />
            </div>

            <div className="configs__payment-data__formItem">
              <label>CEP:</label>
              <TextBox value={cep || ''} onChangeValue={value => _setCep(value)} background="#1e262a" />
            </div>

            <div className="configs__payment-data__formItem configs__payment-data__formItem--phone-line">
              <div className="configs__payment-data__formItem--phone-ddd">
                <label>{type === 'PF' ? 'Celular' : 'Telefone'}:</label>
                <TextBox
                  value={phoneDdd || ''}
                  onChangeValue={value => _setPhoneDdd(value)}
                  background="#1e262a"
                  className="configs__payment-data__formItem--phone-ddd__field"
                />
              </div>
              <div className="configs__payment-data__formItem--phone-number">
                <label>&nbsp;</label>
                <TextBox
                  value={phoneNumber || ''}
                  onChangeValue={value => _setPhoneNumber(value)}
                  background="#1e262a"
                  className="configs__payment-data__formItem--phone-number__field"
                />
              </div>
            </div>

            {type === 'PF' && (
              <div className="configs__payment-data__formItem">
                <label>Data de nascimento:</label>
                <div className="configs__payment-data__formItem--born-at-line">
                  <div className="configs__payment-data__formItem--born-at-day">
                    <Dropdown
                      options={[...Array(31).keys()].map(i => ({
                        value: `000000000${i + 1}`.substr(-2),
                        label: `000000000${i + 1}`.substr(-2),
                      }))}
                      onChange={value => setBornAtDay(value)}
                      placeholder=""
                      value={bornAtDay}
                      className="configs__payment-data__formItem--born-at-day__field"
                    />
                  </div>
                  <div className="configs__payment-data__formItem--born-at-month">
                    <Dropdown
                      options={[...Array(12).keys()].map(i => ({
                        value: `000000000${i + 1}`.substr(-2),
                        label: `000000000${i + 1}`.substr(-2),
                      }))}
                      onChange={value => setBornAtMonth(value)}
                      placeholder=""
                      value={bornAtMonth}
                      className="configs__payment-data__formItem--born-at-day__field"
                    />
                  </div>
                  <div className="configs__payment-data__formItem--born-at-year">
                    <Dropdown
                      options={[...Array(100).keys()].map(i => ({
                        value: 2019 - i,
                        label: 2019 - i,
                      }))}
                      onChange={value => setBornAtYear(value)}
                      placeholder=""
                      value={bornAtYear}
                      className="configs__payment-data__formItem--born-at-day__field"
                    />
                  </div>
                </div>
              </div>
            )}

            <div className="configs__payment-data__button">
              <Button
                className="user-options__logout__button"
                onClick={() => {
                  onFinish({
                    name,
                    cpf,
                    address,
                    streetNumber,
                    complementary,
                    neighborhood,
                    cep,
                    bornAtDay,
                    bornAtMonth,
                    bornAtYear,
                    phoneDdd,
                    phoneNumber,
                  });
                  metrics.collect(metrics.events.DRAWER.CONFIGURACOES.ASSINE_JA.INFO_NF);
                }}
                disabled={
                  !name ||
                  !cpf ||
                  (type === 'PF' && !cpf.match(cpfFullRegex)) ||
                  (type === 'PJ' && !cpf.match(cnpjFullRegex)) ||
                  errorCPF ||
                  !address ||
                  !streetNumber ||
                  !neighborhood ||
                  !cep ||
                  !cep.match(cepFullRegex) ||
                  !bornAtDay ||
                  !bornAtMonth ||
                  !bornAtYear ||
                  !phoneDdd ||
                  !phoneNumber ||
                  !phoneNumber.match(phoneFullRegex)
                }
              >
                {single ? 'Alterar' : 'Próximo'}
              </Button>
            </div>
          </div>
        </div>
      </CustomScrollbar>
    </div>
  );
};
