import { WAYPOINT, WAYPOINT_TYPE } from './types';

export function getAllWaypoints() {
  return {
    type: WAYPOINT.LOAD_COLLECTION,
    payload: {
      client: 'aerodata',
      request: {
        url: '/waypoints',
        method: 'get',
      },
    },
  };
}

export function getAllWaypointTypes() {
  return {
    type: WAYPOINT_TYPE.LOAD_COLLECTION,
    payload: {
      client: 'api',
      request: {
        url: '/waypoint/getAllTypes',
        method: 'get',
      },
    },
  };
}

export function getAllUserWaypoints() {
  return {
    type: WAYPOINT.LOAD_USER_COLLECTION,
    payload: {
      client: 'api',
      request: {
        url: '/userWaypoint/getWaypoints',
        method: 'get',
      },
    },
  };
}
