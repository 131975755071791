import React, { Component } from 'react';
import Tooltip from 'react-uwp/Tooltip';

import FloatNav from 'react-uwp/FloatNav';
import IconButton from 'react-uwp/IconButton';
import cartas from 'assets/icons/barras/cartas.svg';
import cartasDisabled from 'assets/icons/barras/cartasDisabled.svg';
import metereologia from 'assets/icons/barras/metereologia.svg';
import metereologiaDisabled from 'assets/icons/barras/metereologiaDisabled.svg';

import classnames from 'classnames';
import withTourContext from 'context/TourContext/withTourContext';
import metrics from '../../../../utils/metrics';

import './MapNav.style.scss';

class MapNav extends Component {
  shouldComponentUpdate(nextProps, nextState) {
    const { meteorology, ruleActive, mapNavOpen } = this.props;

    return nextProps.meteorology !== meteorology || nextProps.ruleActive !== ruleActive || nextProps.mapNavOpen !== mapNavOpen;
  }

  isTourStepDisabled = event => {
    const { tourContext } = this.props;

    if (!tourContext) {
      return true;
    }

    return tourContext.isDisabled(event);
  };

  handleMapOptions = () => {
    const { tourContext, onMapOptions } = this.props;

    if (this.isTourStepDisabled(metrics.events.MENU_MAPA.CARTAS_AERONAUTICAS.THIS)) {
      return;
    }
    onMapOptions();
    metrics.collect(metrics.events.MENU_MAPA.CARTAS_AERONAUTICAS.THIS);
    tourContext.dispatchTourClick(metrics.events.MENU_MAPA.CARTAS_AERONAUTICAS.THIS);
  };

  handleMeteorologyOptions = () => {
    const { tourContext, onMeteorologyOptions } = this.props;

    if (this.isTourStepDisabled(metrics.events.MENU_MAPA.MAPAS_METEOROLOGICOS.THIS)) {
      return;
    }
    onMeteorologyOptions();
    metrics.collect(metrics.events.MENU_MAPA.MAPAS_METEOROLOGICOS.THIS);
    tourContext.dispatchTourClick(metrics.events.MENU_MAPA.MAPAS_METEOROLOGICOS.THIS);
  };

  handleRuleUpdate = () => {
    const { tourContext, onRuleUpdate, meteorology, ruleActive } = this.props;

    if (tourContext.isDisabled(metrics.events.MENU_MAPA.REGUA)) {
      return;
    }
    if (!meteorology) {
      onRuleUpdate(!ruleActive);
    }
    metrics.collect(metrics.events.MENU_MAPA.REGUA);
    tourContext.dispatchTourClick(metrics.events.MENU_MAPA.REGUA);
  };

  render() {
    const { meteorology, ruleActive, onFitRoute, onZoomIn, onZoomOut } = this.props;

    const topNode = [];

    topNode.push(
      <IconButton
        className={classnames('map__nav__item', { 'map__nav__item--active': !meteorology })}
        id="cartas_aeronauticas"
        onClick={this.handleMapOptions}
        disabled={this.isTourStepDisabled(metrics.events.MENU_MAPA.CARTAS_AERONAUTICAS.THIS)}
      >
        <img
          src={this.isTourStepDisabled(metrics.events.MENU_MAPA.CARTAS_AERONAUTICAS.THIS) ? cartasDisabled : cartas}
          width={22}
          height={22}
          alt="logoCartas"
        />
      </IconButton>,
    );

    topNode.push(
      <IconButton
        className={classnames('map__nav__item', {
          'map__nav__item--active': meteorology,
        })}
        id="mapas_meteorologicos"
        onClick={this.handleMeteorologyOptions}
        disabled={this.isTourStepDisabled(metrics.events.MENU_MAPA.MAPAS_METEOROLOGICOS.THIS)}
      >
        <img
          src={this.isTourStepDisabled(metrics.events.MENU_MAPA.MAPAS_METEOROLOGICOS.THIS) ? metereologiaDisabled : metereologia}
          width={22}
          height={22}
          alt="logoMeteorologia"
        />
      </IconButton>,
    );

    topNode.push(
      <Tooltip className="map__nav__item__tooltip-ruler" content="Régua" verticalPosition="bottom" horizontalPosition="left">
        <IconButton
          className={classnames('map__nav__item', { 'map__nav__item--active': ruleActive })}
          id="regua"
          size={42}
          onClick={this.handleRuleUpdate}
          disabled={meteorology || this.isTourStepDisabled(metrics.events.MENU_MAPA.REGUA)}
        >
          Unit
        </IconButton>
      </Tooltip>,
    );

    return (
      <FloatNav
        className="map__nav"
        initWidth={50}
        topNode={topNode}
        bottomNode={[
          <Tooltip className="map__nav__item__tooltip-adjust" content="Ajustar" verticalPosition="bottom" horizontalPosition="left">
            <IconButton
              className="map__nav__item"
              size={42}
              onClick={() => {
                onFitRoute();
                metrics.collect(metrics.events.MENU_MAPA.AJUSTAR);
              }}
            >
              BackToWindow
            </IconButton>
          </Tooltip>,
          <Tooltip className="map__nav__item__tooltip-zoomIn" content="Ampliar" verticalPosition="bottom" horizontalPosition="left">
            <IconButton
              className="map__nav__item"
              size={42}
              onClick={() => {
                onZoomIn();
                metrics.collect(metrics.events.MENU_MAPA.AMPLIAR);
              }}
            >
              Add
            </IconButton>
          </Tooltip>,
          <Tooltip className="map__nav__item__tooltip-zoomOut" content="Reduzir" verticalPosition="bottom" horizontalPosition="left">
            <IconButton
              className="map__nav__item"
              size={42}
              onClick={() => {
                onZoomOut();
                metrics.collect(metrics.events.MENU_MAPA.REDUZIR);
              }}
            >
              RemoveLegacy
            </IconButton>
          </Tooltip>,
        ]}
      />
    );
  }
}

export default withTourContext(MapNav);
