export const resolveLevelNumberToLevel = number => {
  switch (number) {
    case 0:
      return 'surface';
    case 1:
      return '100m';
    case 2:
      return '975h';
    case 3:
      return '950h';
    case 4:
      return '925h';
    case 5:
      return '900h';
    case 6:
      return '850h';
    case 7:
      return '800h';
    case 8:
      return '700h';
    case 9:
      return '600h';
    case 10:
      return '500h';
    case 11:
      return '400h';
    case 12:
      return '300h';
    case 13:
      return '250h';
    case 14:
      return '200h';
    case 15:
      return '150h';
    default:
      return '';
  }
};

export const resolveLevelToLabel = level => {
  switch (level) {
    case 0:
      return 'Superfície';
    case 1:
      return '330ft 100m';
    case 2:
      return '1500ft 450m';
    case 3:
      return '2000ft 600m';
    case 4:
      return '2500ft 750m';
    case 5:
      return '3000ft 900m';
    case 6:
      return '5000ft 1500m';
    case 7:
      return '6400ft 2000m';
    case 8:
      return 'FL100 3000m';
    case 9:
      return 'FL140 4200m';
    case 10:
      return 'FL180 5500m';
    case 11:
      return 'FL240 7000m';
    case 12:
      return 'FL300 9000m';
    case 13:
      return 'FL340 10km';
    case 14:
      return 'FL390 11.7km';
    case 15:
      return 'FL450 13.5km';
    default:
      return '';
  }
};
