import React, { Component } from 'react';

import CloseIcon from 'assets/icons/barras/close.svg';

import './BetaBanner.style.scss';

class BetaBanner extends Component {
  render() {
    const { onClose } = this.props;

    return (
      <div className="beta-banner" onClick={onClose}>
        <div className="beta-banner__content" onClick={e => e.stopPropagation()}>
          <div className="beta-banner__content__header">
            <div className="beta-banner__content__header__close" onClick={onClose}>
              <img src={CloseIcon} alt="X" />
            </div>
          </div>
          <div className="beta-banner__content__body">
            <div className="beta-banner__content__body__image-box">
              {/* <img src={betaIndication} alt="indication of where to find information about the beta" /> */}
            </div>
            <div className="beta-banner__content__body__text">
              <div className="beta-banner__content__body__text__title__container">
                <h1>Experimente a nova versão do aplicativo web!</h1>
              </div>
              <p>
                Estamos empolgados em apresentar a nova versão do nosso software, cheia de novas funcionalidades e melhorias para você explorar em
                primeira mão.{' '}
                <span role="img" aria-label="rocket icon">
                  🚀
                </span>
              </p>
              <p>
                Lembrando que esta é uma versão de testes! Algumas funcionalidades ainda estão sendo aprimoradas, então você pode encontrar alguns
                erros.
              </p>
              <p>
                <strong>Teste agora</strong> e <strong>deixe seus feedbacks</strong> sobre as novidades!
              </p>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default BetaBanner;
