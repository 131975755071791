import React, { useState } from 'react';
import { useDispatch } from 'react-redux';

import Icon from 'react-uwp/Icon';
import CustomScrollbar from 'components/CustomScrollbar/CustomScrollbar';
import MaterialInput from 'components/form/MaterialInput/MaterialInput';
import OutlinedButton from 'components/form/OutlinedButton/OutlinedButton';

import { apiWebClient } from 'services/webclient';
import { updateLocalUser } from 'redux/actions/authActions';

import './ConfigsManageDependants.style.scss';
import DependantItem from './components/DependantItem';

export default props => {
  const { user, onReturn } = props;

  const dispatch = useDispatch();

  const [emailAdd, setEmailAdd] = useState('');
  const [emailError, setEmailError] = useState(null);
  const [loading, setLoading] = useState(false);

  const addDependant = () => {
    setLoading(true);

    apiWebClient
      .post('/user/addDependant', {
        email: emailAdd,
      })
      .then(response => {
        const { data } = response;

        if (data.error) {
          if (data.message === 'userNotFound') {
            setEmailError('Essa conta não existe. Crie a conta primeiro.');
          } else if (data.message === 'userSameEmail') {
            setEmailError('Você não pode se adicionar como dependente.');
          } else if (data.message === 'userAlreadyHasPlan') {
            setEmailError('Essa conta já possui uma assinatura ativa.');
          } else if (data.message === 'userAlreadyHasManager') {
            setEmailError('Essa conta já é dependente de outra conta.');
          }

          setLoading(false);
          return;
        }

        dispatch(updateLocalUser(data.user));
        setEmailAdd('');
        setEmailError(null);
        setLoading(false);
      })
      .catch(error => {
        setEmailError('Ocorreu um erro, tente novamente mais tarde.');
        setLoading(false);
      });
  };

  const removeDependant = email => {
    apiWebClient
      .post('/user/removeDependant', {
        email,
      })
      .then(response => {
        const { data } = response;

        if (data.user) {
          dispatch(updateLocalUser(data.user));
        }

        setLoading(false);
      })
      .catch(error => {
        setLoading(false);
      });
  };

  const dependantsNum = user.dependants && user.dependants.length;
  let maxDependantsNum = user.plan && user.plan.dependants;
  if (user.subscription_special_dependants) {
    maxDependantsNum = user.subscription_special_dependants;
  }

  return (
    <div className="configs__base">
      <div className="configs__title">
        <Icon onClick={() => onReturn()} className="configs__title__action">
          LeftArrowKeyTime0
        </Icon>
        GERENCIAR DEPENDENTES
      </div>
      <CustomScrollbar className="configs__scroll">
        <div className="configs__container">
          <div className="configs__container__session">
            <p className="configs__container__paragraph">
              O seu plano permite compartilhar sua assinatura!
              <br />
              <br />
              Adicione um dependente:
            </p>
          </div>
          <div className="configs__container__session configs__dependants__session">
            <MaterialInput
              label="Digite um e-mail"
              value={emailAdd}
              info={dependantsNum >= maxDependantsNum ? 'Você alcançou o limite de dependentes.' : emailError}
              infoLevel={emailError || dependantsNum >= maxDependantsNum ? 'warning' : null}
              infoMargin
              onChangeValue={setEmailAdd}
              onEnter={addDependant}
            />
            <div className="configs__dependants__button">
              <OutlinedButton label="Adicionar" disabled={!emailAdd || loading || dependantsNum >= maxDependantsNum} onClick={addDependant} />
            </div>
          </div>
          <div className="configs__container__session configs__dependants__session">
            <p className="configs__dependants__title">
              DEPENDENTES CADASTRADOS ({dependantsNum}/{maxDependantsNum})
            </p>
            {user.dependants.map(dependant => (
              <DependantItem key={dependant.id} item={dependant} onRemove={removeDependant} />
            ))}
          </div>
        </div>
      </CustomScrollbar>
    </div>
  );
};
