import React from 'react';
import { TourContext } from './TourContext';

const withTourContext = WrappedComponent => {
  return props => {
    return <TourContext.Consumer>{tourContext => <WrappedComponent {...props} tourContext={tourContext} />}</TourContext.Consumer>;
  };
};

export default withTourContext;
