import { FEEDBACK } from '../actions/types';

const initialState = {
  feedbackSent: false,
  feedbackError: false,
};

export default function(state = initialState, action) {
  switch (action.type) {
    case `${FEEDBACK.SEND}_SUCCESS`:
      return {
        ...state,
        feedbackSent: true,
        feedbackError: false,
      };

    case `${FEEDBACK.SEND}_ERROR`:
      return {
        ...state,
        feedbackError: true,
        feedbackSent: false,
      };

    case FEEDBACK.RESET_STATE:
      return {
        ...initialState,
      };

    default:
      return { ...state };
  }
}
