import React, { useEffect, useState } from 'react';
import Modal from 'react-modal';
import ReactPixel from 'react-facebook-pixel';

import metrics from 'utils/metrics';

import './PromoModal.style.scss';

const PromoModal = props => {
  const { src, href, promo } = props;
  const [open, setOpen] = useState(true);

  useEffect(() => {
    if (localStorage.getItem(`promo$${promo}`) === '3') {
      return;
    }

    ReactPixel.trackCustom('ModalPromo', { promotion: promo });
    metrics.collect(metrics.events.MAPA.PROMO, promo);
  }, [promo]);

  const handleClose = () => {
    const currentNumber = localStorage.getItem(`promo$${promo}`);
    const newNumber = currentNumber ? parseInt(currentNumber, 10) + 1 : 1;

    localStorage.setItem(`promo$${promo}`, String(newNumber));
    setOpen(false);
  };

  if (localStorage.getItem(`promo$${promo}`) === '3') {
    return null;
  }

  return (
    <Modal isOpen={open} onRequestClose={handleClose} ariaHideApp={false} className="promo-modal" overlayClassName="promo-modal__overlay">
      <div className="promo-modal__close" onClick={handleClose} role="button" tabIndex={0} onKeyDown={handleClose}>
        &times;
      </div>

      <a
        href={href}
        onClick={() => {
          ReactPixel.trackCustom('ModalPromoClick', { promotion: promo });
          metrics.collect(metrics.events.MAPA.PROMO_CLICK, promo);
        }}
        target="_blank"
        rel="noopener noreferrer"
      >
        <img src={src} alt="" />
      </a>
    </Modal>
  );
};

export default PromoModal;
