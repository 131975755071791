import React from 'react';
import CheckBox from 'react-uwp/CheckBox';

import { useTourContext } from 'context/TourContext/TourContext';
import { METEOROLOGY_OPTIONS } from '../../MapMain';

import metrics from '../../../../utils/metrics';

import './MeteorologyOptions.style.scss';

const MeteorologyOptions = props => {
  const { meteorology, onMeteorologyOptions } = props;

  const tourContext = useTourContext();

  return (
    <div>
      <div className="methereology__options">
        <h1 className="methereology__options__title">Mapas Meteorológicos</h1>
        <span className="methereology__options__item">
          <CheckBox
            className="map__options__item__checkbox"
            defaultChecked={meteorology === METEOROLOGY_OPTIONS.WINDS}
            onCheck={() => {
              onMeteorologyOptions(METEOROLOGY_OPTIONS.WINDS);
              metrics.collect(metrics.events.MENU_MAPA.MAPAS_METEOROLOGICOS.MAPA, 'VENTOS');
              tourContext.dispatchTourClick(metrics.events.MENU_MAPA.MAPAS_METEOROLOGICOS.MAPA);
            }}
          />
          <span
            className="methereology__options__chartTitle"
            onClick={() => {
              onMeteorologyOptions(METEOROLOGY_OPTIONS.WINDS);
              metrics.collect(metrics.events.MENU_MAPA.MAPAS_METEOROLOGICOS.MAPA, 'VENTOS');
              tourContext.dispatchTourClick(metrics.events.MENU_MAPA.MAPAS_METEOROLOGICOS.MAPA);
            }}
          >
            Ventos
          </span>
        </span>
        {/* <span className="methereology__options__item">
        <CheckBox
          className="map__options__item__checkbox"
          defaultChecked={meteorology === METEOROLOGY_OPTIONS.TEMPERATURE}
          onCheck={() => onMeteorologyOptions(METEOROLOGY_OPTIONS.TEMPERATURE)}
        />
        <span
          className="methereology__options__chartTitle"
          onClick={() => onMeteorologyOptions(METEOROLOGY_OPTIONS.TEMPERATURE)}
        >
          Temperatura
        </span>
      </span> */}
      </div>
    </div>
  );
};

export default MeteorologyOptions;
