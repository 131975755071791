import React from 'react';
import PropTypes from 'prop-types';

import './InfoContainer.style.scss';

const InfoContainer = props => {
  const { title, errors, onClose } = props;

  return (
    <div className="auth__info-container">
      <div className="auth__info-container__close" onKeyDown={() => onClose()} role="button" tabIndex="0" onClick={() => onClose()}>
        &times;
      </div>
      {title && <h2 className="auth__info-container__title">{title}</h2>}
      {errors &&
        errors.map(error => (
          <div key={error} className="auth__info-container__content-line">
            {error}
          </div>
        ))}
    </div>
  );
};

InfoContainer.defaultProps = {
  title: 'Erros possíveis',
  errors: [],
  onClose: () => {},
};

InfoContainer.propTypes = {
  title: PropTypes.string,
  errors: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.string, PropTypes.node])),
  onClose: PropTypes.func,
};

export default InfoContainer;
