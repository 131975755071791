import React from 'react';
import classnames from 'classnames';
import './TextArea.style.scss';

const TextArea = props => {
  return (
    <textarea
      className={classnames({ [props.extraClass]: props.extraClass }, 'custom-textarea')}
      onChange={e => {
        if (props.onChangeValue) {
          props.onChangeValue(e.target.value);
        }
      }}
    >
      {props.children}
    </textarea>
  );
};

export default TextArea;
