import React, { Component } from 'react';

import './AppModalMobile.style.scss';
import AppMobile from 'assets/logos/nexatlas-black.svg';

import metrics from '../../../../utils/metrics';

class AppModalMobile extends Component {
  render() {
    const { onClose } = this.props;

    return (
      <div className="app-footer-mobile">
        <div className="app-footer-mobile__content">
          <div className="app-footer-mobile__content__header">
            <img src={AppMobile} alt="nexatlas logo" className="app-footer-mobile__content__header__logo" />
            <span className="app-footer-mobile__content__header__title">Tenha a experiência completa com o novo aplicativo NexAtlas</span>
          </div>
          <div className="app-footer-mobile__content__description">
            Mapas, cartas de procedimento e navegação aérea offline para tablets e celulares.
          </div>
          <div className="app-footer-mobile__content__buttons">
            <a
              className="app-footer-mobile__content__buttons__open"
              target="_blank"
              rel="noopener noreferrer"
              href="https://nexatlas.com/download-app"
              onClick={() => metrics.collect(metrics.events.APP_BAR.DOWNLOAD_MOBILE)}
            >
              <span>Abrir no aplicativo</span>
            </a>
            <div
              className="app-footer-mobile__content__buttons__close"
              onClick={() => {
                onClose();
                metrics.collect(metrics.events.APP_BAR.CLOSE);
              }}
            >
              <span>Agora não</span>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default AppModalMobile;
