import { Component } from 'react';

export default class CacheBuster extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      isCorrectVersion: false,
      refreshCacheAndReload: () => {
        console.log('Clearing cache and hard reloading...');

        fetch(window.location.href, {
          method: 'GET',
          headers: {
            Pragma: 'no-cache',
            Expires: -1,
            'Cache-Control': 'no-cache',
          },
          cache: 'reload',
        }).then(() => {
          window.location.reload(true);
        });
      },
    };
  }

  componentDidMount() {
    fetch('/meta.json', {
      method: 'GET',
      headers: {
        Pragma: 'no-cache',
        Expires: -1,
        'Cache-Control': 'no-cache',
      },
      cache: 'reload',
    })
      .then(response => response.json())
      .then(meta => {
        const latestCommit = meta.commit;
        const currentCommit = process.env.GIT_COMMITHASH;

        if (latestCommit !== currentCommit) {
          console.log(`We have a new version. Should force refresh`, latestCommit, currentCommit);

          // Prevent deadlock
          if (!sessionStorage.getItem('force-reaload')) {
            console.log('Doing a refresh');
            sessionStorage.setItem('force-reaload', true);
            this.setState({ loading: false, isCorrectVersion: false });
          } else {
            console.log('Could not refresh, not forcing a deadlock');
            this.setState({ loading: false, isCorrectVersion: true });
          }
        } else {
          console.log(`You already have the latest version. No cache refresh needed.`, latestCommit, currentCommit);
          this.setState({ loading: false, isCorrectVersion: true });
        }
      });
  }

  render() {
    const { loading, isCorrectVersion, refreshCacheAndReload } = this.state;

    return this.props.children({ loading, isCorrectVersion, refreshCacheAndReload });
  }
}
