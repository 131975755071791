import React, { useEffect, useState } from 'react';

import Button from 'react-uwp/Button';
import Icon from 'react-uwp/Icon';
import RadioButton from 'react-uwp/RadioButton';
import CustomScrollbar from 'components/CustomScrollbar/CustomScrollbar';

import parse from 'html-react-parser';

import metrics from '../../../../../../utils/metrics';

import './ConfigsPlans.styles.scss';

export default props => {
  const { emailConfirmed, plans, values, single, user, onReturn, onFinish, onResendEmail } = props;

  let isCurrentPlanActive = false;
  if (values && values.planOption && plans) {
    const currentPlan = plans.find(plan => plan.id === values.planOption.id);
    if (currentPlan) {
      isCurrentPlanActive = currentPlan.active;
    }
  }

  const [planOption, setPlanOption] = useState((isCurrentPlanActive && values && values.planOption) || null);

  const [emailSentFirst, setEmailSentFirst] = useState(false);
  const [emailCounter, setEmailCounter] = useState(0);

  let specialPlan = null;
  if (user.subscription_special_plan && plans) {
    specialPlan = plans.find(plan => plan.id === user.subscription_special_plan);
  }

  useEffect(() => {
    if (emailSentFirst && emailCounter > 0) {
      setTimeout(() => {
        setEmailCounter(emailCounter - 1);
      }, 1000);
    }
  }, [emailCounter, emailSentFirst]);

  const handleResendEmail = () => {
    setEmailSentFirst(true);
    setEmailCounter(60);
    onResendEmail();
  };

  return (
    <div className="configs__plans__base">
      <div className="configs__title">
        {!single && (
          <Icon onClick={() => onReturn()} className="configs__title__action">
            ChevronLeft
          </Icon>
        )}
        PLANO DE ASSINATURA
      </div>
      <CustomScrollbar className="configs__scroll">
        <div className="configs__container">
          {!emailConfirmed ? (
            <div className="configs__container__session">
              <div className="configs__plans__mail-verify__container">
                <span style={{ fontWeight: 'bold' }}>Parece que você ainda não confirmou seu e-mail!</span>
                <br />
                <br />
                Antes de prosseguir e escolher um plano, clique no botão abaixo para enviar um e-mail de confirmação.
                <br />
                <br />
                Depois de confirmar seu e-mail, volte a esta página e atualize-a para continuar.
              </div>
              <div className="configs__plans__mail-verify__button">
                <Button
                  className="user-options__logout__button"
                  onClick={() => {
                    handleResendEmail();
                  }}
                  disabled={emailSentFirst && emailCounter > 0}
                >
                  {emailSentFirst && emailCounter > 0 ? 'E-mail enviado' : 'Enviar e-mail de confirmação'}
                </Button>
              </div>
              {emailSentFirst && (
                <div className="configs__plans__mail-verify__counter">
                  <div className="configs__plans__mail-verify__counter__number">{emailCounter} seg</div>
                  <div className="configs__plans__mail-verify__counter__text">para enviar um novo e-mail de confirmação</div>
                </div>
              )}
            </div>
          ) : (
            <>
              <div className="configs__container__session">
                <p className="configs__container__paragraph configs__plans__top">Selecione uma opção:</p>

                <div className="configs__plans__session">
                  <div className="configs__plans__title">PLANO INDIVIDUAL</div>
                  <div className="configs__plans__description">
                    Válido para essa conta, podendo acessar em um dispositivo por vez. Assinatura recorrente.
                  </div>
                  {specialPlan && (
                    <RadioButton
                      key={specialPlan.id}
                      defaultChecked={planOption && planOption.id === specialPlan.id}
                      label={parse(specialPlan.text)}
                      onCheck={() => setPlanOption(specialPlan)}
                      className="configs__plans__radio"
                    />
                  )}
                  {plans
                    .filter(plan => plan.active && plan.dependants === 0)
                    .map(plan => (
                      <RadioButton
                        key={plan.id}
                        defaultChecked={planOption && planOption.id === plan.id}
                        label={parse(plan.text)}
                        onCheck={() => setPlanOption(plan)}
                        className="configs__plans__radio"
                      />
                    ))}
                </div>

                {user.type === 'PJ' && (
                  <div>
                    <div className="configs__plans__session">
                      <hr className="configs__plans__hr" />
                      <div className="configs__plans__title">PLANO EQUIPE</div>
                      <div className="configs__plans__description">
                        Entre em contato com nossa equipe para saber mais sobre planos especiais para empresas.
                        <br />
                        <br />
                        Para isso, envie um e-mail para contato@nexatlas.com ou entre em contato pelo chat, no canto inferior direito.
                      </div>
                    </div>
                  </div>
                )}
                <div className="configs__plans__button">
                  <Button
                    className="user-options__logout__button"
                    onClick={() => {
                      onFinish(planOption);
                      metrics.collect(metrics.events.DRAWER.CONFIGURACOES.ASSINE_JA.PLANO, planOption.text);
                    }}
                    disabled={!planOption}
                  >
                    {single ? 'Alterar' : 'Próximo'}
                  </Button>
                </div>
              </div>
            </>
          )}
        </div>
      </CustomScrollbar>
    </div>
  );
};
