import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

import './MaterialInput.style.scss';

import classnames from 'classnames';

const MaterialInput = props => {
  const { className, name, value, type, label, shrink, info, infoMargin, infoLevel, onChangeValue, onFocus, onBlur, onEnter } = props;

  const [labelShrinked, setLabelShrinked] = useState(shrink || value);
  const [inputFocused, setInputFocused] = useState(false);

  useEffect(() => {
    if (value && value.length > 0) {
      setLabelShrinked(true);
    } else {
      setLabelShrinked(false);
    }
  }, [value]);

  return (
    <div className={classnames('auth__input__wrapper', className, { 'auth__input__wrapper--info-margin': infoMargin })}>
      {info && (
        <div
          className={classnames('auth__input__info', {
            'auth__input__info--error': infoLevel === 'error',
            'auth__input__info--warning': infoLevel === 'warning',
          })}
        >
          {info}
        </div>
      )}

      <div className={classnames('auth__input__label', { 'auth__input__label--active': labelShrinked, 'auth__input__label--focused': inputFocused })}>
        {label}
      </div>

      <input
        className={classnames('auth__input__base-input', {
          'auth__input__base-input--warning': infoLevel === 'warning',
          'auth__input__base-input--error': infoLevel === 'error',
          'auth__input__base-input--active': inputFocused,
        })}
        name={name}
        value={value || ''}
        type={type}
        onChange={e => onChangeValue(e.target.value)}
        onFocus={() => {
          setLabelShrinked(true);
          setInputFocused(true);
          onFocus();
        }}
        onBlur={() => {
          setInputFocused(false);
          if (!value || value.length === 0) {
            setLabelShrinked(false);
          }
          onBlur();
        }}
        onKeyUp={event => {
          if (event.keyCode === 13) {
            onEnter();
          }
        }}
      />
    </div>
  );
};

MaterialInput.defaultProps = {
  value: null,
  name: null,
  type: 'text',
  label: '',
  className: null,
  shrink: false,
  info: null,
  infoMargin: false,
  infoLevel: 'message',
  onChangeValue: () => {},
  onFocus: () => {},
  onBlur: () => {},
  onEnter: () => {},
};

MaterialInput.propTypes = {
  value: PropTypes.string,
  name: PropTypes.string,
  type: PropTypes.string,
  label: PropTypes.string,
  className: PropTypes.string,
  shrink: PropTypes.bool,
  info: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  infoMargin: PropTypes.bool,
  infoLevel: PropTypes.oneOf(['error', 'warning', 'message']),
  onChangeValue: PropTypes.func,
  onFocus: PropTypes.func,
  onBlur: PropTypes.func,
  onEnter: PropTypes.func,
};

export default MaterialInput;
