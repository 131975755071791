import React from 'react';

const ContentProfile4 = () => {
  return (
    <div>
      <p>Seja bem-vindo(a) à NexAtlas!</p>
      <p>
        Sua atividade de planejamento de voos será muito mais prática e intuitiva com o apoio dos nossos recursos e informações. Conte sempre conosco.
      </p>
      <p>
        Bons voos,
        <br />
        Equipe NexAtlas
      </p>
    </div>
  );
};

export default ContentProfile4;
