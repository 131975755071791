import React from 'react';

import './ContentCreate2.style.scss';

const ContentCreate2 = () => {
  return (
    <div>
      <p>
        <span className="auth__create__emphasis">Pessoa física:</span> escolha esta opção se você é um profissional individual ou entusiasta e
        pretende utilizar a NexAtlas sozinho.
      </p>
      <p>
        <span className="auth__create__emphasis">Pessoa jurídica:</span> escolha esta opção se você representa uma instituição pública ou privada e
        necessita múltiplos acessos simultâneos à NexAtlas.
      </p>
    </div>
  );
};

export default ContentCreate2;
