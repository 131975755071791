import React from 'react';
import PropTypes from 'prop-types';

import Loader from 'react-loader-spinner';

const ContentChangePassword3 = props => {
  const { loading, error } = props;

  if (loading) {
    return (
      <div style={{ width: '100%', maxWidth: '230px', margin: '0 auto' }}>
        <Loader type="Plane" color="#fcfcfc" height={100} width={100} />
      </div>
    );
  }

  if (error === 'INVALID_TOKEN') {
    return (
      <div>
        <p>Token de confirmação inválido ou expirado. Tente novamente a recuperação de senha!</p>
      </div>
    );
  }

  if (error) {
    return (
      <div>
        <p>Ocorreu um erro, tente novamente mais tarde.</p>
      </div>
    );
  }

  return (
    <div>
      <p>Agora podemos continuar.</p>
    </div>
  );
};

ContentChangePassword3.defaultProps = {
  error: null,
};

ContentChangePassword3.propTypes = {
  loading: PropTypes.bool.isRequired,
  error: PropTypes.bool,
};

export default ContentChangePassword3;
