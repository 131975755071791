import React, { useState } from 'react';

import CustomScrollbar from 'components/CustomScrollbar/CustomScrollbar';

import Icon from 'react-uwp/Icon';
import RadioButton from 'react-uwp/RadioButton';
import TextBox from 'react-uwp/TextBox';
import Button from 'react-uwp/Button';

export default props => {
  const { onCancelPaymentAccount, onReturn } = props;

  const [cancelReason, setCancelReason] = useState(null);
  const [cancelText, setCancelText] = useState(null);

  const cancelReasonList = [
    { label: 'Preciso de uma ferramenta integrada a navegação.', needsText: false },
    { label: 'Não estou voando no momento (avião em manutenção, voo apenas por hobbie, desemprego etc...).', needsText: false },
    { label: 'Utilizei durante a formação de piloto, mas agora estou sem voar.', needsText: false },
    { label: 'O valor está acima do que estou disposto a pagar.', needsText: true, text: 'Quanto pagaria?' },
    { label: 'Utilizo outra ferramenta para planejamento de voo.', needsText: true, text: 'Qual?' },
  ];
  return (
    <div className="configs__base">
      <div className="configs__title">
        <Icon onClick={() => onReturn()} className="configs__title__action">
          LeftArrowKeyTime0
        </Icon>
        CANCELAMENTO DE ASSINATURA
      </div>
      <CustomScrollbar className="configs__scroll">
        <div className="configs__container">
          <div className="configs__container__session">
            É uma pena que esteja cancelando sua conta, mas estaremos aqui quando precisar de uma ferramenta completa de planejamento de voo
            novamente, ok?
            <br />
            <br />
            E provavelmente teremos novidades até lá!
            <br />
            <br />
            Antes de ir, se importa de nos contar o por quê decidiu cancelar?
            <br />
            <br />
            {cancelReasonList.map((reason, i) => (
              <RadioButton
                key={reason.label}
                defaultChecked={cancelReason === i}
                label={reason.label}
                onCheck={() => setCancelReason(i)}
                className="configs__plans__radio"
              />
            ))}
            <br />
            {cancelReason !== null && cancelReasonList[cancelReason].needsText && (
              <div>
                <label>{cancelReasonList[cancelReason].text}</label>
                <TextBox value={cancelText || ''} onChangeValue={value => setCancelText(value)} background="#1e262a" />
              </div>
            )}
          </div>

          <div className="configs__payment-data__button">
            <Button
              className="user-options__logout__button"
              onClick={() => {
                onCancelPaymentAccount(cancelReasonList[cancelReason].label, cancelText || '');
                onReturn();
              }}
              disabled={cancelReason === null || (cancelReason !== null && cancelReasonList[cancelReason].needsText && !cancelText)}
            >
              Cancelar assinatura
            </Button>
            <br />
            <br />
            <Button className="user-options__logout__button" onClick={() => onReturn()}>
              Retornar
            </Button>
          </div>
        </div>
      </CustomScrollbar>
    </div>
  );
};
