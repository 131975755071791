import React, { Component } from 'react';

import './AppFooter.style.scss';

import CloseIcon from 'assets/icons/barras/close.svg';
import AppStar from 'assets/icons/barras/star-app.svg';
import Mobile from 'assets/icons/barras/mobile.svg';
import AppMobile from 'assets/logos/nexatlas-black.svg';
import PlayStore from 'assets/icons/barras/play-store-logo-black.png';
import AppStore from 'assets/icons/barras/app-store-logo-black.png';

import metrics from '../../../../utils/metrics';

class AppFooter extends Component {
  render() {
    const { onClose, onModal } = this.props;
    const { innerWidth: screenWidth } = window;

    return (
      <div className="app-footer">
        <div className="app-footer__content__left">
          <div
            onClick={() => {
              onClose();
              metrics.collect(metrics.events.APP_BAR.CLOSE);
            }}
            className="app-footer__content__left__close"
          >
            <img src={CloseIcon} alt="X" />
          </div>
          <div className="app-footer__content__left__nexatlas">
            <img src={AppMobile} alt="logo-nexatlas" />
            <div className="app-footer__content__left__nexatlas-info">
              <div className="app-footer__content__left__nexatlas-info__title-container">
                <span className="app-footer__content__left__nexatlas-info__title">NexAtlas</span>
                <img src={AppStar} alt="app-star" />
              </div>
              <p className="app-footer__content__left__nexatlas-info__subtitle">Planejamento e navegação aérea</p>
            </div>
          </div>
        </div>
        <div className="app-footer__content__right">
          {screenWidth > 920 && (
            <div
              className="app-footer__content__right__button-modal"
              onClick={() => {
                metrics.collect(metrics.events.APP_BAR.DOWNLOAD_MODAL);
                onModal();
              }}
            >
              <img src={Mobile} alt="mobile-icon" />
              <span>Enviar para seu tablet ou celular</span>
            </div>
          )}
          <div className="app-footer__content__right__container-buttons">
            <a
              href="https://play.google.com/store/apps/details?id=com.nexatlas.pilotapp"
              onClick={() => metrics.collect(metrics.events.APP_BAR.PLAY_STORE)}
              target="_blank"
              rel="noopener noreferrer"
              style={{ marginRight: '8px' }}
            >
              <img src={PlayStore} alt="play-store" />
            </a>
            <a
              href="https://apps.apple.com/us/app/nexatlas/id1562704198"
              onClick={() => metrics.collect(metrics.events.APP_BAR.APP_STORE)}
              target="_blank"
              rel="noopener noreferrer"
            >
              <img src={AppStore} alt="app-store" />
            </a>
          </div>
        </div>
      </div>
    );
  }
}

export default AppFooter;
