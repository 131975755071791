import React from 'react';

import CustomScrollbar from 'components/CustomScrollbar/CustomScrollbar';
import Icon from 'react-uwp/Icon';

import moment from 'moment';

import './ConfigsMoreInfo.style.scss';

export default props => {
  const { updateItems, onReturn } = props;

  const sessions = [
    { type: 'dados', title: 'DADOS' },
    { type: 'rea', title: 'CARTAS REA' },
    { type: 'reh', title: 'CARTAS REH' },
    { type: 'wac', title: 'CARTAS WAC' },
    { type: 'enrch', title: 'CARTAS ENRC HIGH' },
    { type: 'enrcl', title: 'CARTAS ENRC LOW' },
    { type: 'arc', title: 'CARTAS ARC' },
  ];

  return (
    <div className="configs__more-info__base">
      <div className="configs__title">
        <Icon onClick={() => onReturn()} className="configs__title__action">
          LeftArrowKeyTime0
        </Icon>
      </div>
      <CustomScrollbar className="configs__scroll">
        <div className="configs__container">
          <div className="configs__container__session">
            {sessions.map(session => (
              <table key={session.type}>
                <thead>
                  <tr>
                    <th className="configs__container__td1">{session.title}</th>
                    <th className="configs__container__td2" />
                  </tr>
                </thead>
                <tbody>
                  {updateItems
                    .filter(item => item.type === session.type)
                    .sort((a, b) => moment(a.id) - moment(b.id))
                    .map(item => (
                      <tr key={item.id}>
                        <td className="configs__container__td1">{item.name}</td>
                        <td className="configs__container__td2">{moment(item.updated_date).format('DD-MM-YY')}</td>
                      </tr>
                    ))}
                </tbody>
              </table>
            ))}
          </div>
        </div>
      </CustomScrollbar>
    </div>
  );
};
