import React from 'react';

import Button from 'react-uwp/Button';
import CustomScrollbar from 'components/CustomScrollbar/CustomScrollbar';

import './ConfigsTrialEnd.styles.scss';

export default props => {
  const { userProfile } = props;

  return (
    <div className="configs__base">
      <div className="configs__title">SEU PERÍODO DE AVALIAÇÃO TERMINOU</div>
      <CustomScrollbar className="configs__scroll">
        <div className="configs__container">
          <div className="configs__container__session">
            <p className="configs__container__paragraph">Olá, {userProfile.name}</p>
            <p className="configs__container__paragraph">Os 15 dias de avaliação chegaram ao fim e esperamos que você tenha adorado o aplicativo!</p>
            <p className="configs__container__paragraph">Para manter seu acesso, adquira agora mesmo plano de assinatura NexAtlas.</p>
            <div className="configs__trial-end__button">
              <Button tooltip="Assine agora a NexAtlas" className="user-options__logout__button" onClick={props.onCheck}>
                Assine agora
              </Button>
            </div>
          </div>
        </div>
        <div className="configs__bottom">
          Não é {userProfile.name}?{' '}
          <div className="configs__link" onClick={props.onLogout}>
            Entre com outra conta
          </div>
        </div>
      </CustomScrollbar>
    </div>
  );
};
