import { Model, attr } from 'redux-orm';

import { DATA_UPDATE_ITEM } from '../actions/types.js';

export default class DataUpdateItem extends Model {
  static reducer(action, DataUpdateItem, session) {
    const { payload, type } = action;

    switch (type) {
      case `${DATA_UPDATE_ITEM.LOAD_COLLECTION}_SUCCESS`:
        if (payload.data && payload.data.dataUpdateItems) {
          payload.data.dataUpdateItems.forEach(dataUpdateItem => {
            DataUpdateItem.upsert(dataUpdateItem);
          });
        }
        break;

      default:
        break;
    }
  }
}

DataUpdateItem.modelName = 'DataUpdateItem';

DataUpdateItem.fields = {
  id: attr(),
  name: attr(),
  updated_date: attr(),
  type: attr(),
};
