import { apiWebClient } from 'services/webclient';
import arrayMove from 'array-move';
import uuidv1 from 'uuid/v1';
import uuidv4 from 'uuid/v4';
import { store } from '../store';
import { ROUTE, STATE } from './types';
import { decimalToDMS, distanceNMFloat, getWaypointNewSequence, splitLine } from '../../utils/map';

export function invertRouteWaypoints(waypoints, groundSpeed) {
  return dispatch => {
    const generatedRouteID = uuidv1();

    const routeWaypoints = waypoints.sort((a, b) => a.sequence - b.sequence);

    routeWaypoints.forEach(waypoint => {
      waypoint.id = uuidv1();
      waypoint.route_id = generatedRouteID;
    });

    const lastRouteWaypointIndex = routeWaypoints.length - 1;
    const swapsNeeded = Math.floor(lastRouteWaypointIndex / 2);
    for (let i = 0; i <= swapsNeeded; i++) {
      const storeSequence = routeWaypoints[i].sequence;
      routeWaypoints[i].sequence = routeWaypoints[lastRouteWaypointIndex - i].sequence;
      routeWaypoints[lastRouteWaypointIndex - i].sequence = storeSequence;
    }

    const firstName = routeWaypoints[lastRouteWaypointIndex].code
      ? routeWaypoints[lastRouteWaypointIndex].code
      : routeWaypoints[lastRouteWaypointIndex].name;
    const lastName = routeWaypoints[0].code ? routeWaypoints[0].code : routeWaypoints[0].name;
    const routeName = `${firstName} - ${lastName}`;

    return new Promise((resolve, reject) => {
      dispatch({
        type: ROUTE.INVERT,
        payload: {
          route_id: generatedRouteID,
          route_name: routeName,
          ground_speed: groundSpeed,
          waypoints: routeWaypoints,
        },
      });
      resolve();
    });
  };
}

export function segmentRouteWaypoints(waypoints, groundSpeed) {
  return dispatch => {
    const routeWaypoints = waypoints.sort((a, b) => a.sequence - b.sequence);

    const newRouteWaypoints = [...routeWaypoints];

    routeWaypoints.forEach((waypoint, index) => {
      if (index === waypoints.length - 1) return null;
      const distance = distanceNMFloat([waypoint.latitude, waypoint.longitude], [waypoints[index + 1].latitude, waypoints[index + 1].longitude]);

      let splitsNeeded = 0;

      const splitsByDistance = distance / 200;
      if (splitsByDistance > 1) {
        splitsNeeded = Math.floor(splitsByDistance);
      }

      if (groundSpeed) {
        const time = distance / groundSpeed;
        const splitsByTime = time / 0.5;

        if (splitsByTime > 1 && splitsByTime > splitsByDistance) {
          splitsNeeded = Math.floor(splitsByTime);
        }
      }

      if (splitsNeeded > 0) {
        for (let i = 1; i <= splitsNeeded; i += 1) {
          const [latitude, longitude] = splitLine(
            [waypoint.latitude, waypoint.longitude],
            [waypoints[index + 1].latitude, waypoints[index + 1].longitude],
            splitsNeeded + 1,
            i,
          );

          const newWaypoint = {
            id: uuidv4(),
            code: null,
            name: `${decimalToDMS(latitude)} ${decimalToDMS(longitude, true)}`,
            sequence: waypoint.sequence + i,
            latitude,
            longitude,
            route_id: waypoint.route_id,
            waypoint_type_id: 3,
            meta: {},
            waypoint_id: null,
          };

          newRouteWaypoints.splice(waypoint.sequence + i - 1, 0, newWaypoint);
        }

        newRouteWaypoints.forEach((rWaypoint, rIndex) => {
          rWaypoint.sequence = rIndex + 1;
        });
      }
    });

    return new Promise((resolve, reject) => {
      dispatch({
        type: ROUTE.SPLIT,
        payload: {
          waypoints: newRouteWaypoints,
        },
      });
      resolve();
    });
  };
}

export function addRouteWaypoint(item, activeWaypoints) {
  return dispatch => {
    const { routeActive } = store.getState().appState;

    const newWaypoint = {
      name: item.code ? item.code : item.name,
      code: item.code,
      waypoint_id: item.id,
      waypoint_type_id: item.waypoint_type_id,
      longitude: item.longitude,
      latitude: item.latitude,
      meta: item.meta,
    };

    if (!routeActive) {
      const generatedRouteID = uuidv1();

      return new Promise((resolve, reject) => {
        dispatch({
          type: ROUTE.CREATE,
          payload: {
            ...newWaypoint,
            route_id: generatedRouteID,
          },
        });
        resolve();
      });
    }

    const sequence = getWaypointNewSequence(item, activeWaypoints);

    let name = null;
    if (sequence === 2 && activeWaypoints.length === 1) {
      const firstName = activeWaypoints[0].code ? activeWaypoints[0].code : activeWaypoints[0].name;
      const secondName = item.code ? item.code : item.name;
      name = `${firstName} - ${secondName}`;
    }

    return new Promise((resolve, reject) => {
      dispatch({
        type: ROUTE.ADD_WAYPOINT,
        payload: {
          ...newWaypoint,
          sequence,
          route_name: name,
          route_id: routeActive,
        },
      });
      resolve();
    });
  };
}

export function switchRouteWaypoint(oldWaypoint, newWaypoint, activeWaypoints) {
  const { routeActive } = store.getState().appState;

  let route_name = null;
  if (oldWaypoint.sequence === 1) {
    const firstName = newWaypoint.code ? newWaypoint.code : newWaypoint.name;

    const lastWaypoint = activeWaypoints[activeWaypoints.length - 1];
    const lastName = lastWaypoint.code ? lastWaypoint.code : lastWaypoint.name;

    route_name = `${firstName} - ${lastName}`;
  }

  if (oldWaypoint.sequence === activeWaypoints.length) {
    const firstName = activeWaypoints[0].code ? activeWaypoints[0].code : activeWaypoints[0].name;
    const lastName = newWaypoint.code ? newWaypoint.code : newWaypoint.name;
    route_name = `${firstName} - ${lastName}`;
  }

  return dispatch => {
    return new Promise((resolve, reject) => {
      dispatch({
        type: ROUTE.SWITCH_WAYPOINT,
        payload: {
          route_id: routeActive,
          route_name,
          old_waypoint: oldWaypoint,
          new_waypoint: newWaypoint,
        },
      });
      resolve();
    });
  };
}

export function updateRouteWaypoint(waypoint) {
  return dispatch => {
    return new Promise((resolve, reject) => {
      dispatch({ type: ROUTE.UPDATE_WAYPOINT, payload: waypoint });
      resolve();
    });
  };
}

export function sortRouteWaypoints(oldIndex, newIndex, activeWaypoints) {
  const { routeActive } = store.getState().appState;

  let route_name = null;
  const sortingWaypoint = activeWaypoints[oldIndex];

  const newActiveWaypoints = arrayMove(activeWaypoints, oldIndex, newIndex);
  const firstName = newActiveWaypoints[0].code ? newActiveWaypoints[0].code : newActiveWaypoints[0].name;
  const lastName = newActiveWaypoints[newActiveWaypoints.length - 1].code
    ? newActiveWaypoints[newActiveWaypoints.length - 1].code
    : newActiveWaypoints[newActiveWaypoints.length - 1].name;
  route_name = `${firstName} - ${lastName}`;

  return dispatch => {
    return new Promise((resolve, reject) => {
      dispatch({
        type: ROUTE.SORT_WAYPOINT,
        payload: { oldIndex, newIndex, sortingWaypoint, route_id: routeActive, route_name },
      });
      resolve();
    });
  };
}

export function removeWaypoint(waypoint_id, route_id) {
  return dispatch => {
    return new Promise((resolve, reject) => {
      dispatch({ type: ROUTE.REMOVE_WAYPOINT, payload: { waypoint_id, route_id } });
      resolve();
    });
  };
}

export function highlightWaypoint(waypoint_id) {
  return dispatch => {
    return new Promise(resolve => {
      dispatch({ type: ROUTE.HIGHLIGHT_WAYPOINT, payload: { waypoint_id } });
      resolve();
    });
  };
}

export function fadeWaypoint(waypoint_id) {
  return dispatch => {
    return new Promise(resolve => {
      dispatch({ type: ROUTE.FADE_WAYPOINT, payload: { waypoint_id } });
      resolve();
    });
  };
}

export function clearRoute() {
  return dispatch => {
    return new Promise((resolve, reject) => {
      dispatch({
        type: ROUTE.CLEAR,
      });
      resolve();
    });
  };
}

export function setVS(vs) {
  const { routeActive } = store.getState().appState;

  return dispatch => {
    return new Promise((resolve, reject) => {
      dispatch({
        type: ROUTE.UPDATE,
        payload: {
          id: routeActive,
          ground_speed: vs,
        },
      });
      resolve();
    });
  };
}

export function updateName(route_id, name) {
  return dispatch => {
    return new Promise((resolve, reject) => {
      dispatch({
        type: ROUTE.UPDATE,
        payload: {
          id: route_id,
          name,
        },
      });
      resolve();
    });
  };
}

export function upsertRoute(route) {
  return dispatch => {
    dispatch({ type: STATE.LOADING, payload: { key: 'update_route', display: 'Salvando Rota' } });

    const promisse = apiWebClient.post('/navigationRoute/upsertRoute', route);

    promisse
      .then(response => {
        dispatch({
          type: `${ROUTE.UPDATE_ALL}_SUCCESS`,
          payload: { data: route, response },
        });
        dispatch({ type: STATE.LOADED, payload: { key: 'update_route' } });
      })
      .catch(() => {
        dispatch({ type: STATE.LOADED, payload: { key: 'update_route' } });
      });

    return promisse;
  };
}

export function deleteRoute(route_id) {
  return {
    type: ROUTE.DELETE,
    payload: {
      client: 'api',
      request: {
        url: '/navigationRoute/removeRoute',
        method: 'post',
        data: {
          route_id,
        },
      },
    },
  };
}

export function loadUserRoutes() {
  return dispatch => {
    dispatch({
      type: ROUTE.LOAD_COLLECTION,
      payload: {
        client: 'api',
        request: {
          url: '/navigationRoute/userRoutes',
          method: 'post',
        },
      },
    });
  };
}
