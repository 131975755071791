import { createSelector } from 'redux-orm';
import { schema } from '../ormSchema';

export const ormSelector = state => state.orm;

export const getLastUpdate = createSelector(schema, session => {
  const first = session.DataUpdate.first();

  if (first) {
    return session.DataUpdate.first().ref;
  }
  return null;
});

export const getUpdateItems = createSelector(schema, session => {
  return session.DataUpdateItem.all().toRefArray();
});
