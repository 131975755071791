import React, { Component } from 'react';

import ReactGA from 'react-ga';

import './AppMobile.style.scss';
import AppContent from './components/AppContent/AppContent';

class AppMobile extends Component {
  componentDidMount() {
    ReactGA.modalview('/drawer/app');

    return () => {
      ReactGA.pageview('/dashboard');
    };
  }

  render() {
    return (
      <div className="app">
        <div className="app__header">
          <div className="app__title">APLICATIVO</div>
        </div>
        <AppContent />
      </div>
    );
  }
}

export default AppMobile;
