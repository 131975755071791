import { DATA_UPDATE, DATA_UPDATE_ITEM } from './types';

export function loadLastUpdate() {
  return {
    type: DATA_UPDATE.LOAD_LAST,
    payload: {
      client: 'api',
      request: {
        url: '/dataUpdate/getLast',
        method: 'get',
      },
    },
  };
}

export function loadUpdateItems() {
  return {
    type: DATA_UPDATE_ITEM.LOAD_COLLECTION,
    payload: {
      client: 'api',
      request: {
        url: '/dataUpdate/getItems',
        method: 'get',
      },
    },
  };
}
