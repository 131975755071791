import React from 'react';
import PropTypes from 'prop-types';

import RadioButton from 'react-uwp/RadioButton';
import InlineButton from 'components/form/InlineButton/InlineButton';

import './ContentCreate1.style.scss';

const ContentCreate = props => {
  const { type, onChangeType, onBestOption } = props;

  return (
    <div>
      <div className="auth__create__radio-session">
        <div className="auth__create__radio-wrapper">
          <RadioButton className="auth__create__radio" defaultChecked={type === 'PF'} label="Pessoa física" onCheck={() => onChangeType('PF')} />
        </div>
        <div className="auth__create__radio-wrapper">
          <RadioButton className="auth__create__radio" defaultChecked={type === 'PJ'} label="Pessoa jurídica" onCheck={() => onChangeType('PJ')} />
        </div>
      </div>
      <InlineButton onClick={onBestOption}>Qual é a melhor opção para mim?</InlineButton>
    </div>
  );
};

ContentCreate.defaultProps = {
  onBestOption: () => {},
};

ContentCreate.propTypes = {
  type: PropTypes.oneOf(['PF', 'PJ']).isRequired,
  onChangeType: PropTypes.func.isRequired,
  onBestOption: PropTypes.func,
};

export default ContentCreate;
