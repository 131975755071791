import React, { useState } from 'react';
import Turnstile from 'react-turnstile';
import PropTypes from 'prop-types';

import CheckBox from 'react-uwp/CheckBox';
import MaterialInput from 'components/form/MaterialInput/MaterialInput';

const ContentCreate4 = props => {
  const { password, error, onChangePassword, onEnter, onCaptchaChange } = props;

  const [showPassword, changeShowPassword] = useState(false);

  let errorString = null;
  if (error === 'SHORT_PASSWORD') {
    errorString = 'Mínimo 4 caracteres.';
  }

  return (
    <div>
      <MaterialInput
        type={showPassword ? 'text' : 'password'}
        name="password"
        label="Senha"
        value={password}
        info={errorString}
        infoLevel={error && 'warning'}
        infoMargin
        onChangeValue={onChangePassword}
        onEnter={onEnter}
      />
      <p>
        <CheckBox defaultChecked={showPassword} label="Exibir Senha" onCheck={changeShowPassword} />
      </p>
      <div style={{ marginTop: 10, marginBottom: 16, display: 'flex', justifyContent: 'center' }}>
        <Turnstile
          sitekey="0x4AAAAAAAz7NOiZUfx5rKwj"
          onVerify={token => {
            onCaptchaChange(token);
          }}
        />
      </div>
    </div>
  );
};

ContentCreate4.defaultProps = {
  error: null,
  onChangePassword: () => {},
  onEnter: () => {},
  onCaptchaChange: () => {},
};

ContentCreate4.propTypes = {
  password: PropTypes.string.isRequired,
  error: PropTypes.oneOf(['SHORT_PASSWORD']),
  onChangePassword: PropTypes.func,
  onEnter: PropTypes.func,
  onCaptchaChange: PropTypes.func,
};

export default ContentCreate4;
