import React, { memo } from 'react';

import { Map, TileLayer, LayersControl, CircleMarker } from 'react-leaflet';
import { Map as MapWindy } from 'react-windy-leaflet';
import 'leaflet/dist/leaflet.css';
import L from 'leaflet';
import { GoogleLayer } from 'react-leaflet-google-v2';
import { shallowCheckEqualArrays, shallowCheckEqualObjects } from 'utils/compare';

import MapRoute from './MapRoute/MapRoute';
import MapRouteWindy from './MapRouteWindy/MapRouteWindy';
import MapRule from './MapRule/MapRule';

import { resolveLevelNumberToLevel } from '../utils';

import { googleKey, activeDrawerBoundOptions, closedDrawerBoundOptions } from './constants';

import './Map.style.scss';

delete L.Icon.Default.prototype._getIconUrl;

L.Icon.Default.mergeOptions({
  iconRetinaUrl: require('leaflet/dist/images/marker-icon-2x.png'),
  iconUrl: require('leaflet/dist/images/marker-icon.png'),
  shadowUrl: require('leaflet/dist/images/marker-shadow.png'),
});

const { BaseLayer, Overlay } = LayersControl;

const MapComponent = props => {
  const {
    layers,
    meteorology,
    meteorologyLevel,
    rule,
    zoom,
    search,
    bounds,
    center,
    meteorologyOptions,
    meteorologyPicker,
    viewport,
    drawerOpen,
    shouldBoundsUpdate,
  } = props;

  const map = (
    <div className="map" id="leaflet-map">
      {!meteorology ? (
        <Map
          ref={props.onMapRef}
          center={center}
          zoom={zoom}
          bounds={bounds}
          boundsOptions={drawerOpen && shouldBoundsUpdate ? activeDrawerBoundOptions : closedDrawerBoundOptions}
          maxBounds={
            !(layers.includes('SATELLITE') || layers.includes('HYBRID') || layers.includes('ROADMAP'))
              ? [
                  [-53.63, -110.37],
                  [25.92, 5.73],
                ]
              : [
                  [-90, -180],
                  [90, 180],
                ]
          }
          maxBoundsViscosity={1}
          minZoom={3}
          maxZoom={20}
          zoomControl={false}
          viewport={viewport}
          style={{ height: '100%', zIndex: 0 }}
          preferCanvas
          useFlyTo
          scrollWheelZoom
          boxZoom
          keyboard
          tap
          touchZoom
          bounceAtZoomLimits
          zoomSnap={0.7}
          wheelDebounceTime={2}
          wheelPxPerZoomLevel={10000000000}
          onClick={event => {
            props.onClick(event);
          }}
          onMouseMove={event => props.onMouseMove(event)}
          onViewportChange={event => {
            props.onViewportChange(event);
          }}
          onViewportChanged={event => props.onViewportChanged(event)}
        >
          <LayersControl>
            <BaseLayer checked={layers.includes('SATELLITE')} name="Google Maps Satellite">
              <GoogleLayer googlekey={googleKey} maptype="SATELLITE" minZoom={0} maxZoom={20} />
            </BaseLayer>
            <BaseLayer checked={layers.includes('HYBRID')} name="Google Maps Satellite">
              <GoogleLayer googlekey={googleKey} maptype="HYBRID" minZoom={0} maxZoom={20} libraries={['geometry', 'places']} />
            </BaseLayer>
            <BaseLayer checked={layers.includes('ROADMAP')} name="Google Maps Roadmap">
              <GoogleLayer googlekey={googleKey} maptype="ROADMAP" minZoom={0} maxZoom={20} />
            </BaseLayer>
            <Overlay checked={layers.includes('WAC')} name="WAC">
              <TileLayer
                attribution='&amp;copy <a href="https://aisweb.decea.gov.br/">AISWeb</a>'
                url="https://{s}.tiles.nexatlas.com/wac/latest/{z}/{x}/{y}.png"
                maxNativeZoom={11}
                minZoom={3}
                maxZoom={18}
              />
            </Overlay>
            <Overlay checked={layers.includes('ENRCH')} name="ENRC High">
              <TileLayer
                attribution='&amp;copy <a href="https://aisweb.decea.gov.br/">AISWeb</a>'
                url="https://{s}.tiles.nexatlas.com/enrch/latest/{z}/{x}/{y}.png"
                maxNativeZoom={11}
                minZoom={3}
                maxZoom={18}
              />
            </Overlay>
            <Overlay checked={layers.includes('ENRCL')} name="ENRC Low">
              <TileLayer
                attribution='&amp;copy <a href="https://aisweb.decea.gov.br/">AISWeb</a>'
                url="https://{s}.tiles.nexatlas.com/enrcl/latest/{z}/{x}/{y}.png"
                maxNativeZoom={11}
                minZoom={3}
                maxZoom={18}
              />
            </Overlay>
            <Overlay checked={layers.includes('REA')} name="REA">
              <TileLayer
                attribution='&amp;copy <a href="https://aisweb.decea.gov.br/">AISWeb</a>'
                url="https://{s}.tiles.nexatlas.com/rea/latest/{z}/{x}/{y}.png"
                maxNativeZoom={13}
                minZoom={3}
                maxZoom={18}
              />
            </Overlay>
            <Overlay checked={layers.includes('REH')} name="REH">
              <TileLayer
                attribution='&amp;copy <a href="https://aisweb.decea.gov.br/">AISWeb</a>'
                url="https://{s}.tiles.nexatlas.com/reh/latest/{z}/{x}/{y}.png"
                maxNativeZoom={13}
                minZoom={3}
                maxZoom={18}
              />
            </Overlay>
            <Overlay checked={layers.includes('ARC')} name="ARC">
              <TileLayer
                attribution='&amp;copy <a href="https://aisweb.decea.gov.br/">AISWeb</a>'
                url="https://{s}.tiles.nexatlas.com/arc/latest/{z}/{x}/{y}.png"
                maxNativeZoom={13}
                minZoom={3}
                maxZoom={18}
              />
            </Overlay>
          </LayersControl>
          <MapRoute
            zoom={zoom}
            onWaypointDrag={props.onWaypointDrag}
            onWaypointDragStart={props.onWaypointDragStart}
            onWaypointDragEnd={props.onWaypointDragEnd}
            mapRef={props.mapRef}
            mapSearchWaypointActive={props.mapSearchWaypointActive}
          />
          <MapRule rule={rule} onAddToMap={props.onRuleAddToMap} />
          {search && <CircleMarker center={search} radius={8} color="#00B96D" />}
        </Map>
      ) : (
        <MapWindy
          id="windy"
          windyKey="HHJqyZxkXDzc7t5keotucXMCJltheE3Y"
          windyControls
          windyLabels={false}
          overlay={meteorology}
          particlesAnim={meteorology === 'wind' ? 'on' : 'off'}
          overlayOpacity={0.7}
          level={resolveLevelNumberToLevel(meteorologyLevel)}
          center={center}
          zoom={zoom}
          bounds={bounds}
          boundsOptions={drawerOpen ? activeDrawerBoundOptions : closedDrawerBoundOptions}
          minZoom={3}
          maxZoom={18}
          meteorologyOptions={meteorologyOptions}
          style={{ height: '100%', zIndex: 0 }}
          onViewportChanged={event => props.onViewportChanged(event)}
          useFlyTo
          zoomAnimationThreshold={16}
          pickerPosition={meteorologyPicker}
          onPickerMoved={latLon => props.onMeteorologyPickerMoved(latLon.lat, latLon.lon)}
          onPickerClosed={() => props.onMeteorologyPickerClosed()}
          onClick={props.onMeteorologyMapClick}
          mapElements={
            <MapRouteWindy
              zoom={zoom}
              onWaypointDrag={props.onWaypointDrag}
              onWaypointDragEnd={props.onWaypointDragEnd}
              mapRef={props.mapRef}
              mapSearchWaypointActive={props.mapSearchWaypointActive}
            />
          }
        />
      )}
    </div>
  );

  return map;
};

const propsAreEqual = (prev, next) => {
  const propsEqual =
    shallowCheckEqualArrays(prev.bounds, next.bounds) &&
    shallowCheckEqualArrays(prev.center, next.center) &&
    shallowCheckEqualArrays(prev.layers, next.layers) &&
    shallowCheckEqualObjects(prev.mapSearchWaypointActive, next.mapSearchWaypointActive) &&
    prev.meteorology === next.meteorology &&
    prev.meteorologyLevel === next.meteorologyLevel &&
    shallowCheckEqualArrays(prev.meteorologyPicker, next.meteorologyPicker) &&
    shallowCheckEqualObjects(prev.rule, next.rule) &&
    shallowCheckEqualObjects(prev.search, next.search) &&
    prev.width === next.width &&
    prev.zoom === next.zoom;

  return propsEqual;
};

export default memo(MapComponent, propsAreEqual);
