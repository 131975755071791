import React from 'react';

import moment from 'moment';
import 'moment/locale/pt-br';
import nl2br from 'react-nl2br';
import ImageZoom from 'react-medium-image-zoom';

import './NewsListItem.style.scss';

export default props => {
  const { news } = props;

  moment.locale('pt-br');

  return (
    <div className="news-list-item">
      <div className="news-list-item__date">
        {moment(news.creation_date)
          .format('DD [de] MMMM [de] YYYY')
          .toLowerCase()}
      </div>
      <div className="news-list-item__title">{news.title}</div>
      <div className="news-list-item__message">{nl2br(news.message)}</div>
      {news.image && (
        <div className="news-list-item__image">
          <ImageZoom
            image={{
              src: news.image,
              alt: '',
              className: 'img',
              style: { width: '100%' },
            }}
            zoomImage={{
              src: news.image,
              alt: '',
            }}
            defaultStyles={{
              zoomContainer: {
                zIndex: 20000,
              },

              overlay: {
                opacity: 0.8,
              },
            }}
          />
        </div>
      )}
    </div>
  );
};
