import React, { useState, useEffect, useCallback } from 'react';
import Icon from 'react-uwp/Icon';
import TextBox from 'react-uwp/TextBox';
import Tooltip from 'react-uwp/Tooltip';
import { Draggable } from 'react-beautiful-dnd';
import ReactGA from 'react-ga';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { wrapComponent as withSnackbar } from 'react-snackbar-alert';

import { useTourContext } from 'context/TourContext/TourContext';

import classnames from 'classnames';
import metrics from 'utils/metrics';

import DistanceField from '../DistanceField/DistanceField';
import { resolveItemFirstInformation, resolveItemSecondInformation } from './utils/resolve';

import './RoutePlanningItem.style.scss';

const RoutePlanningItem = props => {
  const { value, dists, index, onItemClick, onRemoveItem, onItemNameChange, onItemMouseEnter, onItemMouseLeave, onLocate } = props;
  const tourContext = useTourContext();

  const [itemName, setItemName] = useState(value.name);
  const [onEdit, setOnEdit] = useState(false);

  useEffect(() => {
    setItemName(value.name);
  }, [value.name]);

  const startEditingName = useCallback(
    e => {
      setOnEdit(true);
      e.stopPropagation();
    },
    [setOnEdit],
  );

  const finishEditingName = useCallback(
    e => {
      setOnEdit(false);
      onItemNameChange(value.sequence, itemName);
      metrics.collect(metrics.events.DRAWER.PLANEJAR_ROTA.WPT.RENOMEAR);
      e.stopPropagation();
    },
    [itemName, value.sequence, setOnEdit, onItemNameChange],
  );

  const handleKeyDown = useCallback(
    e => {
      if (e.key === 'Enter') {
        finishEditingName(e);
      }
    },
    [finishEditingName],
  );

  return (
    <Draggable draggableId={`item-${value.id}`} index={index} className="route-planning-item__container">
      {(provided, snapshot) => (
        <div
          ref={provided.innerRef}
          {...provided.draggableProps}
          {...provided.dragHandleProps}
          style={provided.draggableProps.style}
          onMouseEnter={() => onItemMouseEnter(value.id)}
          onMouseLeave={() => onItemMouseLeave(value.id)}
        >
          {index >= 1 ? (
            <DistanceField
              distance={dists.distance}
              bearing={dists.bearing}
              time={dists.time}
              distanceAccumulated={dists.distanceAccumulated}
              timeAccumulated={dists.timeAccumulated}
              dragging={snapshot.isDragging}
            />
          ) : null}

          <div className={classnames('route-planning-item__container', { 'on-dragging': snapshot.isDragging })}>
            <div
              className="route-planning-item__infos"
              onClick={() => {
                onItemClick();
                onLocate(value.latitude, value.longitude);
              }}
            >
              <div className="route-planning-item__infos__name">
                {onEdit ? (
                  <TextBox
                    background="none"
                    value={resolveItemFirstInformation(value.waypoint_type_id, itemName, value.code, value.meta)}
                    onChangeValue={value => setItemName(value)}
                    onKeyDown={handleKeyDown}
                    className="route-planning-item__infos__textbox"
                  />
                ) : (
                  resolveItemFirstInformation(value.waypoint_type_id, itemName, value.code, value.meta)
                )}

                {value.waypoint_type_id === 3 ? (
                  onEdit ? (
                    <Icon className="route-planning-item__infos__edit--end" onClick={finishEditingName}>
                      CheckMark
                    </Icon>
                  ) : (
                    <Icon className="route-planning-item__infos__edit" onClick={startEditingName}>
                      Edit
                    </Icon>
                  )
                ) : null}
              </div>
              <div className="route-planning-item__infos__type">
                {resolveItemSecondInformation(value)}
                <Tooltip content="Copiar Coordenada" verticalPosition="top" horizontalPosition="center">
                  <CopyToClipboard
                    text={resolveItemSecondInformation(value)}
                    onCopy={() =>
                      props.createSnackbar({
                        message: 'Coordenada copiada!',
                      })
                    }
                  >
                    <button
                      type="button"
                      className="route-planning-item__infos__type__copy"
                      onClick={e => {
                        e.preventDefault();
                        e.stopPropagation();
                      }}
                    >
                      <Icon>Copy</Icon>
                    </button>
                  </CopyToClipboard>
                </Tooltip>
              </div>
            </div>

            <div
              id="remove-btn"
              className={classnames('route-planning-item__remove-button', {
                'route-planning-item__remove-button--disabled': tourContext.isDisabled(metrics.events.DRAWER.PLANEJAR_ROTA.WPT.EXCLUIR),
              })}
              onClick={() => {
                if (tourContext.isDisabled(metrics.events.DRAWER.PLANEJAR_ROTA.WPT.EXCLUIR)) {
                  return;
                }

                onRemoveItem(value.id);
                metrics.collect(metrics.events.DRAWER.PLANEJAR_ROTA.WPT.EXCLUIR);
                tourContext.dispatchTourClick(metrics.events.DRAWER.PLANEJAR_ROTA.WPT.EXCLUIR);
              }}
              role="button"
              tabIndex="-1"
              onKeyDown={() => {}}
            >
              &times;
            </div>

            <div className="route-planning-item__actions">
              {value.waypoint_type_id === 1 || value.waypoint_type_id === 5 ? (
                <ReactGA.OutboundLink
                  to={`https://aisweb.decea.gov.br/?i=aerodromos&codigo=${value.code}`}
                  eventLabel="aisweb-waypoint-panel"
                  alt="I"
                  target="_blank"
                  rel="noopener noreferrer"
                  onClick={() => metrics.collect(metrics.events.DRAWER.PLANEJAR_ROTA.WPT.AISWEB)}
                >
                  <Icon>Info</Icon>
                </ReactGA.OutboundLink>
              ) : null}
            </div>
          </div>
        </div>
      )}
    </Draggable>
  );
};

export default withSnackbar(RoutePlanningItem);
