import { Model, attr } from 'redux-orm';

import { WAYPOINT_TYPE } from '../actions/types.js';

export default class WaypointType extends Model {
  static reducer(action, WaypointType, session) {
    const { payload, type } = action;

    switch (type) {
      case `${WAYPOINT_TYPE.LOAD_COLLECTION}_SUCCESS`:
        payload.data.waypoint_types.forEach(waypoint_type => {
          WaypointType.upsert({
            id: waypoint_type.id,
            name: waypoint_type.name,
            code_name: waypoint_type.code_name,
          });
        });
        break;

      default:
        break;
    }
  }
}

WaypointType.modelName = 'WaypointType';

WaypointType.fields = {
  id: attr(),
  name: attr(),
  code_name: attr(),
};
