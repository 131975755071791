import React from 'react';

import InlineButton from 'components/form/InlineButton/InlineButton';

const ContentChangePassword2 = () => (
  <div>
    <p>
      Enviamos uma mensagem para você!
      <br />
      Acesse o link recebido para confirmar a sua identidade.
    </p>
    <p>
      Não recebeu?
      <br />
      Solicite apoio em{' '}
      <InlineButton
        onClick={() => {
          document.location = 'mailto:contato@nexatlas.com';
        }}
      >
        contato@nexatlas.com
      </InlineButton>
    </p>
  </div>
);

ContentChangePassword2.propTypes = {};

export default ContentChangePassword2;
