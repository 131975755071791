import React, { useCallback, useMemo } from 'react';
import { connect } from 'react-redux';
import { DragDropContext } from 'react-beautiful-dnd';
import classnames from 'classnames';
import { wrapComponent as withSnackbar } from 'react-snackbar-alert';

import { sortRouteWaypoints, updateRouteWaypoint, removeWaypoint, highlightWaypoint, fadeWaypoint, clearRoute } from 'redux/actions/routeActions';
import { getAllUserWaypoints } from 'redux/actions/waypointActions';

import { getActiveRoute } from 'redux/selectors/route';
import { useTourContext } from 'context/TourContext/TourContext';

import metrics from 'utils/metrics';
import { distanceNMFloat, magneticBearing, flightTime } from 'utils/map';

import SortableList from './components/SortableList/SortableList';

import FlagIcon from '../../assets/icon_flag.svg';
import './RoutePlanning.style.scss';

function RoutePlanning(props) {
  const {
    activeRoute,
    onItemClick,
    sortRouteWaypoints,
    updateRouteWaypoint,
    onLocate,
    removeWaypoint,
    highlightWaypoint,
    fadeWaypoint,
    clearRoute,
  } = props;
  const tourContext = useTourContext();

  let routeItems = [];
  if (activeRoute && activeRoute.waypoints) {
    routeItems = activeRoute.waypoints;
  }

  const handleDragEnd = useCallback(
    dragEvent => {
      const { source, destination } = dragEvent;

      if (destination) {
        sortRouteWaypoints(source.index, destination.index, activeRoute.waypoints);
      }
    },
    [activeRoute, sortRouteWaypoints],
  );

  const handleRemoveItem = useCallback(
    waypoint_id => {
      if (activeRoute.waypoints.length <= 1) {
        clearRoute();
      } else {
        removeWaypoint(waypoint_id, activeRoute.id);
      }
    },
    [activeRoute, clearRoute, removeWaypoint],
  );

  const handleItemNameChange = useCallback(
    (sequence, name) => {
      const waypoint = activeRoute.waypoints[sequence - 1];
      waypoint.name = name;

      console.log('waypoint', waypoint);

      updateRouteWaypoint(waypoint);
    },
    [activeRoute, updateRouteWaypoint],
  );

  const handleMouseEnter = useCallback(
    waypoint_id => {
      highlightWaypoint(waypoint_id);
    },
    [highlightWaypoint],
  );

  const handleMouseLeave = useCallback(
    waypoint_id => {
      fadeWaypoint(waypoint_id);
    },
    [fadeWaypoint],
  );

  const dists = useMemo(() => {
    const dists = [];
    let distanceAccumulated = 0;
    let timeAccumulated = 0;

    for (let i = 1; i <= routeItems.length - 1; i++) {
      const A = [routeItems[i - 1].latitude, routeItems[i - 1].longitude];
      const B = [routeItems[i].latitude, routeItems[i].longitude];

      const distance = distanceNMFloat(A, B);
      let time = null;

      if (activeRoute.ground_speed) {
        time = flightTime(distance, activeRoute.ground_speed);
      }

      distanceAccumulated += distance;
      if (time) {
        timeAccumulated += time;
      }

      dists.push({
        distanceAccumulated,
        timeAccumulated: timeAccumulated > 0 ? timeAccumulated : null,
        distance,
        bearing: magneticBearing(A, B),
        time,
      });
    }

    return dists;
  }, [routeItems, activeRoute]);

  return (
    <DragDropContext
      onDragEnd={result => {
        handleDragEnd(result);
        tourContext.dispatchTourClick(metrics.events.DRAWER.PLANEJAR_ROTA.WPT.TROCAR);
      }}
    >
      <div className={classnames('route-planning', { 'route-planning--items': routeItems.length > 0 })}>
        {routeItems.length === 0 ? (
          <div className="route-planning__no-items">
            <div className="route-planning__no-items__icon">
              <img src={FlagIcon} alt="" />
            </div>
            <div className="route-planning__no-items__text">Faça uma pesquisa acima ou clique no mapa para iniciar.</div>
          </div>
        ) : (
          <SortableList
            items={routeItems}
            dists={dists}
            onRemoveItem={handleRemoveItem}
            onItemNameChange={handleItemNameChange}
            onItemClick={onItemClick}
            onItemMouseEnter={handleMouseEnter}
            onItemMouseLeave={handleMouseLeave}
            onLocate={onLocate}
          />
        )}
      </div>
    </DragDropContext>
  );
}

const mapStateToProps = (state, props) => ({
  activeRoute: getActiveRoute(state, props),
});

const mapDispatchToProps = {
  sortRouteWaypoints,
  getAllUserWaypoints,
  updateRouteWaypoint,
  removeWaypoint,
  clearRoute,
  highlightWaypoint,
  fadeWaypoint,
};

export default connect(mapStateToProps, mapDispatchToProps)(withSnackbar(RoutePlanning));
