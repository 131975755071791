/* eslint-disable no-nested-ternary */
import { normalizeForSearch } from 'utils/string';

const TYPE_CODE = ['', 'Airport', 'User', 'User', 'Intersection', 'Airport', 'NDB', 'VOR', 'VOR', 'User'];

const decimalToDMS = (D, lng) => {
  let dir;
  if (D === 0) dir = lng ? 'W' : 'S';
  else dir = D < 0 ? (lng ? 'W' : 'S') : lng ? 'E' : 'N';

  const posAbs = Math.abs(D);

  let deg = Math.floor(posAbs);

  const minFloat = (posAbs - deg) * 60.0000000001;
  const min = Math.floor(minFloat);

  const sec = ((minFloat - min) * 60).toFixed(2);

  if (deg < 0) deg = -deg;

  return `${dir}${deg}° ${min}' ${sec}"`;
};

const addStringPad = (number, size) => {
  let s = String(number);

  while (s.length < (size || 2)) {
    s = `0${s}`;
  }

  return s;
};

/**
 *
 * @param {string} routeName
 * @param {{
 *  name: string;
 *  latitude: number;
 *  longitude: number;
 * }[]} points
 * @return {string}
 */
function generatePLN(routeName, points, midair) {
  const parsedWaypoints = points.map(waypoint => {
    const plnType = TYPE_CODE[waypoint.waypoint_type_id];
    const waypointCode = `${Math.abs(waypoint.latitude * 1000).toFixed(0)}_${addStringPad(Math.abs(waypoint.longitude * 10).toFixed(0), 4)}`;
    // const useStringCode = plnType === 'Airport' || plnType === 'Intersection' || plnType === 'NDB' || plnType === 'VOR';

    return {
      plnType,
      code: waypoint.code ? normalizeForSearch(waypoint.code).replace(' ', '_') : waypointCode,
      icaoRegion: 'SB',
      latitude: decimalToDMS(waypoint.latitude, false),
      longitude: decimalToDMS(waypoint.longitude, true),
      special: false,
    };
  });

  const firstWaypoint = parsedWaypoints[0];
  const lastWaypoint = parsedWaypoints[parsedWaypoints.length - 1];

  let special = midair;

  if (firstWaypoint.plnType !== 'Airport' || midair) {
    firstWaypoint.code = 'CUSTD';
    firstWaypoint.plnType = 'Intersection';
    firstWaypoint.special = true;
    special = true;
  }

  if (lastWaypoint.plnType !== 'Airport' || midair) {
    lastWaypoint.code = 'CUSTA';
    lastWaypoint.plnType = 'Intersection';
    lastWaypoint.special = true;
    special = true;
  }

  const parsedXMLPoints = parsedWaypoints.reduce(
    (total, point) => `${total}
        <ATCWaypoint id="${point.code.substring(0, 10)}">
            <ATCWaypointType>${point.plnType}</ATCWaypointType>
            <WorldPosition>${point.latitude},${point.longitude},+005000.00</WorldPosition>${
      point.plnType !== 'User'
        ? `
            <ICAO>
                ${point.plnType !== 'Airport' && !point.special ? `<ICAORegion>${point.icaoRegion}</ICAORegion>` : ''}
                <ICAOIdent>${point.code}</ICAOIdent>
            </ICAO>`
        : ''
    }
        </ATCWaypoint>`,
    '',
  );

  const PLN = `<?xml version="1.0" encoding="UTF-8"?>
<SimBase.Document Type="AceXML" version="1,0">
    <Descr>AceXML Document</Descr>
    <FlightPlan.FlightPlan>
        <Title>${routeName}</Title>
        <FPType>VFR</FPType>
        <RouteType>HighAlt</RouteType>
        <CruisingAlt>11000</CruisingAlt>
        <DepartureID>${firstWaypoint.code}</DepartureID>
        <DepartureLLA>${firstWaypoint.latitude},${firstWaypoint.longitude},+005000.00</DepartureLLA>
        <DestinationID>${lastWaypoint.code}</DestinationID>
        <DestinationLLA>${lastWaypoint.latitude},${lastWaypoint.longitude},+005000.00</DestinationLLA>
        <Descr>${firstWaypoint.code}, ${lastWaypoint.code}</Descr>
        <DepartureName>${special ? 'Custom departure' : firstWaypoint.code}</DepartureName>
        <DestinationName>${special ? 'SSUH' : lastWaypoint.code}</DestinationName>
        <AppVersion>
            <AppVersionMajor>11</AppVersionMajor>
            <AppVersionBuild>282174</AppVersionBuild>
        </AppVersion>${parsedXMLPoints}
    </FlightPlan.FlightPlan>
</SimBase.Document>
`;

  return PLN;
}

export default generatePLN;
