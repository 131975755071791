import React, { Component } from 'react';
import { connect } from 'react-redux';
import ReactResizeDetector from 'react-resize-detector';
import ReactPixel from 'react-facebook-pixel';
import LinkedInTag from 'react-linkedin-insight';

import metrics from 'utils/metrics';

import MapNav from '../MapMain/components/MapNav/MapNav';

import { withDrawer } from '../../context/MainContextDrawer';
import Configs from '../MapMain/components/Configs/Configs';
import News from '../MapMain/components/News/News';
import Feedback from '../MapMain/components/Feedback/Feedback';

import PromoModal from '../MapMain/components/PromoModal/PromoModal';
import PromoAlunosModal from '../MapMain/assets/promo/alunosModal.jpg';

import './MapPayment.style.scss';

class MapPayment extends Component {
  state = {};

  componentDidMount() {
    const { drawer } = this.props;

    drawer.disableDrawer();
    metrics.collect(metrics.events.MAPA.PAYMENT);
    LinkedInTag.track('4613745');

    this.promoConditions = false;

    if (this.promoConditions) {
      ReactPixel.trackCustom('ModalPromoView', { promotion: 'piloto_aluno' });
      metrics.collect(metrics.events.MAPA.PROMO, 'piloto_aluno');
      LinkedInTag.track('4613721');
    }
  }

  render() {
    const { status, drawer } = this.props;

    return (
      <div className="map-payment">
        {drawer.drawerRoute === '/news' && <News onClick={() => {}} />}
        <Feedback open={drawer.drawerRoute === 'FEEDBACK'} onClick={() => {}} />
        {drawer.drawerRoute === '/configs' && <Configs onClick={() => {}} fixedStatus={status} />}

        <div className="map-payment__map-wrapper">
          <div className="map-payment__disabled" />
          <ReactResizeDetector handleWidth handleHeight onResize={() => {}}>
            <div className="map-payment__fake-map" />
          </ReactResizeDetector>
        </div>

        {this.promoConditions && (
          <PromoModal
            src={PromoAlunosModal}
            href="https://docs.google.com/forms/d/e/1FAIpQLScmf_6tzmnGC6q_J1vsjXuanj__MhJYhq_10yGquW3jTYriFA/viewform?usp=sf_link"
          />
        )}

        <MapNav
          onRuleUpdate={() => {}}
          ruleActive={false}
          meteorology={false}
          onZoomIn={() => {}}
          onZoomOut={() => {}}
          onFitRoute={() => {}}
          onMapOptions={() => {}}
          onMeteorologyOptions={() => {}}
          mapNavOpen={false}
        />
      </div>
    );
  }
}

const mapDispatch = {};

const mapStateToProps = (state, props) => ({
  authState: state.auth,
});

export default withDrawer(connect(mapStateToProps, mapDispatch)(MapPayment));
