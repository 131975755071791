import React from 'react';
import { connect } from 'react-redux';

import { Beforeunload } from 'react-beforeunload';

const LeaveWatcher = props => (
  <Beforeunload
    onBeforeunload={() => {
      if (props.routeModified || props.loading.size > 0) {
        return 'Existem modificações na rota que não foram salvas, deseja sair?';
      }

      return true;
    }}
  >
    {props.children}
  </Beforeunload>
);

const mapStateToProps = (state, props) => ({
  routeModified: state.appState.routeModified,
  loading: state.appState.loading,
});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(LeaveWatcher);
