export function maskCPForCNPJ(field) {
  if (field.length === 11) {
    return field.replace(/^(\d{3})(\d{3})(\d{3})(\d{2})/, '$1.$2.$3-$4');
  }
  if (field.length === 14) {
    return field.replace(/^(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/, '$1.$2.$3/$4-$5');
  }

  return field;
}

export function maskTypingCPF(field) {
  const fieldClean = field
    .replace(/\//g, '')
    .replace(/\./g, '')
    .replace(/-/g, '');

  if (fieldClean.length <= 3) {
    return field;
  }

  if (fieldClean.length > 3 && fieldClean.length <= 6) {
    return fieldClean.replace(/^(\d{3})(\d{0,3})/, '$1.$2');
  }

  if (fieldClean.length > 6 && fieldClean.length <= 9) {
    return fieldClean.replace(/^(\d{3})(\d{3})(\d{0,3})/, '$1.$2.$3');
  }

  return fieldClean.replace(/^(\d{3})(\d{3})(\d{3})(\d{0,2})/, '$1.$2.$3-$4');
}

export function maskTypingCNPJ(field) {
  const fieldClean = field
    .replace(/\//g, '')
    .replace(/\./g, '')
    .replace(/-/g, '');

  if (fieldClean.length <= 2) {
    return field;
  }

  if (fieldClean.length > 2 && fieldClean.length <= 5) {
    return fieldClean.replace(/^(\d{2})(\d{0,3})/, '$1.$2');
  }

  if (fieldClean.length > 5 && fieldClean.length <= 8) {
    return fieldClean.replace(/^(\d{2})(\d{3})(\d{0,3})/, '$1.$2.$3');
  }

  if (fieldClean.length > 8 && fieldClean.length <= 12) {
    return fieldClean.replace(/^(\d{2})(\d{3})(\d{3})(\d{0,4})/, '$1.$2.$3/$4');
  }

  return fieldClean.replace(/^(\d{2})(\d{3})(\d{3})(\d{4})(\d{0,2})/, '$1.$2.$3/$4-$5');
}

export function maskTypingCEP(field) {
  if (field.length <= 5) {
    return field;
  }

  return field.replace(/^(\d{5})(\d{0,3})/, '$1-$2');
}

export function maskTypingPhone(field) {
  if (field.length <= 4) {
    return field;
  }

  if (field.length > 4 && field.length <= 8) {
    return field.replace(/^(\d{4})(\d{0,4})/, '$1-$2');
  }

  return field.replace(/^(\d{5})(\d{4})/, '$1-$2');
}

export function maskDecimal1(field) {
  field = field.replace('.', '').replace(/^0+/, '');

  if (field.length === 0) return '';
  if (field.length === 1) return `0.${field}`;

  return field.replace(/^(\d+)(\d{1})$/, '$1.$2');
}

export function maskDecimal2(field) {
  field = field.replace('.', '').replace(/^0+/, '');

  if (field.length === 0) return '';
  if (field.length === 1) return `0.0${field}`;
  if (field.length === 2) return `0.${field}`;

  return field.replace(/^(\d+)(\d{2})$/, '$1.$2');
}

export function addZeroPad(num, size) {
  const s = `0000000000000000000${num}`;
  return s.substr(s.length - size);
}
