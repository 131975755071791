import { createSelector } from 'reselect';

export const appStateSelector = state => state.appState;

export const getUserTrialFinishDate = createSelector(
  state => state.auth,
  auth => {
    if (!auth.user) {
      return;
    }

    return auth.user.trial_to;
  },
);

export const isUserTrialChecked = createSelector(
  state => state.auth,
  auth => {
    if (!auth.user) {
      return;
    }

    return auth.user.trial_checked === 1;
  },
);

export const getUserName = createSelector(
  state => state.auth,
  auth => {
    if (!auth.user_profile) {
      return '';
    }

    return auth.user_profile.name;
  },
);

export const getUserSurname = createSelector(
  state => state.auth,
  auth => {
    if (!auth.user_profile) {
      return '';
    }

    return auth.user_profile.surname;
  },
);

export const getUserEmail = createSelector(
  state => state.auth,
  auth => {
    if (!auth.user) {
      return '';
    }

    return auth.user.email;
  },
);
