import React from 'react';
import moment from 'moment';
import classnames from 'classnames';

import { millisecondsToHourMinute } from 'utils/map';

import './DistanceField.style.scss';

const DistanceField = props => {
  const { time, timeAccumulated, dragging, bearing, distance, distanceAccumulated } = props;

  const dur = moment.duration(time, 'h');
  const durAccumulated = moment.duration(timeAccumulated, 'h');

  return (
    <div
      className={classnames('route-planning-item__distance-field', {
        'route-planning-item__distance-field--dragging': dragging,
      })}
    >
      <span className="route-planning-item__distance-field__bearing">{bearing}</span>
      <span className="route-planning-item__distance-field__distance">{`${distance.toFixed(1)}nm`}</span>
      <span className="route-planning-item__distance-field__time">{time ? millisecondsToHourMinute(dur.as('milliseconds')) : '--'}</span>
      <span className="route-planning-item__distance-field__distance-accumulated">{`${distanceAccumulated.toFixed(1)}nm`}</span>
      <span className="route-planning-item__distance-field__time-accumulated">
        {time ? millisecondsToHourMinute(durAccumulated.as('milliseconds')) : '--'}
      </span>
    </div>
  );
};

export default DistanceField;
