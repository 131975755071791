import React from 'react';
import PropTypes from 'prop-types';

import Loader from 'react-loader-spinner';

const ContentProfile1 = props => {
  const { loading, error } = props;

  if (loading) {
    return (
      <div style={{ width: '100%', maxWidth: '230px', margin: '0 auto' }}>
        <Loader type="Plane" color="#fcfcfc" height={100} width={100} />
      </div>
    );
  }

  if (error) {
    return <div>Você já terminou seu registro.</div>;
  }

  return <div>Agora podemos continuar.</div>;
};

ContentProfile1.defaultProps = {
  loading: false,
  error: false,
};

ContentProfile1.propTypes = {
  loading: PropTypes.bool,
  error: PropTypes.bool,
};

export default ContentProfile1;
