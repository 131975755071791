import { Component } from 'react';
import { withRouter } from 'react-router-dom';

import ReactGA from 'react-ga';

class RouterListener extends Component {
  componentDidMount() {
    const { history } = this.props;

    ReactGA.pageview(history.location.pathname);

    this.unlisten = history.listen((location, action) => {
      ReactGA.pageview(location.pathname);
    });
  }

  componentWillUnmount() {
    this.unlisten();
  }

  render() {
    const { children } = this.props;

    return children;
  }
}
export default withRouter(RouterListener);
