import { createSelector } from 'redux-orm';
import { schema } from '../ormSchema';

export const ormSelector = state => state.orm;

export const getPlans = createSelector(schema, session => {
  return session.Plan.all().toRefArray();
});

export const getUserPlan = createSelector(
  schema,
  state => state.auth,
  (session, auth) => {
    let plan = null;

    if (auth.user) {
      const userPlan = session.Plan.withId(auth.user.plan_id);
      if (userPlan) {
        plan = userPlan;
      }
    }

    return plan;
  },
);
