import React from 'react';
import PropTypes from 'prop-types';

import classnames from 'classnames';

import './TermsModal.style.scss';

const CreateForm = props => {
  const { open, onClose } = props;

  return (
    <div
      className={classnames('auth__terms-modal', {
        'auth__terms-modal--open': open,
      })}
    >
      <div className="auth__terms-modal__close" role="button" tabIndex="0" onKeyDown={onClose} onClick={onClose}>
        &times;
      </div>
      <div className="auth__terms-modal__content">
        <h2>TERMOS DE USO E POLÍTICA DE PRIVACIDADE</h2>
        <p>Bem-vindo ao aplicativo NexAtlas!</p>
        <p>
          Este documento de Termos de Uso e Privacidade foi elaborado visando esclarecer as condições gerais associadas ao uso do aplicativo móvel e à
          proteção empregada aos dados pessoais dos usuários. Solicitamos que leia-o atentamente, assim como todos os avisos exibidos no aplicativo e
          em outras páginas de nossa responsabilidade. Ao acessar o aplicativo NexAtlas você estará automaticamente concordando com os termos e
          condições abaixo relacionados.
        </p>
        <p>
          A NexAtlas não poupará esforços para manter as informações e arquivos contidos em seu aplicativo de maneira precisa, atualizada e completa.
          No entanto, não assumiremos a responsabilidade pela utilização, aplicação ou processamento que por ventura os usuários possam dar às
          informações aqui contidas para todos os fins, sem o consentimento explícito da empresa.
        </p>
        <p>
          As informações contidas no aplicativo poderão ser atualizadas, modificadas, ou excluídas sem prévio aviso, definitivamente ou em caráter
          temporário, de acordo com suas necessidades administrativas. Portanto não daremos suporte a informações antigas que porventura possam ser de
          seu interesse.
        </p>
        <h3>DA ACEITAÇÃO DOS TERMOS E CONDIÇÕES DE USO</h3>
        <p>
          Este Termo de Uso e Política de Privacidade apresenta as Condições Gerais aplicáveis ao uso dos serviços pagos oferecidos pela NEXATLAS,
          sociedade limitada, inscrita no CNPJ sob nº 23.958.431/0001-60 (“NexAtlas”), em seu aplicativo para o sistema operacional Android
          distribuído através da loja oficial da empresa Google. (“Aplicativo”) (“Aplicação”) (“Ferramenta”).
        </p>
        <p>
          Ao solicitar cadastro no aplicativo NexAtlas, você deve ler, entender e aceitar todas as condições estabelecidas neste documento. As
          cláusulas apresentadas no presente Termo de Uso e Política de Privacidade serão consideradas aceitas a partir do momento em que o usuário
          der sua concordância, clicando no botão “Li e aceito os Termos de Uso e Política de Privacidade” ao se cadastrar na aplicação.
        </p>
        <p>
          Se você não for juridicamente capaz, ou não estiver de pleno acordo com qualquer um dos termos ou condições estabelecidas, infelizmente
          deverá abster-se do cadastro e utilização da aplicação. Por favor informe-nos o motivo caso isso ocorrer para que tomemos conhecimento e
          possamos aprimorar nossos Termos de Uso e Privacidade de acordo.
        </p>
        <h3>DO OBJETO</h3>
        <p>
          O presente Termo de Uso e Política de Privacidade regula a utilização, pelos usuários, da aplicação NexAtlas de planejamento lúdico de voos
          e navegação no espaço aéreo brasileiro (“Ferramenta digital”). Esta ferramenta digital reúne de maneira interativa mapas aeronáuticos,
          condições meteorológicas, informações sobre aeródromos e auxílios a navegação aérea de todo o Brasil.
        </p>
        <p>
          A Aplicação foi desenvolvida para o uso recreativo, sendo certo que se isenta de qualquer responsabilidade que, pela lei e pelo costume
          aeronáutico, seja atribuída ao Comandante da aeronave e aos mecanismos oficiais de controle e segurança.
        </p>
        <h3>DAS DEFINIÇÕES</h3>
        <p>Para a perfeita compreensão e interpretação do presente documento são adotadas as seguintes definições:</p>
        <p>
          USUÁRIO: Pessoa física que acessa o site e se utiliza da Ferramenta digital para planejar e fazer a navegação offline de seus voos no espaço
          aéreo brasileiro.
        </p>
        <p>
          NEXATLAS: Denominação comercial da Aplicação onde opera todo o sistema ora detalhado, de titularidade da Pessoa Jurídica NEXATLAS, inscrita
          no CNPJ de nº 23.958.431/0001-60.
        </p>
        <p>
          PLATAFORMA/FERRAMENTA/APLICAÇÃO/APLICATIVO: Espaço virtual que permite os Usuários utilizarem as informações disponibilizadas para
          planejarem voos no espaço aéreo brasileiro.
        </p>
        <p>
          TERMO DE USO E POLÍTICA DE PRIVACIDADE: Termos e condições gerais de uso e serviços da Aplicação, ora descritos, cujo teor é disponibilizado
          na página de download e dentro do aplicativo e cuja aceitação, pelos Usuários, é requisito para a sua utilização e os termos de segurança de
          armazenamento, manipulação, utilização e exclusão de dados pessoais dos Usuários dentro da Aplicação.
        </p>
        <p>LOGIN/DADOS DE ACESSO: Endereço de e-mail e senha cadastrados pelos Usuários para que possam ter acesso à Aplicação da NexAtlas.</p>
        <p>
          INFORMAÇÕES DE TERCEIROS: Funcionalidades e conteúdos produzidos por pessoas diversas da NexAtlas que são utilizados direta ou indiretamente
          na aplicação.
        </p>
        <h3>DA NEXATLAS</h3>
        <p>
          A NexAtlas é um serviço hospedado sob o domínio https://www.nexatlas.com/, de propriedade única e exclusiva da sociedade NEXATLAS, pessoa
          jurídica de direito privado, inscrita no CNPJ sob nº 23.958.431/0001-60, com endereço na Rua Coronel Renó, 07, bairro Centro, em
          Itajubá/Minas Gerais, CEP 37500-000.
        </p>
        <p>
          A Aplicação permite que os Usuários, por sua conta e risco, tenham acesso a mapas aeronáuticos, condições meteorológicas, informações sobre
          aeroportos, heliportos, aerovias e auxílios à navegação. A NexAtlas expressamente recomenda que seus usuários não utilizem, como fonte única
          e exclusiva, a base de dados, documentos e informações disponibilizados na aplicação.
        </p>
        <h3>CONTINUIDADE E SEGURANÇA DA APLICAÇÃO</h3>
        <p>
          A NexAtlas não se responsabiliza por qualquer prejuízo sofrido pelo usuário nos casos em que ocorrer a interrupção no funcionamento da
          aplicação. Também não seremos responsáveis por eventuais falhas de segurança ou ataque de vírus e terceiros maliciosos, tanto na aplicação,
          quanto nos equipamentos dos usuários. Além disso, a NexAtlas se reserva no direito de acionar qualquer de seus Usuários visando à reparação
          por quaisquer perdas, danos ou prejuízos sofridos por ela ou seus sócios, parceiros e/ou funcionários em razão de qualquer conduta lesiva
          praticada pelos Usuários em geral.
        </p>
        <h3>INFORMAÇÕES DE TERCEIROS</h3>
        <p>
          A Aplicação utiliza Informações de Terceiros sendo que não controla e não é responsável por quaisquer informações que não produziu, pelo que
          os Usuários se declaram cientes do fato de que tais dados são inseridos na Aplicação no estado em que se encontram e não endossa ou dá
          garantia de qualquer espécie no que diz respeito a Informações de Terceiros, incluindo, sem se limitar, ao conteúdo, funcionalidade,
          exatidão, integridade ou adequação a um propósito específico.
        </p>
        <h3>DO CADASTRO DO USUÁRIO</h3>
        <p>É exigido cadastro para utilizar a aplicação.</p>
        <p>
          O aplicativo móvel utiliza do mesmo cadastro previamente efetivado através de seu sistema online. Para se cadastrar no sistema, os Usuários
          deverão realizar o cadastro no endereço https://www.nexatlas.com/, fornecendo as informações requisitadas nos campos próprios. O cadastro
          será realizado por meio de Candidatura. É reservada a NexAtlas se valer de todos os meios em Direito admitidos para identificar seu Usuário.
        </p>
        <p>
          É permitido apenas o cadastro de pessoas físicas capazes nos termos da legislação civil. No caso de pessoas jurídicas, o cadastro somente
          pode ser efetuado por seu(s) representante(s) legal(is) ou por pessoa física com poderes especiais para a realização de tal atividade.
        </p>
        <p>
          Efetuado o cadastro, as informações prestadas pelos Usuários são enviadas eletronicamente para o banco de dados da Aplicação, sendo o mesmo
          comunicado no endereço eletrônico indicado no cadastro, a respeito de sua inclusão no Sistema. As informações fornecidas apenas serão
          utilizadas pela NexAtlas para os fins estabelecidos neste documento.
        </p>
        <p>
          Com o devido preenchimento do cadastro, o Usuário declara eletronicamente ter pleno conhecimento do presente Termo De Uso e Política de
          Privacidade, concorda integralmente com o seu conteúdo, obrigando-se, assim, ao fiel cumprimento de suas disposições e declara ter ciência
          de que o pleno e perfeito funcionamento do Sistema depende da utilização pelo Usuário de maneira correta e eventuais orientações constantes
          ao longo da Aplicação.
        </p>
        <h3>COLETA DE INFORMAÇÕES DO USUÁRIO</h3>
        <p>
          Durante o cadastro do usuário, serão solicitadas informações capazes de o individualizar, como endereço de e-mail, nome completo e perfil
          profissional. Cada acesso posterior à aplicação também gerará automaticamente informações complementares do usuário, como rotas planejadas,
          atividades do navegador e endereço IP do dispositivo utilizado para o acesso.
        </p>
        <p>
          O Usuário tem ciência e concorda que as informações coletadas serão utilizadas para informar a respeito de novos conteúdos, notícias e
          eventos; atualizar as informações de contato; otimizar a usabilidade e a experiência interativa durante a navegação do usuário no Site e no
          aplicativo NexAtlas; elaborar estatísticas gerais, sem que haja identificação dos usuários; responder às dúvidas e solicitações do próprio
          Usuários; e obedecer determinações legais ou judiciais. Também nos reservamos o direito de utilizar tais registros, de forma
          individualizada, em casos de investigação de ilícitos civis ou criminais, especialmente quando houver suspeita de fraude, ataques de
          hackers, alterações no sistema ou acesso indevido a informações protegidas e dados cadastrais de outros usuários
        </p>
        <p>Reservamos o direito de utilizar tais registros de forma individualizada ou em massa.</p>
        <h3>DA GUARDA E RESPONSABILIDADE PELAS INFORMAÇÕES COLETADAS</h3>
        <p>
          As informações fornecidas pelos Usuários serão obtidas e armazenadas de acordo com os mais rígidos padrões de segurança e confiabilidade.
          Envidaremos nossos melhores esforços na manutenção da integridade das informações que nos forem fornecidas, razão pela qual não somos
          responsáveis pelos danos decorrentes do seu acesso e/ou utilização por terceiros estranhos.
        </p>
        <p>
          O acesso às informações coletadas é restrito aos colaboradores, autoridades públicas e pessoas autorizadas. Aqueles que se utilizarem
          indevidamente dessas informações, em violação a este Termo de Uso e Política de Privacidade, estarão sujeitos às sanções administrativas e
          disciplinares, sem exclusão das medidas legais cabíveis.
        </p>
        <p>
          Informamos também que usuários poderão visualizar e operar a aplicação com limitações e funcionalidades diferentes, conforme interesse da
          NexAtlas.
        </p>
        <h3>COMUNICAÇÃO E MENSAGENS</h3>
        <p>
          A NexAtlas comunica-se oficialmente com seus usuários somente através dos endereços de e-mail cadastrados e avisos publicados diretamente na
          aplicação.
        </p>
        <p>
          Os canais registrados nas redes sociais Facebook, Twitter, Instagram, LinkedIn e Youtube atuam como meios complementares de comunicação.
        </p>
        <p>ANÚNCIOS</p>
        <p>
          Os usuários autorizam a NexAtlas a veicular anúncios em comunicados por e-mail e na aplicação utilizando-se de informações pessoais para
          criar e direcionar anúncios específicos.
        </p>
        <h3>DO CANCELAMENTO DO CADASTRO</h3>
        <p>
          A NexAtlas se reserva no direito de excluir o cadastro de qualquer Usuário quando prestar de informações cadastrais inverídicas e/ou
          fictícias; violar, por qualquer meio ou forma, do Termo De Uso e a Política de Privacidade, bem como do Sistema de Pagamento e outras
          situações que venham a serem consideradas inapropriadas.
        </p>
        <p>
          O usuário poderá, a qualquer momento, solicitar o cancelamento de sua conta por meio de solicitação enviada para o e-mail
          contato@nexatlas.com. Nesta hipótese, o cancelamento será processado em um prazo máximo de 10 dias, contados da data do recebimento, pela
          NexAtlas, da solicitação de cancelamento.
        </p>
        <p>
          O cancelamento da conta de um usuário respeitará todos os termos deste documento, bem como toda a legislação aplicável e decisões judiciais.
        </p>
        <h3>DO NOME E SENHA DE ACESSO DO USUÁRIO</h3>
        <p>
          Os Usuários deverão manter a confidencialidade de login e de sua senha de acesso, adotando as cautelas e diligências necessárias a impedir a
          sua utilização indevida por terceiros. Em caso, de perda, extravio, furto e/ou roubo do login e/ou da senha de acesso, e caso o Usuário não
          consiga recuperar a senha utilizando a ferramenta “recuperar senha”, deverá, imediatamente, comunicar o fato à NexAtlas via e-mail
          contato@nexatlas.com.
        </p>
        <p>
          O Usuário cujo nome ou cuja senha de acesso tenha sido perdido(a), extraviado(a), furtado(a) e/ou roubado(a) permanece responsável pelas
          ações realizadas em seu nome na Aplicação até o momento do envio da resposta pela NexAtlas. Uma vez recebido o e-mail do Usuário comunicando
          a perda, o extravio, furto e/ou roubo, a NexAtlas procederá ao imediato bloqueio do Usuário da Aplicação e a seu critério será fornecido
          novo acesso para o Usuário ou será requerido um novo cadastro por parte do Usuário.
        </p>

        <h3>PAGAMENTOS, CANCELAMENTOS E REEMBOLSOS</h3>
        <p>
          As Assinaturas Pagas são adquiridas diretamente no site NexAtlas por meio de pagamento de uma tarifa de assinatura recorrente mensal,
          semestral ou anual que pode ser realizada utilizando boleto ou cartão de crédito.
        </p>
        <p>
          O pagamento da assinatura é processado com todos os procedimentos necessários para a segurança das transações através da plataforma
          disponibilizada pela PAGAR.ME PAGAMENTOS S.A., sociedade anônima com sede na Cidade de São Paulo, Estado de São Paulo, na Rua Fidencio
          Ramos, 308, 9º andar, cj. 91, Vila Olímpia, CEP 04551-010, inscrita no CNPJ/MF sob o nº. 18.727.053/0001-74.
        </p>
        <p>
          Ao usuário é ofertada como cortesia a oportunidade de experimentar o aplicativo pago por um período de avaliação gratuito de 7 dias. Ao fim
          do período de avaliação será necessário efetivar a assinatura caso o usuário deseje manter seu acesso à ferramenta. Caso o procedimento não
          seja realizado, a NexAtlas reserva-se o direito de impedir, imediatamente e sem limitações, qualquer acesso do usuário às funcionalidades do
          sistema.
        </p>
        <p>
          Ao realizar a Assinatura e assumir estar ciente do presente Termo de Uso, o usuário tem conhecimento de que será realizada uma cobrança
          recorrente ao fim do período vigente através do meio e no intervalo de tempo escolhidos por ele no momento da contratação do serviço
          NexAtlas (mensal, semestral ou anual) até que seja realizado seu cancelamento explícito.
        </p>
        <p>
          O cancelamento entrará em vigor no dia seguinte ao último dia do período de assinatura atual e o usuário perderá o acesso ao aplicativo. Em
          acordo com o artigo 49 do Código de Defesa do Consumidor, a NexAtlas garante o tempo de reflexão de 7 dias corridos a partir da assinatura
          do serviço para reembolso total do usuário em caso de arrependimento. Nessa situação, o usuário deverá enviar um email para
          contato@nexatlas.com para receber as informações de procedimento. O cancelamento da cobrança recorrente fora do prazo de reflexão não dá
          direito ao reembolso total ou parcial.
        </p>
        <p>
          A NexAtlas poderá, de tempos em tempos, alterar o preço das Assinaturas e se obriga a informar quaisquer alterações de preço ao usuário com
          uma antecedência de pelo menos 15 dias e, se aplicável, como aceitar estas alterações. As alterações de preços das Assinaturas entrarão em
          vigor no início do próximo período de assinatura, depois da data da mudança de preço. O usuário aceitará o novo preço ao continuar
          utilizando o serviço NexAtlas depois que a alteração de preço entrar em vigor. Se o usuário não concordar com as mudanças de preços, terá o
          direito de rejeitar a alteração ao cancelar a assinatura do serviço NexAtlas antes que a alteração entre em vigor, ficando sob sua
          responsabilidade a leitura atenta de qualquer aviso do tipo sobre alterações de preço veiculados no sistema e por email.
        </p>
        <h3>DOS USUÁRIOS</h3>
        <p>
          As informações prestadas pelo Usuário não poderão infringir direitos autorais de terceiros, qualquer terceira parte, patentes, marcas
          registradas, segredos de comércio, ou outros direitos de propriedade, direitos de publicidade ou privacidade; ser inverídica, fraudulenta
          e/ou fictícia, nem obtida por meios ilícitos; violar a legislação em vigor; e conter quaisquer vírus, cavalos de Tróia, bombas de tempo ou
          qualquer outro programa de computador destinado a danificar, interferir ou apropriar-se de qualquer sistema, dados ou informações pessoais.
        </p>
        <p>
          Os Usuários serão unicamente responsáveis pela utilização das informações encontradas na Aplicação, sendo que a NexAtlas não é responsável
          por qualquer dano oriundo de informações falsas, manipuladas, copiadas, inexatas ou que apresentem qualquer vício ou erro fornecidas pelas
          fontes utilizadas pela Aplicação.
        </p>
        <p>
          A NexAtlas poderá divulgar, a qualquer tempo, e em quaisquer outros meios e mídias, inclusive em seu site, que o Usuário utiliza sua
          Aplicação.
        </p>
        <h3>DA CONFIDENCIALIDADE</h3>
        <p>
          A NexAtlas reconhece o direito de confidencialidade às informações prestadas por seus Usuários, e empenhar-se-á para que nenhuma informação
          prestada à Aplicação seja utilizada indevidamente por terceiros, salvo por força de lei ou ordem judicial.
        </p>
        <p>
          O cadastro na NexAtlas implica no aceite de responsabilidade plena e não compartilhada com a NexAtlas, quanto à utilização das informações
          fornecidas pela Aplicação.
        </p>
        <h3>PROPRIEDADE INTELECTUAL</h3>
        <p>
          O usuário declara reconhecer o direito da NexAtlas à propriedade intelectual total da aplicação, não tendo o direito de copiar a marca, os
          procedimentos, desenhos ou o banco de dados de informações e imagens aos quais terá acesso ao operar a aplicação. Qualquer eventual omissão
          não implicará limitação de uso do material pela NexAtlas.
        </p>
        <h3>REVISÃO E ALTERAÇÃO</h3>
        <p>
          O presente Termo De Uso e Política de Privacidade poderá ser revisto, modificado e/ou aditado pela NexAtlas a qualquer tempo e a critério
          desta, independentemente de comunicação prévia aos Usuários ou do consentimento deles. As alterações entrarão em vigor no primeiro dia
          imediatamente posterior à publicação delas na Aplicação.
        </p>
        <p>
          A NexAtlas publicará, em sua Aplicação, um aviso pelo período de 5 (cinco) dias sobre qualquer alteração nos Termos de Uso e Política de
          Privacidade. Assim, após esse período, presumir-se-á ciência de todos os Usuários que continuarem a fazer uso da ferramenta.
        </p>
        <h3>DAS REGRAS GERAIS E FORO</h3>
        <p>
          Na hipótese de que qualquer item, termo ou disposição deste Termo De Uso e Política de Privacidade vir a ser declarado nulo ou não
          aplicável, tal nulidade ou inexequibilidade não afetará quaisquer outros itens, termos ou disposições aqui contidos, os quais permanecerão
          em pleno vigor e efeito.
        </p>
        <p>O Termo De Uso e a Política de Privacidade e a relação entre as partes são regidos pelas leis da República Federativa do Brasil.</p>
        <p>
          As Partes elegem o Foro da Comarca de Belo Horizonte como sendo o único competente para dirimir quaisquer controvérsias e/ou litígios
          decorrentes deste Termo De Uso e Política de Privacidade e que venham a envolver as Partes em relação ao uso da Aplicação. A expressa,
          irrestrita e integral aceitação dos termos de uso acima descritos é caracterizada pela aceitação eletrônica durante a criação da conta,
          através do botão “Li e concordo com os Termos de Uso e Política de Privacidade”.
        </p>
      </div>
    </div>
  );
};

CreateForm.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
};

export default CreateForm;
