import React, { useEffect } from 'react';
import { connect } from 'react-redux';

import ReactGA from 'react-ga';

import CustomScrollbar from 'components/CustomScrollbar/CustomScrollbar';

import { getNews, getLastNewsId } from 'redux/selectors/news';
import { loadNews, updateLastNewsId } from 'redux/actions/newsActions';
import NewsList from './components/NewsList/NewsList';

import './News.style.scss';

const News = props => {
  const { newsLastId, updateLastNewsId, onClick } = props;

  useEffect(() => {
    ReactGA.modalview('/drawer/news');

    return () => {
      ReactGA.pageview('/dashboard');
    };
  }, []);

  useEffect(() => {
    updateLastNewsId(newsLastId);
  }, [newsLastId, updateLastNewsId]);

  const handleScrollEnd = () => {
    props.loadNews(props.actualNewsPage + 1);
  };

  return (
    <div className="news" onClick={onClick}>
      <div className="news__header">
        <div className="news__title">NOVIDADES</div>
      </div>

      <CustomScrollbar className="news__scroll">
        <NewsList news={props.news} onScrollEnd={handleScrollEnd} />
      </CustomScrollbar>
    </div>
  );
};

const mapStateToProps = (state, props) => ({
  news: getNews(state, props),
  actualNewsPage: state.appState.newsPage,
  newsLastId: getLastNewsId(state, props),
});

const mapDispatchToProps = {
  loadNews,
  updateLastNewsId,
};

export default connect(mapStateToProps, mapDispatchToProps)(News);
