import React, { Component } from 'react';

import './NewsList.style.scss';
import NewsListItem from './components/NewsListItem/NewsListItem';

export default class NewsList extends Component {
  ref = null;

  addRef = ref => {
    this.ref = ref;

    if (this.ref) {
      this.ref.addEventListener('scroll', this.handleScroll);
    }
  };

  handleScroll = () => {
    if (this.ref.scrollTop === this.ref.scrollHeight - this.ref.clientHeight) {
      this.props.onScrollEnd();
    }
  };

  render() {
    return (
      <div className="news-list" ref={this.addRef}>
        {this.props.news.map(news => (
          <NewsListItem news={news} key={news.id} />
        ))}
      </div>
    );
  }
}
