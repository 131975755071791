import { STATE } from './types';

export function showError(error) {
  return {
    type: STATE.SHOW_ERROR,
    payload: {
      error,
    },
  };
}

export function closeError() {
  return {
    type: STATE.CLOSE_ERROR,
  };
}

export function setLoading(loading) {
  if (loading) {
    return {
      type: STATE.LOADING,
    };
  }
  return {
    type: STATE.LOADED,
  };
}

export function setRouteActive(routeActive) {
  return {
    type: STATE.SET_ROUTE_ACTIVE,
    payload: {
      route_active: routeActive,
    },
  };
}

export function freezeUserAccount(freeze) {
  return {
    type: STATE.FREEZE_USER_ACCOUNT,
    payload: {
      freeze,
    },
  };
}
