import { Model, fk, attr } from 'redux-orm';

import { ROUTE_WAYPOINT } from '../actions/types.js';

export default class RouteWaypoint extends Model {
  static reducer(action, RouteWaypoint, session) {
    const { payload, type } = action;

    switch (type) {
      case `${ROUTE_WAYPOINT.LOAD_COLLECTION}_SUCCESS`:
        payload.data.route_waypoints.forEach(route_waypoint => {
          RouteWaypoint.create({
            id: route_waypoint.id,
            route_id: route_waypoint.route_id,
            waypoint_id: route_waypoint.waypoint_id,
            sequence: route_waypoint.sequence,
            waypoint_type_id: route_waypoint.waypoint_type_id,
            code: route_waypoint.code,
            name: route_waypoint.name,
            latitude: route_waypoint.latitude,
            longitude: route_waypoint.longitude,
            meta: route_waypoint.meta,
            creation_date: route_waypoint.creation_date,
          });
        });
        break;

      default:
        break;
    }
  }
}

RouteWaypoint.modelName = 'RouteWaypoint';

RouteWaypoint.fields = {
  id: attr(),
  route_id: fk('Route', 'waypoints'),
  waypoint_id: fk('Waypoint', 'route_waypoints'),
  sequence: attr(),
  waypoint_type_id: fk('WaypointType', 'route_waypoints'),
  code: attr(),
  name: attr(),
  latitude: attr(),
  longitude: attr(),
  meta: attr(),
  creation_date: attr(),
};
