import React from 'react';
import PropTypes from 'prop-types';

import GoogleLogin from 'react-google-login';

import MaterialInput from 'components/form/MaterialInput/MaterialInput';
import InlineButton from 'components/form/InlineButton/InlineButton';

const ContentCreate3 = props => {
  const { email, error, disableSocialLogin, onEnter, onChangeEmail, onGoogleFinish } = props;

  return (
    <div>
      <MaterialInput
        name="email"
        label="Digite seu e-mail"
        value={email}
        info={error}
        infoLevel={error && 'warning'}
        infoMargin
        onChangeValue={onChangeEmail}
        onEnter={onEnter}
      />
      {!disableSocialLogin && (
        <p>
          ou, crie sua conta pelo{' '}
          <GoogleLogin
            clientId="718555250810-5476j41le71nreeta7sefeeb05im0fjp.apps.googleusercontent.com"
            render={renderProps => <InlineButton onClick={renderProps.onClick}>Google</InlineButton>}
            buttonText="Login"
            onSuccess={onGoogleFinish}
            onFailure={() => {}}
            cookiePolicy="single_host_origin"
          />
        </p>
      )}
    </div>
  );
};

ContentCreate3.defaultProps = {
  error: null,
  disableSocialLogin: false,
  onEnter: () => {},
  onChangeEmail: () => {},
  onGoogleFinish: () => {},
};

ContentCreate3.propTypes = {
  email: PropTypes.string.isRequired,
  error: PropTypes.string,
  disableSocialLogin: PropTypes.bool,
  onEnter: PropTypes.func,
  onChangeEmail: PropTypes.func,
  onGoogleFinish: PropTypes.func,
};

export default ContentCreate3;
