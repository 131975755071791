/* eslint no-control-regex: "off" */

import moment from 'moment';
import { removeDiacritics } from './normalize';

export function secondsToRoundTime(seconds) {
  if (seconds < 60) {
    return `${seconds} seg`;
  }
  if (seconds < 3600) {
    return `${(seconds / 60).toFixed(2)} min`;
  }
  return `${(seconds / 3600).toFixed(2)} h`;
}

export function metersToRoundDistance(meters) {
  if (meters < 1000) {
    return `${meters} m`;
  }
  return `${(meters / 1000).toFixed(2)} km`;
}

export function normalizeStringSearch(string) {
  return removeDiacritics(string)
    .replace(/[\u0300-\u036f]/g, '')
    .toLowerCase();
}

export function onlyASCII(string) {
  return string.replace(/[^\x00-\x7F]/g, '');
}

export function brithdateExtense(birthdate) {
  const now = moment();
  birthdate = moment(birthdate);

  const months = now.diff(birthdate, 'month');
  const years = Math.floor(months / 12);
  const remain_months = months - years * 12;

  return `${years} anos e ${remain_months} meses`;
}

export function nl2br(str) {
  return `${str}`.replace(/([^>\r\n]?)(\r\n|\n\r|\r|\n)/g, '$1<br />$2');
}

export const normalizeForSearch = str => {
  return removeDiacritics(str)
    .replace(/[\u0300-\u036f]/g, '')
    .toUpperCase();
};

export function numberToFixedLength(number, len, character = ' ') {
  let text = number.toString().substr(0, len);

  while (text.length < len) {
    text = `${character}${text}`;
  }

  return text;
}
