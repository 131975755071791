import { store } from '../redux/store';

export const getUserFlag = flag => {
  const userFlags = getUserFlags();

  if (!userFlags) return null;

  let rtr = null;

  userFlags.forEach(uFlag => {
    if (uFlag.flag === flag) rtr = uFlag.value;
  });

  return rtr;
};

function getUserFlags() {
  const authStore = store.getState().auth;
  return authStore && authStore.user && authStore.user.flags;
}
