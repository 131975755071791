import React, { useState, useEffect, useMemo } from 'react';
import { connect } from 'react-redux';
import classnames from 'classnames';
import ReactGA from 'react-ga';

import ConfirmModal from 'components/ConfirmModal/ConfirmModal';

import { getUserRoutes, getActiveRoute } from 'redux/selectors/route';
import { updateName, deleteRoute, clearRoute } from 'redux/actions/routeActions';
import { setRouteActive } from 'redux/actions/appStateActions';
import metrics from 'utils/metrics';

import RoutesList from './components/RoutesList/RoutesList';

import FavoriteIcon from '../RoutePanel/assets/icon_save_disabled.svg';
import IconCronologic from './assets/icon_cronologic.svg';
import IconAlphabetical from './assets/icon_alphabetical.svg';

import './UserRoutes.style.scss';

const Favorites = props => {
  const [deleting, setDeleting] = useState(null);
  const [changing, setChanging] = useState(null);
  const [sorting, setSorting] = useState('CRONOLOGIC');
  const { routes, activeRoute, routeModified, routeSaving, setRouteActive, onClick } = props;

  useEffect(() => {
    ReactGA.modalview('/drawer/user-routes');
  }, []);

  const handleRouteListItemClick = id => {
    if (activeRoute && activeRoute.id === id) {
      return;
    }

    if (routeModified || routeSaving) {
      setChanging(id);
    } else {
      setRouteActive(id);
    }
    metrics.collect(metrics.events.DRAWER.ROTAS_SALVAS.ROTA_SELECIONAR);
  };

  const handleRouteListItemDelete = id => {
    setDeleting(id);
  };

  const favoriteRoutes = useMemo(() => {
    if (!routes) {
      return null;
    }

    if (sorting === 'CRONOLOGIC') {
      return routes.sort((a, b) => b.updated_on - a.updated_on);
    }

    return routes.sort((a, b) => {
      if (a.name === b.name) return 0;
      if (a.name < b.name) return -1;
      return 1;
    });
  }, [routes, sorting]);

  const handleCronologicSortingClick = () => {
    setSorting('CRONOLOGIC');
    metrics.collect(metrics.events.DRAWER.ROTAS_SALVAS.ORDEM_CRONO);
  };

  const handleAlphabeticalSortingClick = () => {
    setSorting('ALPHABETICAL');
    metrics.collect(metrics.events.DRAWER.ROTAS_SALVAS.ORDEM_AZ);
  };

  const handleDeletingConfirmModalYes = () => {
    if (activeRoute && deleting === activeRoute.id) {
      props.clearRoute();
    }

    props.deleteRoute(deleting);
    metrics.collect(metrics.events.DRAWER.ROTAS_SALVAS.ROTA_EXCLUIR.DIALOG.SIM);
  };

  const handleChangingConfirmModalYes = () => {
    setRouteActive(changing);
    onClick();
    setChanging(null);
  };

  return (
    <div className="favorites" onClick={!routeModified ? onClick : undefined}>
      <div className="favorites__header">
        <div className="favorites__title">ROTAS SALVAS</div>
        <div className="favorites__options">
          <div
            className={classnames('favorites__options__item', {
              'favorites__options__item--active': sorting === 'CRONOLOGIC',
            })}
            onClick={handleCronologicSortingClick}
          >
            <img src={IconCronologic} className="favorites__options__item__icon" alt="" />
          </div>
          <div
            className={classnames('favorites__options__item', {
              'favorites__options__item--active': sorting === 'ALPHABETICAL',
            })}
            onClick={handleAlphabeticalSortingClick}
          >
            <img src={IconAlphabetical} className="favorites__options__item__icon" alt="" />
          </div>
        </div>
      </div>

      <ConfirmModal
        open={deleting}
        message="Esta rota será apagada. Prosseguir?"
        width="280px"
        onYes={handleDeletingConfirmModalYes}
        onCancel={() => metrics.collect(metrics.events.DRAWER.ROTAS_SALVAS.ROTA_EXCLUIR.DIALOG.CANCELAR)}
        onFinish={() => setDeleting(false)}
      />

      <ConfirmModal
        open={changing}
        message="A rota atual possui modificações que serão perdidas ao mudar de rota. Prosseguir?"
        width="280px"
        onYes={handleChangingConfirmModalYes}
        onFinish={() => setChanging(null)}
      />

      <RoutesList routes={favoriteRoutes} onItemClick={handleRouteListItemClick} onItemDelete={handleRouteListItemDelete} />
      {favoriteRoutes && favoriteRoutes.length === 0 && (
        <div className="favorites__no-items">
          <div className="favorites__no-items__icon">
            <img src={FavoriteIcon} alt="" />
          </div>
          <div className="favorites__no-items__text">Salve suas rotas planejadas para acessá-las futuramente neste painel.</div>
        </div>
      )}
    </div>
  );
};

const mapStateToProps = (state, props) => ({
  routes: getUserRoutes(state, props),
  activeRoute: getActiveRoute(state, props),
  routeModified: state.appState.routeModified,
  routeSaving: state.appState.loading.has('update_route'),
});

const mapDispatchToProps = {
  updateName,
  deleteRoute,
  setRouteActive,
  clearRoute,
};

export default connect(mapStateToProps, mapDispatchToProps)(Favorites);
