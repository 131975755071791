import React, { useMemo } from 'react';

import RoutesListItem from './components/RoutesListItem/RoutesListItem';

import './RoutesList.style.scss';

const RoutesList = props => {
  const { routes, onItemClick, onItemDelete } = props;

  const routesListItems = useMemo(
    () => routes.map(route => <RoutesListItem route={route} key={route.id} onItemClick={onItemClick} onItemDelete={onItemDelete} />),
    [routes, onItemClick, onItemDelete],
  );

  return <div className="routes-list">{routesListItems}</div>;
};

export default RoutesList;
