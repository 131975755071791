import React from 'react';
import { Droppable } from 'react-beautiful-dnd';

import RoutePlanningItem from '../RoutePlanningItem/RoutePlanningItem';

const InnerList = React.memo(props => {
  const { items, dists, someLoading, onItemClick, onRemoveItem, onItemNameChange, onItemMouseEnter, onItemMouseLeave, onLocate } = props;

  return items.map((value, index) => (
    <RoutePlanningItem
      key={value.id}
      index={index}
      dists={index > 0 ? dists[index - 1] : null}
      disabled={someLoading}
      value={value}
      onItemClick={onItemClick}
      onLocate={onLocate}
      onItemNameChange={onItemNameChange}
      onRemoveItem={onRemoveItem}
      onItemMouseEnter={onItemMouseEnter}
      onItemMouseLeave={onItemMouseLeave}
    />
  ));
});

const SortableList = props => {
  const { items, dists, onItemClick, onRemoveItem, onItemNameChange, onItemMouseEnter, onItemMouseLeave, onLocate } = props;

  const someLoading = items.some(item => item.loading);

  return (
    <Droppable droppableId="route-planning">
      {(provided, snapshot) => (
        <div {...provided.droppableProps} ref={provided.innerRef}>
          <InnerList
            items={items}
            dists={dists}
            disabled={someLoading}
            onItemClick={onItemClick}
            onLocate={onLocate}
            onItemNameChange={onItemNameChange}
            onRemoveItem={onRemoveItem}
            onItemMouseEnter={onItemMouseEnter}
            onItemMouseLeave={onItemMouseLeave}
          />
          {provided.placeholder}
        </div>
      )}
    </Droppable>
  );
};

export default SortableList;
