import React from 'react';

import Select from 'react-select';

import './Dropdown.style.scss';

const Dropdown = props => {
  return <Select {...props} classNamePrefix="fluid-dropdown" isSearchable={false} />;
};

export default Dropdown;
