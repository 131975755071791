import React from 'react';
import { connect } from 'react-redux';

import { getActiveRoute } from 'redux/selectors/route';

import './MapTip.style.scss';

const MapTip = props => {
  let text = 'Defina o aeródromo de partida';

  if (props.activeRoute) {
    if (props.activeRoute.waypoints.length === 1) {
      text = 'Defina o aeródromo de destino';
    } else if (props.activeRoute.waypoints.length === 2) {
      text = 'Escolha pontos intermediários';
    } else if (props.activeRoute.waypoints.length > 2) {
      return null;
    }
  }

  return <div className="map__tip">{text}</div>;
};

const mapStateToProps = (state, props) => ({
  activeRoute: getActiveRoute(state, props),
});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(MapTip);
