import React, { Component } from 'react';
import { Scrollbars } from 'react-custom-scrollbars';

import 'resources/Vars.style.scss';

export default class CustomScrollbar extends Component {
  constructor(props, ...rest) {
    super(props, ...rest);
    this.state = { top: 0 };
    this.renderView = this.renderView.bind(this);
    this.renderThumb = this.renderThumb.bind(this);
  }

  renderView({ style, ...props }) {
    return <div className="box" style={{ ...style }} {...props} />;
  }

  renderThumb({ style, ...props }) {
    const thumbStyle = {
      backgroundColor: '#bfbfbf',
      width: '20px',
    };
    return <div style={{ ...style, ...thumbStyle }} {...props} />;
  }

  renderTrackHorizontal({ style, ...props }) {
    return <div {...props} style={{ display: 'none', overflowX: 'none', width: '0px' }} className="track-horizontal" />;
  }

  render() {
    return (
      <Scrollbars
        renderView={this.renderView}
        renderThumbVertical={this.renderThumb}
        thumbMinSize={30}
        renderTrackHorizontal={this.renderTrackHorizontal}
        {...this.props}
      />
    );
  }
}
