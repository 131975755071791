import React from 'react';
import PropTypes from 'prop-types';

import RadioButton from 'react-uwp/RadioButton';
import Dropdown from 'components/form/Dropdown/Dropdown';

import './ContentProfile2_3.style.scss';

const ContentProfile2e3 = props => {
  const { pilot, pilotProfessional, onChangePilot, onChangePilotProfessional, onChangeAirship, onChangeSegment, onChangeClassification } = props;

  return (
    <div>
      Você é piloto?
      <div className="auth__create__pilot-container">
        <div className="auth__create__pilot-radio">
          <RadioButton defaultChecked={pilot} onCheck={() => onChangePilot(true)} label="Sim" />
        </div>
        <div className="auth__create__pilot-radio">
          <RadioButton defaultChecked={!pilot} onCheck={() => onChangePilot(false)} label="Não" />
        </div>
      </div>
      {pilot && (
        <div>
          Você trabalha como piloto profissional?
          <div className="auth__create__pilot-container">
            <div className="auth__create__pilot-radio">
              <RadioButton defaultChecked={pilotProfessional} onCheck={() => onChangePilotProfessional(true)} label="Sim" />
            </div>
            <div className="auth__create__pilot-radio">
              <RadioButton defaultChecked={!pilotProfessional} onCheck={() => onChangePilotProfessional(false)} label="Não" />
            </div>
          </div>
          <div className="auth__create__dropdown">
            {pilotProfessional ? (
              <Dropdown
                isClearable
                placeholder="Atua em qual segmento da aviação?"
                onChange={v => onChangeSegment(v !== null ? v.value : null)}
                options={[
                  { value: 'Piloto particular', label: 'Piloto particular' },
                  { value: 'Instrutor de voo', label: 'Instrutor de voo' },
                  { value: 'Piloto de táxi aéreo', label: 'Piloto de táxi aéreo' },
                  { value: 'Linha Aérea', label: 'Linha Aérea' },
                  { value: 'Piloto militar', label: 'Piloto militar' },
                  { value: 'Piloto agrícola', label: 'Piloto agrícola' },
                  { value: 'Outros', label: 'Outros' },
                ]}
              />
            ) : (
              <Dropdown
                isClearable
                placeholder="Atua em qual segmento da aviação?"
                onChange={v => onChangeSegment(v !== null ? v.value : null)}
                options={[
                  { value: 'Piloto aluno', label: 'Piloto aluno' },
                  { value: 'Piloto hobista', label: 'Piloto hobista' },
                  { value: 'Proprietário de Aeronave Particular', label: 'Proprietário de Aeronave Particular' },
                  { value: 'Outros', label: 'Outros' },
                ]}
              />
            )}
          </div>
          <div className="auth__create__dropdown">
            <Dropdown
              name="airship"
              isClearable
              placeholder="Qual aeronave opera com mais frequência?"
              onChange={v => onChangeAirship(v !== null ? v.value : null)}
              options={[
                {
                  value: 'Avião à pistão',
                  label: 'Avião à pistão',
                },
                {
                  value: 'Avião turbohélice',
                  label: 'Avião turbohélice',
                },
                {
                  value: 'Avião à jato',
                  label: 'Avião à jato',
                },
                {
                  value: 'Helicóptero à pistão',
                  label: 'Helicóptero à pistão',
                },
                {
                  value: 'Helicóptero turboeixo',
                  label: 'Helicóptero turboeixo',
                },
                {
                  value: 'Outro',
                  label: 'Outro',
                },
              ]}
            />
          </div>
        </div>
      )}
      {!pilot && (
        <div className="auth__create__dropdown">
          <Dropdown
            isClearable
            placeholder="Como você se classifica?"
            onChange={v => onChangeClassification(v !== null ? v.value : null)}
            options={[
              {
                value: 'Simulação de voo',
                label: 'Simulação de voo',
              },
              {
                value: 'Coordenação de voos',
                label: 'Coordenação de voos',
              },
              {
                value: 'Controle de tráfego aéreo',
                label: 'Controle de tráfego aéreo',
              },
              {
                value: 'Sala AIS',
                label: 'Sala AIS',
              },
              { value: 'Outros', label: 'Outros' },
            ]}
          />
        </div>
      )}
    </div>
  );
};

ContentProfile2e3.defaultProps = {
  onChangePilot: () => {},
  onChangePilotProfessional: () => {},
  onChangeAirship: () => {},
  onChangeSegment: () => {},
  onChangeClassification: () => {},
};

ContentProfile2e3.propTypes = {
  pilot: PropTypes.bool.isRequired,
  pilotProfessional: PropTypes.bool.isRequired,
  onChangePilot: PropTypes.func,
  onChangePilotProfessional: PropTypes.func,
  onChangeAirship: PropTypes.func,
  onChangeSegment: PropTypes.func,
  onChangeClassification: PropTypes.func,
};

export default ContentProfile2e3;
