import React, { useEffect } from 'react';
import { Provider } from 'react-redux';

import { PersistGate } from 'redux-persist/integration/react';

import { Theme as UWPThemeProvider, getTheme } from 'react-uwp/Theme';
import { primaryColor } from './resources/theme';

import { store, persistor } from './redux/store';

import CacheBuster from './services/cacheBuster';
import Splash from 'components/Splash/Splash';
import LeaveWatcher from 'components/LeaveWatcher/LeaveWatcher';

import Metrics from 'utils/metrics';

import BaseLayout from './layouts/BaseLayout';

import './resources/Main.style.scss';

function App() {
  useEffect(() => {
    Metrics.collect(Metrics.events.APP.ACCESS);
  }, []);

  return (
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <UWPThemeProvider
          theme={getTheme({
            themeName: 'dark',
            accent: primaryColor,
            useFluentDesign: false,
          })}
        >
          <LeaveWatcher>
            <CacheBuster>
              {({ loading, isCorrectVersion, refreshCacheAndReload }) => {
                if (loading) {
                  return <Splash />;
                }

                if (!loading && !isCorrectVersion) {
                  if (caches) {
                    // Service worker cache should be cleared with caches.delete()
                    caches.keys().then(function(names) {
                      for (const name of names) caches.delete(name);
                    });
                  }

                  refreshCacheAndReload();
                  return <Splash />;
                }

                return <BaseLayout />;
              }}
            </CacheBuster>
          </LeaveWatcher>
        </UWPThemeProvider>
      </PersistGate>
    </Provider>
  );
}

export default App;
